import { Box, Button, CircularProgress, Typography, styled } from '@material-ui/core';
import GenericModal from "../../../../components/src/GenericModal";
import { defaultButton } from './FormWrapper';
import React from 'react';

interface IProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    onSubmit: () => void;
    isLoading: boolean;
}

const ContentWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '14px',

    [theme.breakpoints.up('md')]: {
        padding: '30px',
    },

    "& .header-text": {
        color: '#000',
        fontSize: '23px',
        fontWeight: 700,
        textAlign: 'center'
    },

    "& .text": {
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center'
    },
    "& .red-text": {
        color: '#DC2626',
        fontWeight: 600
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #6200EA",
        padding: '10px 20px',
        color: '#6200EA',
        width: '100%',
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        width: '100%',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('lg')]: {
            width: '50%'
          },
    },
    "& .box-wrapper": {
        marginTop: '35px',
        display: "flex",
        gap: "14px",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          gap: "26px",
        },
    },
}))

const CancelationModal = (props: IProps) => {
    const { isOpen, title = "", onClose, onSubmit, isLoading } = props;

    const renderButtonContent = () => {
        if (isLoading) {
            return <CircularProgress style={{ color: "#4B4B4B" }} size="24px" />;
        } else {
            return "Yes, I’m sure";
        }
    };

    return (
        <GenericModal
            id='cancel-request' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose}
            bodyStyle={{ padding: '30px 50px' }} 
            withoutCloseBtn
        >
            <ContentWrapper>
                <Typography className='header-text'>
                    You're requesting a booking cancellation, are you sure?
                </Typography>
                <Typography className='text'>
                    This does not guarantee full refund, we will wait for vendor approval for the cancellation request.
                </Typography>
                <Box className="box-wrapper">
                    <Button className="main-btn" onClick={() => onSubmit()} disabled={isLoading}>{renderButtonContent()}</Button>
                    <Button className="secondary-btn" onClick={() => onClose()}>No, don't request cancellation</Button> 
                </Box>
            </ContentWrapper>
        </GenericModal>
    )
}

export default CancelationModal;