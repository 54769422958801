import moment from "moment";
import React, { useMemo } from "react";
import { Box, Typography, styled } from "@material-ui/core";
import { DayCellWrapperVendorProps } from "./types";
import { Views } from "react-big-calendar";

const DayCellWrapper = styled('div')(({ theme }) => ({
    padding: "5px",
    position: "relative",
    backgroundColor: "transparent",
    zIndex: 15,
    color: "black",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    fontFamily: 'Montserrat',

    "& .content": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: '10px!important'
    },
    "& .today": {
      border: '1px solid #6F05EA',
      padding: '10px 10px',
      borderRadius: '10px',

      [theme.breakpoints.up("sm")]: {
        padding: '5px 10px',
      },
    },
    "& .active-day": {
      fontSize: '14px',
      fontWeight: 600
    },
    "& .active-today-day": {
      fontSize: '14px',
      fontWeight: 600,
      color: '#6F05EA'
    },
    "& .not-active-day": {
      fontSize: '14px',
      fontWeight: 600,
      color: '#B7B7B7'
    },
    "& .event-dot": {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      zIndex: 16,
    },

    "& .event-active": {
      background: "#6F05EA",
    },

    "& .event-full": {
      background: "#34D399",
    },

    "& .event-full-text": {
      fontSize: '8px',
      fontWeight: 500,
      color: '#34D399'
    },

    "& .event-count": {
        color: '#78716C',
        fontSize: '8px',
        fontWeight: 500,
        marginTop: '2px'
    },
    "& .cursor": {
      cursor: 'pointer'
    }
}));

const DayCellVendor: React.FC<DayCellWrapperVendorProps> = React.memo(({ children, value, events, calendarDate, view, toogleAvailability }) => {
  const dayEvents = useMemo(() => {
    return events.filter((event) => {
      const eventDate = new Date(event.start);
      return (
        eventDate.getFullYear() === value.getFullYear() &&
        eventDate.getMonth() === value.getMonth() &&
        eventDate.getDate() === value.getDate()
      );
    });
  }, [events, value]);

  const totalSchedulesCount = useMemo(() => {
    return dayEvents.reduce((total, event) => total + event.ss_schedules_count, 0);
  }, [dayEvents]);

  const totalCapacity = useMemo(() => {
    return dayEvents.reduce((total, event) => total + event.ss_capacity, 0);
  }, [dayEvents]);

  const isCurrentMonth = useMemo(() => {
    return (
      value.getFullYear() === calendarDate.getFullYear() &&
      value.getMonth() === calendarDate.getMonth()
    );
  }, [value, calendarDate]);

  const isToday = useMemo(() => {
    return moment(value).isSame(moment(), 'day');
  }, [value]);

  if (view === Views.WEEK) {
    return null;
  }

  const isFull = (totalSchedulesCount >= totalCapacity) && totalSchedulesCount > 0;
  const displayCounter = totalSchedulesCount || "";

  const handleClick = () => {
    if(displayCounter) {
      toogleAvailability()
    }
  }

  return (
    <DayCellWrapper>
      <Box className={`content ${isToday && 'today'} ${totalSchedulesCount > 0 && 'cursor'}`} onClick={handleClick}>
        <Typography className={isToday ? 'active-today-day' : isCurrentMonth ? 'active-day' : 'not-active-day'}>
          {moment(value).format("DD")}
        </Typography>
        <Box className={`event-dot ${totalSchedulesCount > 0 && 'event-active'} ${isFull && 'event-full'}`} />
        {isFull ? <Typography className="event-full-text">Booked</Typography> : <Typography className="event-count">{displayCounter}</Typography>}
      </Box>
    </DayCellWrapper>
  );
});

export default DayCellVendor;