import { Box, ClickAwayListener, Typography, styled } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { EditPen } from '../assets';


type Props = {
    name?: string;
    isNew: boolean;
    onSaveName: (name: string) => void;
}

const Wrapper = styled(Box)({
    width: '100%',

    '& .header-info-name': {
        fontWeight: 600, fontSize: '30px', overflowWrap: 'break-word', wordBreak: 'break-word', margin: 0
    },
    '& .edit-input': {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '30px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        color: '#6F05EA',
        width: '100%',
        minWidth: '100%',
        "&::placeholder": {
            color: '#A6A6A6',
            opacity: 0.5,
            fontWeight: 400,
            fontStyle: 'italic'
          }
    },
    '& .hovered-name': {
        color: '#6F05EA'
    }
})

const PetName = ({ name = '', isNew, onSaveName }: Props) => {
    const [isHovered, setIsHovered] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string>(name);
    const [error, setError] = useState<string>('');

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if(isNew) {
            onSaveName(event.target.value);
        }
        if (error) setError('');
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        if(value.length) {
            onSaveName(value);
        } else {
            setValue(name);
        }
        if(!isNew) {
            setIsEditing(false);
        }
        if (!value.trim()) {
            setError('Pet name is required');
          }
    };

    useEffect(() => {
        setValue(name);
        if(!name) {
            setIsEditing(true)
        } 
    }, [name])

    useEffect(() => {
        if(inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    useEffect(() => {
        if(!isNew) {
            setIsEditing(false)
        }
    }, [isNew])

    return (
        <Wrapper>
            {
                isEditing 
                    ? <div style={{ display: 'flex', gap: '14px', alignItems: 'center', width: '280px' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <Box style={{ flex: 1 }}>
                            <ClickAwayListener onClickAway={handleBlur} data-test-id='clickaway'>
                                <input id='info-name-input' required className='edit-input' value={value} onChange={handleChange} ref={inputRef} maxLength={75} placeholder='Enter pet name'/>    
                            </ClickAwayListener>
                            {error && <p style={{position: "absolute", letterSpacing: "0.03333em", color: 'rgb(244, 67, 54)', fontSize: "0.75rem", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"  }}>{error}</p>}
                        </Box>
                        <Box>
                            <img src={EditPen} onClick={handleBlur} />
                        </Box>
                      </div> 
                    : <div data-test-id="info-header" style={{ display: 'flex', gap: '14px', alignItems: 'center',cursor:'pointer' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <Typography className={`header-info-name ${isHovered && 'hovered-name'}`}>{value}</Typography>
                        {isHovered && <img src={EditPen} onClick={handleEditClick} data-test-id='edit-btn'/>}
                      </div>
            }
        </Wrapper>
    )
}

export default PetName;