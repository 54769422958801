export const MarkerIcon = require("../assets/marker.svg");
export const Paw = require("../assets/image_paw.svg");
export const MarkerSmall = require("../assets/marker_small.svg");
export const checked = require("../assets/checkbox_chek.svg");
export const unchecked = require("../assets/checkbox_empty.svg");
export const noResults = require("../assets/noResults.svg");
//ViewMap
export const smallarrow = require("../../maps/assets/smallarrow.png");
export const Cellout = require("../../maps/assets/locationpin.png");
export const crossButton = require("../../maps/assets/crossButton.png");
export const selectedmarkerpin = require("../assets/selectedmarkerpin.png");
export const image_minus = require("../assets/image_minus.png");
export const image_plus = require("../assets/image_plus.png");



