import { Box, IconButton, styled } from "@material-ui/core";
import React, { useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { nextArrowIcon, previousArrowIcon } from "./calendarWeb/assets";

interface DatePickerProps {
  selectedDate: Date | null;
  handleDateChange: (date: Date | null) => void;
  maxDate?: Date | null;
  minDate?: Date | null;
  disabled?: boolean;
}
const StyledCustomHeader = styled(Box)({
  borderBottom: "1px solid #B7B7B7",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "16px",
  "& .month-year-info": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .react-datepicker__current-month": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#6200ee",
    borderBottom: "none",
    paddingBottom: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  "& .year-month-container": {
    position: "relative",
  },
  "& .year-span": {
    fontSize: "36px",
    fontWeight: "600",
    border: "none",
    cursor: "pointer",
    color: "black",
    borderBottom: "2px solid black",
    "@media only screen and (max-width: 600px) and (min-width: 350px)":{
      fontSize:"16px"
    }
  },
  "& .year-list": {
    position: "absolute",
    top: "100%",
    left: "-12px",
    width: "118px",
    maxHeight: "240px",
    overflowY: "auto",
    backgroundColor: "white",
    padding: 0,
    marginTop: "5px",
    listStyleType: "none",
    zIndex: 1,
    borderRadius: "8px",
    boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.2)",
    "& ::-webkit-scrollbar": {
      width: 6,
      borderRadius: 6,
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#6F05EA",
      borderRadius: 4,
      width: 6,
    },
    "& ::-webkit-scrollbar-track": {
      background: "#CBD5E1",
      borderRadius: 6,
    },
    "@media only screen and (max-width: 600px) and (min-width: 350px)":{
      width:"unset",
      left:"-18px"
    }
  },
  "& .year-option": {
    padding: "14px 25px",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight:"19.5px",
    "@media only screen and (max-width: 600px) and (min-width: 350px)":{
      fontSize: "12px",
    }
  },
});
const currentYear = new Date().getFullYear();
const years = Array.from({ length: 150 }, (_, i) => currentYear - 35 + i).filter(year => year <= currentYear);

const CommonDatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  handleDateChange,
  maxDate,
  disabled,
  minDate,
}) => {
  const [yearDropdownVisible, setYearDropdownVisible] = useState(false);
  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    changeYear,
  }: ReactDatePickerCustomHeaderProps) => (
    <StyledCustomHeader>
      <Box className="month-year-info">
        <IconButton onClick={decreaseMonth}>
          <img src={previousArrowIcon} alt="prev-arrow" />
        </IconButton>
        <span className="react-datepicker__current-month">
          {date.toLocaleString("default", { month: "long" })}{" "}
          <Box className="year-month-container">
            <span
              className="year-span"
              onClick={() => setYearDropdownVisible(!yearDropdownVisible)}
            >
              {date.getFullYear()}
            </span>
            {yearDropdownVisible && (
              <ul className="year-list">
                {years.map((year) => (
                  <li
                    key={year}
                    style={{
                      color:
                        year === date.getFullYear() ? "#6F05EA" : "#3C3E49",
                      fontWeight: year === date.getFullYear() ? 600 : 400,
                      backgroundColor:
                        year === date.getFullYear()
                          ? "#E1CCFF54"
                          : "transparent",
                    }}
                    className="year-option"
                    onClick={() => {
                      changeYear(year);
                      setYearDropdownVisible(false);
                    }}
                  >
                    {year}
                  </li>
                ))}
              </ul>
            )}
          </Box>
        </span>
        <IconButton onClick={increaseMonth}>
          <img src={nextArrowIcon} alt="prev-arrow" />
        </IconButton>
      </Box>
    </StyledCustomHeader>
  );
  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      maxDate={maxDate}
      minDate={minDate}
      withPortal
      disabled={disabled}
      showYearDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={150}
      dateFormatCalendar="MMMM"
      renderCustomHeader={renderCustomHeader}
    />
  );
};

export default CommonDatePicker;
