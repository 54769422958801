import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilteredService } from "../../../components/src/interfaces.web";

// Customizable Area Start
interface IVendorImgVideoDataModel {
  id: number;
  url: string;
}
interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}

export interface GallaryImageErrorResponse {
  detail?: string;
  account?: string;
  token?: string;
  error?:string;
}
export interface MessageData{
  message:string
}
interface UpdateGallaryApiResponse {
  galleries: IVendorImgVideoDataModel[];
  meta:MessageData;
  errors: GallaryImageErrorResponse[];
  error: GallaryImageErrorResponse[]
}
export interface HandleDeleteRepo{
  meta:MessageData;
  errors?: GallaryImageErrorResponse[];
  error: GallaryImageErrorResponse[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedService:IFilteredService
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedImageVideoId: number;
  openViewImageVideo: boolean;
  isOpenImgGallery:boolean;
  selectedImage:any;
  referenceImage:any;
  errorMessageReferenceImage:string;
  uploadedImagePreview:any;
  GallaryVedioImageArray:[];
  isEditImage:boolean;
  GallaryError:any;
  uploadNewImagePre:any[];
  loading:boolean;
  tempGallaryArray:any

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableUserGallaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  UpdateGallaryImagesApi: string = "";
  GetGallaryImageAPi: string = "";
  DeleteImagesApi: string = "";
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.RestAPIResponceDataMessage),
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
    ];

    this.state = {
      selectedImageVideoId: 0,
      openViewImageVideo: false,
      isOpenImgGallery:false,
      selectedImage: null,
      referenceImage:'',
      errorMessageReferenceImage:'',
      uploadedImagePreview:[],
      GallaryVedioImageArray:[],
      isEditImage:true,
      GallaryError:'',
      uploadNewImagePre:[],
      loading:false,
      tempGallaryArray:[]

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.UpdateGallaryImagesApi:
          this.handleUpdateGallaryImagesApiResponse(responseJson);
          break;
        case this.GetGallaryImageAPi:
          this.handleGallaryImageResponse(responseJson);
          break;
          case this.DeleteImagesApi:
            this.handleDeletedImageresponse(responseJson);
            break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.ShowImageGallaryAPI();
  }
  handleGallaryImageResponse = async (responseJson: UpdateGallaryApiResponse) => {
    if (responseJson.galleries) {
      this.setState({ loading: !this.state.loading })
      const responseData = responseJson;
      if (responseData.galleries.length > 0) {
        const newMediaArray = responseData.galleries.map((file, index) => ({
          id: this.state.uploadedImagePreview.length + index,
          src: file.url,
          imagefile: file.url,
          imageId: file.id
        }));
        this.setState((prevState) => ({
          uploadedImagePreview: [...prevState.uploadedImagePreview, ...newMediaArray],
          referenceImage: '/image'
        }));
        this.setState((prevState) => ({
          tempGallaryArray: [...prevState.tempGallaryArray, ...newMediaArray],
          referenceImage: '/image'
        }));
      }
      
    } else if(responseJson.errors) {
      this.setState({ GallaryError: 'Invalid token' });
    }
  }
  handleUpdateGallaryImagesApiResponse = (
    responseJson: UpdateGallaryApiResponse
  ) => {
    if (responseJson.galleries) {
      if (responseJson.galleries.length > 0) {
        this.setState({uploadedImagePreview:[]
        })
        const newMediaArray = responseJson.galleries.map((file, index) => ({
          id: this.state.uploadedImagePreview.length + index,
          src: file.url,
          imagefile: file.url,
          imageId: file.id
        }));
        this.setState((prevState) => ({
          uploadedImagePreview: [...prevState.uploadedImagePreview, ...newMediaArray],
          referenceImage: '/image'
        }));
        this.setState((prevState) => ({
          tempGallaryArray: [...prevState.tempGallaryArray, ...newMediaArray],
          referenceImage: '/image'
        }));
      }
      
      this.setState({ loading: !this.state.loading, isEditImage: !this.state.isEditImage })

     
    } else if(responseJson.errors) {
      this.setState({ GallaryError: 'Invalid token' });
      this.goToLoginPage();
          }
  };
  handleDeletedImageresponse=(responseJson: HandleDeleteRepo)=>{
    if (responseJson.errors?.some(error => error.token)) {
      this.goToLoginPage();
    }
  }
  goToLoginPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  CalleditImage() {
    this.setState((prevState) => ({
      uploadedImagePreview: prevState.uploadedImagePreview.filter((uploadedImage: { id: any; }) =>
        prevState.tempGallaryArray.some((tempImage: { id: any; }) => tempImage.id === uploadedImage.id)
      ),
      
    }));
    this.setState({isEditImage: !this.state.isEditImage})
  }
  CalleditImage2(){
        this.setState({ isEditImage: !this.state.isEditImage })

  }
  SaveUploadedImage = async (
  ) => {
    if (this.state.uploadedImagePreview.length > this.state.tempGallaryArray.length) {
      this.setState({ loading: !this.state.loading })
      const servicetoken = sessionStorage.getItem("login_token");
      let serviceGallaryUploadData = new FormData();
      this.state.uploadNewImagePre.forEach((media: { id: number; src: string; imagefile: File }) => {
        serviceGallaryUploadData.append(
          "service[service_galleries][]", media.imagefile, "[PROXY]"
        );
      })
      this.UpdateGallaryImagesApi = await this.apiCall({
        method: configJSON.httpPutMethod,
        endPoint: `${configJSON.UploadGallary}?id=${this.props.selectedService.service_id}`,
        token: servicetoken,
        body: serviceGallaryUploadData,
        isJsonStringify: false,
      });
    } else {
      this.setState({ isEditImage: !this.state.isEditImage })
    }
  };
  ShowImageGallaryAPI = async () => {
    if (!this.props.selectedService.service_id) return
    const userInfoToken = sessionStorage.getItem("login_token");
    this.GetGallaryImageAPi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.ShowGallary}?id=${this.props.selectedService.service_id}`,
      token: userInfoToken,
    });
  };
  handleDeleteMedia = async (id: number) => {
    const { uploadedImagePreview } = this.state;
    const foundIndex = uploadedImagePreview.findIndex((media: any) => media.id === id);
    let imageId: number = 0;
    if (foundIndex !== -1) {
      imageId = uploadedImagePreview[foundIndex].imageId;
      this.setState({})

      this.setState((prevState) => ({
        uploadedImagePreview: prevState.uploadedImagePreview.filter(
          (media: any) => media.id !== id
        ),
      }));
    }
    this.setState((prevState) => ({
      uploadedImagePreview: prevState.uploadedImagePreview.filter((media: any, index: number) => media.id !== id),
      tempGallaryArray: prevState.tempGallaryArray.filter((media: any, index: number) => media.id !== id),

    }));
    const servicetoken = sessionStorage.getItem("login_token");
    this.DeleteImagesApi = await this.apiCall({
      method: configJSON.httpDeleteMethod,
      endPoint: `${configJSON.DeleteGallary}?id=${this.props.selectedService.service_id}&gallery_id=${imageId}`,
      token: servicetoken,
      isJsonStringify: false,
    });
  }
  apiCall = async (data: IApiModel) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
