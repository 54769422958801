import React from "react";

// Customizable Area Start
import { createTheme, styled, Theme, ThemeProvider } from "@material-ui/core/styles";
import { Box, Dialog, DialogContent, DialogContentText, Button, Divider, Typography, Grid, DialogTitle, CircularProgress } from "@material-ui/core";
import ServiceGalleryController, {
  Props,
} from "./ServiceGalleryController.web";
import { closeIcon} from "../assets";
import ViewImageSlider from "../../../../components/src/Gallery/viewImageGallery.web";
import { chunkArray, returnTruthyString } from "../../../../components/src/HelperUtils";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat"
  }
});

// Customizable Area End

export default class ServiceGalleryWeb extends ServiceGalleryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderLoader = () => {
    return (
      <LoaderContainer>
        <CustomLoader />
      </LoaderContainer>
    )
  }

  renderSubServiceData = () => {
    const serviceInfo = this.props.subServiceData;
    const description = returnTruthyString(serviceInfo?.description);
    const capacity = returnTruthyString(serviceInfo?.capacity);
    const price = returnTruthyString(serviceInfo?.price !== "0" ? `${serviceInfo?.price}€` : "-");
    return (
      <SubServiceWrapper>
        <InfoWrapper>
          <SubServiceTitle>Capacity:</SubServiceTitle>
          <SubServiceData>{capacity}</SubServiceData>
        </InfoWrapper>
        <InfoWrapper>
          <SubServiceTitle>Price:</SubServiceTitle>
          <SubServiceData> {price}</SubServiceData>
        </InfoWrapper>
        <InfoWrapper>
          <SubServiceTitle>Description:</SubServiceTitle>
          <SubServiceData className="sub-service-description"><span title={description}>{description}</span></SubServiceData>
        </InfoWrapper>
      </SubServiceWrapper>
    )
  }

  renderGallery = () => {
    const chunkImageArray = chunkArray(this.props.galleryData, 5);
    return (
      chunkImageArray.map((images, index) => (
        <Grid container spacing={1} style={{ flexDirection: ((index + 1) % 2 || images.length !== 5) ? "row" : "row-reverse", marginBottom: "14px" }}>
          {(images.length === 5 || (index + 1) % 2 === 1) && 
            <LargeImageGrid item>
              <StyledImage onClick={() => this.handleImageClick(images[0].image_url)} src={images[0].image_url} data-test-id="large-img"/>
              </LargeImageGrid>
          }
          <SmallImagesGrid length={images.length} item data-test-id="small-img-grid">
            <Grid container style={{ height: "100%" }} spacing={1}>
              {((index + 1) % 2 ? images.slice(1, 5) : images.slice(0, 4)).map((img: any) => (
                <Grid item xs={12} sm={6} style={{ height: "208px" }} key={img.image_url}>
                  <StyledImage src={img.image_url} onClick={() => this.handleImageClick(img.image_url)} data-test-id="small-img"/>
                </Grid>
              ))}
            </Grid>
          </SmallImagesGrid>
        </Grid>
      )
      )
    )
  }

  renderGalleryDialog = () => {
    const {isSubService,isGalleryLoading} = this.props;
    const isDisplay = this.state.viewImageId
    return (
      <CustomDialog
        fullWidth
        maxWidth={"md"}
        open={true}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{ className: "gallery-dialog" }}
        style={{display: isDisplay ? "none" : ""}}
      >
        <DialogTitle style={{ padding: "0px 24px" }}>
          <ButtonWrapper>
            <ButtonContainer>
              <ReserveNowBtn onClick={this.handleReserveClick} data-test-id="reserve-now-btn">
                Reserve Now
              </ReserveNowBtn>
              <CloseBtn onClick={this.props.handelCloseClick} data-test-id="close-btn" endIcon={<img src={closeIcon} />}>
                <span className="close-label">Close</span>
              </CloseBtn>
            </ButtonContainer>
          </ButtonWrapper>
        </DialogTitle>
        <DialogContent>
          <DividerContainer><Divider style={{ color: "#B7B7B7" }} /></DividerContainer>
          <DialogContentText>
            {isSubService && this.renderSubServiceData()}
            {!isGalleryLoading ? <GalleryBox>{this.renderGallery()}</GalleryBox> : (<></>)}
            {isGalleryLoading ? <GalleryBox>{this.renderLoader()}</GalleryBox> : (<></>)}
          </DialogContentText>
        </DialogContent>
      </CustomDialog>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {galleryData} = this.props;
    
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <StyledServiceGallery>
          {this.renderGalleryDialog()}
          <ViewImageSlider data-test-id="view-image-slider" imageVideoData={galleryData} selectedImgVideoId={this.state.viewImageId} open={this.state.viewImageId !== ""} handleImageVideoBack={this.handleBackImageClick} />
        </StyledServiceGallery>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyledServiceGallery = styled(Box)({
  padding: "0px"
});

const CustomDialog = styled(Dialog)({
  "& .gallery-dialog": {
    maxWidth: "1021px",
  }
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  padding: "26px 36px 19px 36px",
  justifyContent: "flex-end",
  "@media (max-width: 600px)": {
    padding: "26px 20px 19px 20px",
  },
  "@media (max-width: 400px)": {
    padding: "26px 0px 19px 0px",
  },
})

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "60%",
  "@media (max-width: 960px)": {
    width: "100%",
  },
});

const ReserveNowBtn = styled(Button)({
  width: "198px",
  height: "51px",
  backgroundColor: "#6F05EA",
  color: "#fff",
  fontSize: "18px",
  fontWeight: 700,
  borderRadius: "100px",
  textTransform: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#6F05EA",
    color: "#fff",
  },
  "@media (max-width: 960px)": {
    width: "178px",
    height: "46px",
    fontSize: "16px"
  },
  "@media (max-width: 600px)": {
    width: "158px",
    height: "41px",
    fontSize: "14px"
  },
});

const CloseBtn = styled(Button)({
  width: "94px",
  height: "32px",
  color: "#6F05EA",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "none",
  boxShadow: "0px 2px 8px 0px #0000000F",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#6F05EA",
  },
  "@media (max-width: 960px)": {
    width: "84px",
    height: "29px",
    fontSize: "14px",
    "& img": {
      height: "21px",
      width: "21px"
    }
  },
  "@media (max-width: 600px)": {
    width: "unset",
    height: "unset",
    "& .close-label": {
      display: "none"
    },
    "& span>span": {
      margin: "0px"
    },
    "& img": {
      height: "19px",
      width: "19px"
    }
  },
});

const SubServiceWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "657px",
  gap: 80,
  margin: "0 154px 0px 154px",
  height: 20,
  "@media (max-width: 960px)": {
    flexWrap: "wrap",
    gap: 0,
    maxWidth: "unset",
    height: "unset",
    margin: "0 36px 18px 36px",
  },
  "@media (max-width: 600px)": {
    margin: "0 20px 18px 20px",
    flexDirection: "column"
  },
  "& .sub-service-description": {
    maxWidth: "229px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      maxWidth: "150px",
    },
    "@media (max-width: 350px)": {
      maxWidth: "120px",
    },
  }
})

const InfoWrapper = styled(Box)({
  display: "flex",
  gap: 5
});

const SubServiceTitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500
});

const SubServiceData = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#6F05EA",
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  cursor: "pointer",
});

const SmallImagesGrid = styled(Grid)(
  ({
    theme,
    length = 0,
  }: {
    theme: Theme;
    length?: number;
  }) => ({
    height: length > 2 ? "417px" : "204px",
    width: "56%",
    "@media (max-width: 960px)": {
      width: "100%",
      height: "unset",
    },
    "@media (max-width: 600px)": {
      height: "unset",
    },
  })
);

const LargeImageGrid = styled(Grid)({
  height: "417px",
  width: "44%",
  "@media (max-width: 960px)": {
    width: "100%"
  },
  "@media (max-width: 600px)": {
    height: "204px",
  },
})

const GalleryBox = styled(Box)({
  marginTop: "24px",
  padding: "0px 83px 77px 83px",
  "@media (max-width: 960px)": {
    padding: "0px 53px 77px 53px",
  },
  "@media (max-width: 600px)": {
    padding: "0px 20px 77px 20px",
  },
  "@media (max-width: 400px)": {
    padding: "0px 0px 77px 0px",
  },
});

const DividerContainer = styled(Box)({
  padding: "0px 36px 24px 36px",
  "@media (max-width: 600px)": {
    padding: "0px 20px 24px 20px",
  },
  "@media (max-width: 400px)": {
    padding: "0px 0px 24px 0px",
  },
});

const CustomLoader = styled(CircularProgress)({
  color: "#6F05EA"
})

const LoaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "80px"
})
// Customizable Area End