import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import {
  loginForwardArrow,
  LoginImage,
  passwordIcon,
  successfulIcon,
} from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputField from "../../../components/src/InputField";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";

// Customizable Area Start
interface NewPasswordModal {
  password: string;
  password_confirmation: string;
}
const ForgotPasswordPageWrapper = styled(Box)(({ theme }) => ({
  alignItems: "center",
  height:"100%",
  display: "flex",
  "@media(max-width:960px)": {
    flexDirection: "column",
    marginTop:"120px"
  },
  "& .ForgotPwd-Info-Container": {
    width: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
      width: "90%",
      marginTop: "20px",
    },
  },
  "& .Pwd-Change-Container": {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
  },
  "& .Verify-Text-Container": {
    width: "50%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .Verify-Body": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  "& .forget-pwd":{
    display:"none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      fontWeight: 700,
      color: "#6F05EA",
      fontFamily: "Montserrat",
      display:"contents",
      marginTop:"150px"
    },
  },
  "& .new-password-eye-icon":{
    color:"#A6A6A6"
  }
}));

const FormikContainer = styled(Box)(({ theme }) =>({
  maxWidth: "500px",
  width: "100%",
  paddingTop: "40px",
  [theme.breakpoints.between("md",1150)]: {
    width: "unset",
    margin:"auto"
  },
}));

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px 0px",
  "& .ForgotPwd-Btn": {
    border: "1px solid #6F05EA",
    fontSize: "24px",
    height: "53px",
    padding: "10px 78px",
    fontWeight: "700",
    backgroundColor: "transparent",
    gap: "10px",
    textTransform: "none",
    color: "#6200EA",
    boxShadow: "none",
    borderRadius: "100px",
    "& .MuiButton-endIcon":{
      marginLeft:"36px",
      width:"36px"
    }
  },
});

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  top: "0",
  height:"100%",
  right: "0",
  "& .Image": {
    width: "100%",
    height:"100%"
  },
  [theme.breakpoints.down("sm")]: { display: "none" },
}));
const ImageTextContainer = styled(Box)({
  position: "absolute",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const initialValues: NewPasswordModal = {
  password: "",
  password_confirmation: "",
};

const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("*Password enter your password.")
    .test(
      "password-complexity",
      "Password should include at least 8 characters, one number, and one letter.",
      (value) => {
        if (!value) return false;
        return /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(value);
      }
    )
    .trim(),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password")], "*Passwords does not match.")
    .required("*Confirm Password enter your password."),
});
// Customizable Area End

export default class NewPassword extends ForgotPasswordController{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEndAdornment = (btnType: string, enableField: boolean) => (
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => this.handleClickShowPassword(btnType)}
      edge="end"
      data-test-id="password-icon-btn"
      className="new-password-eye-icon"
    >
      {enableField ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <ForgotPasswordPageWrapper>
          <Typography className="forget-pwd"> New Password</Typography>
          {!this.state.isSuccessfulBlockShow ? (
            <Box className="ForgotPwd-Info-Container">
              <FormikContainer>
                <Formik
                  initialValues={initialValues}
                  validationSchema={newPasswordSchema}
                  enableReinitialize
                  data-test-id="Formik"
                  onSubmit={(values) => {
                    this.newPasswordAPI(values);
                  }}
                >
                  {({ touched, errors, getFieldProps, handleSubmit }) => {
                    return (
                      <>
                        <form onSubmit={handleSubmit}>
                          <InputField
                            style={{ marginBottom: "40px" }}
                            {...getFieldProps("password")}
                            label="Password"
                            fullWidth
                            type={
                              this.state.enablePasswordField
                                ? "password"
                                : "text"
                            }
                            startAdornment={<img src={passwordIcon} />}
                            endAdornment={this.renderEndAdornment(
                              "password",
                              this.state.enablePasswordField
                            )}
                            placeholder={"Password"}
                            error={Boolean(errors.password) && touched.password}
                            helperText={(
                              touched.password && errors.password
                            )?.toString()}
                            data-test-id="Password"
                            name="password"
                          />
                          <InputField
                            {...getFieldProps("password_confirmation")}
                            data-test-id="Confirm-Password"
                            type={
                              this.state.enableConfirmPasswordField
                                ? "password"
                                : "text"
                            }
                            id="password_confirmation"
                            name="password_confirmation"
                            placeholder={"Confirm Password"}
                            style={{ marginBottom: "40px" }}
                            label="Confirm Password"
                            startAdornment={<img src={passwordIcon} />}
                            fullWidth
                            error={
                              Boolean(errors.password_confirmation) &&
                              touched.password_confirmation
                            }
                            helperText={(
                              touched.password_confirmation &&
                              errors.password_confirmation
                            )?.toString()}
                            endAdornment={this.renderEndAdornment(
                              "confirmPassword",
                              this.state.enableConfirmPasswordField
                            )}
                          />
                          <ButtonContainer>
                            {this.state.newPwdError && (
                              <Typography
                                style={{
                                  color: "#EF4444",
                                  marginBottom: "20px",
                                  textAlign: "center",
                                }}
                                data-test-id="signUp-error"
                              >
                                {this.state.newPwdError}
                              </Typography>
                            )}
                            <Button
                              data-test-id={"Btn-EmailForgotPwd"}
                              className="ForgotPwd-Btn"
                              fullWidth
                              type="submit"
                              variant="contained"
                              endIcon={
                                <img
                                  alt="forward-arrow"
                                  src={loginForwardArrow}
                                />
                              }
                            >
                              Save
                            </Button>
                          </ButtonContainer>
                        </form>
                      </>
                    );
                  }}
                </Formik>
              </FormikContainer>
            </Box>
          ) : (
            <Box className="Pwd-Change-Container">
              <img
                src={successfulIcon}
                alt="successful-icon"
                style={{ marginBottom: "65px" }}
              />
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#6F05EA",
                  fontFamily: "Montserrat",
                  marginBottom: "10px",
                }}
              >
                Password Successfully changed.
              </Typography>
              <ButtonContainer>
                <Button
                  data-test-id={"Login-Btn"}
                  className="ForgotPwd-Btn"
                  fullWidth
                  type="submit"
                  variant="contained"
                  endIcon={<img alt="forward-arrow" src={loginForwardArrow} />}
                  onClick={() => {
                    this.goToLoginPage();
                  }}
                >
                  Proceed to login
                </Button>
              </ButtonContainer>
            </Box>
          )}
          <ImageContainer>
            <img src={LoginImage} className="Image" />
            <ImageTextContainer>
              <Typography
                className="WelCome-Text"
                style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                Welcome back!
              </Typography>
              <Typography
                className="Not-Account-Text"
                style={{ fontSize: "20px", marginTop: "40px", color: "white" }}
              >
                You don't have an account yet?{" "}
              </Typography>
              <Typography
                className="Register-Here"
                style={{
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Register <span style={{fontWeight:"700"}}>here</span>.
              </Typography>
            </ImageTextContainer>
          </ImageContainer>
        </ForgotPasswordPageWrapper>
        <AppFooter navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
