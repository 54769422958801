export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const tikTokIcon = require("../assets/tiktok-icon.svg");
export const instaGramIcon = require("../assets/instagram-icon.svg");
export const facebookIcon = require("../assets/facebook-icon.svg");
export const linkedinIcon = require("../assets/linkedin-icon.svg");
export const userAvatarImg = require("../assets/user-avatar.png");
export const locationIcon =  require("../assets/location-pin-icon.svg");
export const plusIcon =  require("../assets/plus-icon.svg");
export const searchIcon =  require("../assets/search-icon.svg");
export const downloadIcon =  require("../assets/download-icon.svg");
export const macIcon =  require("../assets/mac-icon.svg");
export const dropDownIcon =  require("../assets/drop-down.svg");
export const uploadFileIcon =  require("../assets/upload-icon.svg");
export const windowIcon =  require("../assets/window-icon.svg");
export const phoneIcon =  require("../assets/phone-icon.svg");
export const infoIcon =  require("../assets/info-icon.svg");

export const BackButton =  require("../assets/back_button.svg");
