import React from 'react';
import { Box, createTheme, styled } from "@material-ui/core";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff"
      }
    }
  });

export const defaultButton = {
    backgroundColor: 'transparent',
    backround: 'transparent',
    padding: '10px 40px',
    borderRadius: '31px',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    textTransform: 'none',
    letterSpacing: 0,
}

export const FormWrapper = styled(Box)({
    wdith: '100%',
    '& .MuiInputBase-input.MuiInput-input': {
        "&::placeholder": {
          fontStyle: 'italic'
        }
    },
    "& .form": {
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '35px'
    },
    "& .hint": {
        fontSize: '12px',
        fontWeight: 400,
        color: '#A6A6A6'
    },
    "& .redirect-link": {
        color: '#6F05EA',
        fontWeight: 700,
        cursor: 'pointer'
    },
    "& .box-wrapper": {
        marginTop: '15px',
        display: "flex",
        gap: "12px",
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up(600)]: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: "26px",
          },
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        fontSize: '16px',
        lineHeight: '16px',
        [theme.breakpoints.up(600)]: {
            fontSize: '24px',
            lineHeight: '24px'
          },
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        fontSize: '16px',
        lineHeight: '16px',
        [theme.breakpoints.up(600)]: {
            fontSize: '24px',
            lineHeight: '24px'
          },
    },
    "& .error-box": {
        display: 'flex',
        padding: '18px 16px',
        borderLeft: '3px solid #DC2626',
        backgroundColor: '#FEE2E2',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 400,
        color: '#DC2626',
    }
})