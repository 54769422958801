import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@material-ui/core";
import { UserIcon, attachFileIcon, dogImage, sendIcon, video } from "./assets";
//twilio chat
import moment,{ MomentInput } from "moment";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface UploadFile {
  file: File;
  progress: number;
}
//dummy data
const messages = [
  {
    userId: 1,
    userName:"Benjamin Thompson",
    dateDay:"Today",
    messages: [
      {
        id: 1,
        text: "Lorem Ipsum is simply dummy text of the printing and",
        timestamp: "2:20PM",
        isUser: false,
      },
      {
        id: 12,
        images: [
          dogImage,
          UserIcon,
        ],
        timestamp: "4:00PM",
        isUser: false,
      },
      {
        id: 3,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text.",
        timestamp: "2:20PM",
        isUser: false,
      },
      {
        id: 5,
        text: "More text for user 1.",
        timestamp: "3:00PM",
        isUser: true,
      },
      {
        id: 11,
        images: [
          dogImage,
          UserIcon,
          sendIcon,
          dogImage,
          attachFileIcon
        ],
        timestamp: "4:00PM",
        isUser: true,
      },
      {
        id: 20,
        video: [video],
        timestamp: "5:00PM",
        isUser: false,
      }
    ],
  },
  {
    userId: 1,
    userName:"Benjamin Thompson",
    dateDay:"03-07-2024",
    messages: [
      {
        id: 1,
        text: "Lorem Ipsum is simply dummy text of the printing and",
        timestamp: "2:20PM",
        isUser: false,
      },
      {
        id: 3,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text.",
        timestamp: "2:20PM",
        isUser: false,
      },
      {
        id: 5,
        text: "More text for user 1.",
        timestamp: "3:00PM",
        isUser: true,
      },
      {
        id: 11,
        images: [
          dogImage,
          UserIcon,
          sendIcon,
          dogImage,
          attachFileIcon
        ],
        timestamp: "4:00PM",
        isUser: true,
      }
    ],
  },
  {
    userId: 2,
    userName:"Elmer Laverty",
    dateDay:"Yesterday",
    messages: [
      {
        id: 6,
        text: "More text for user 2.",
        timestamp: "4:00PM",
        isUser: true,
      },
      {
        id: 12,
        images: [
          UserIcon,
          sendIcon,
          dogImage,
          attachFileIcon,
          dogImage
        ],
        timestamp: "4:00PM",
        isUser: false,
      },
      {
        id: 2,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        timestamp: "2:20PM",
        isUser: true,
      },
      {
        id: 4,
        text: "Another message for user 2.",
        timestamp: "3:30PM",
        isUser: false,
      },
    ],
  },
  {
    userId: 2,
    userName:"Elmer Laverty",
    dateDay:"Today",
    messages: [
      {
        id: 7,
        text: "Message for user 3.",
        timestamp: "5:00PM",
        isUser: true,
        date: "Today"
      },
      {
        id: 8,
        text: "Another message for user 3.",
        timestamp: "5:30PM",
        isUser: false,
        date: "Today"
      },
      {
        id: 9,
        text: "More text for user 3.",
        timestamp: "6:00PM",
        isUser: true,
        date: "Yesterday"
      }
    ]
  },
];
//end dummy data
//twilio chat
interface IApiModel {
  contentType?: string;
  method: string;
  body?: object;
  endPoint: string;
  isJsonStringify?: boolean;
  token?: string | null;
}
export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}
interface ViewImageDialogModel{
  isImageDialogOpen:boolean;
  viewImageArray: string[],
  imageIndex:number,
  type:string,
  timestamp:string
}
//twilio chat
interface ChatRoom {
  id: number;
  name: string;
  last_message: string;
  chat_type: string; // e.g., "single_user"
  unread_messages: number;
  muted: boolean;
  created_at: string; // ISO 8601 date format
  updated_at: string; // ISO 8601 date format
}

export interface UserData {
  phone_number: number;
  name: string;
  email: string;
  role: string; // e.g., "customer", "vendor"
  image_url: string;
}

interface TwilioConversation {
  sid: string;
  friendly_name: string;
  account_sid: string;
  unique_name: string;
  state: string; // e.g., "active"
  messaging_service_sid: string;
  attributes: string; // JSON string
  chat_service_sid: string;
  date_created: string; // ISO 8601 date format
  date_updated: string; // ISO 8601 date format
}

interface ChatData {
  current_user_data: UserData;
  chat_room: ChatRoom;
  other_user_data: UserData;
  last_message: string;
  twilio_conversation: TwilioConversation;
  error: string | null; // could be null or an error message
}

//conversation chat interface 
interface Attachment {
  id: number;
  file_name: string;
  url: string;
  file_type:any
}

export interface LocalMessage {
  id: number;
  message: string;
  account_id: number;
  chat_id: number;
  is_mark_read: boolean;
  created_at: string;
  updated_at: string;
  type: string;
  attachments: Attachment[];
}

interface TwilioMessage {
  body: string;
  author: string;
  message_sid: string;
  conversation_sid: string;
  account_sid: string;
  participant_sid: string;
  type: string;
  date_created: string;
  date_updated: string;
}

export interface MessageData {
  id: React.Key | null | undefined;
  local_message: LocalMessage;
  twilio_message: TwilioMessage;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userId:number;
  conversationId:any;
  handleBackListClick: () => void;
  //twilio chat
  userListData:ChatData[];

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IChatData | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  dummyMassageList: any;
  dummyUserName: string;
  dummyMsgDate:string;
  dummyNewMessage:string;
  replyMessageData:{messageId: number, message:string, image: string, type:string };
  isReplyMessage:boolean;
  viewImageDialog:ViewImageDialogModel,
  uploadDocumentData:{uploadFiles:UploadFile[],completedFiles:File[]}
  isUploadDocumentDialogOpen:boolean,
  uploadDocumentTypeError:string,
//twilio chat
      twilioClient: string | null,
      conversations: any,
      twiliomessages: any,
      activeConversation: any,
      messageInput: string,
      isLoading:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  getCoversationListId: string = "";
  sendMessagePostApi: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      dummyMassageList:[],
      dummyUserName:"",
      dummyMsgDate:"",
      dummyNewMessage:"",
      replyMessageData:{
        messageId: 0,
        message: "",
        image: "",
        type:"",
      },
      isReplyMessage:false,
      viewImageDialog:{
      isImageDialogOpen:false,
      viewImageArray:[] ,
      imageIndex:0,
      type:"",
      timestamp:""
      },
      uploadDocumentData:{uploadFiles: [], completedFiles:[]},
      isUploadDocumentDialogOpen:false,
      uploadDocumentTypeError:"",
      //twilio chat
      twilioClient: null,
      conversations: '',
      twiliomessages: '',
      activeConversation: null,
      messageInput: '',
      isLoading:true

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  uploadIntervals: { [key: string]: NodeJS.Timeout } = {}; 
  async componentDidMount() {
    super.componentDidMount();
    if (this.props.userListData.length > 0) {
      this.setState({ isLoading: false });
    this.filterMessagesByUserId();
    this.GetUserConversationData();

    }

    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
    Keyboard.addListener("keyboardDidShow", this._keyboardDidShow.bind(this));
    Keyboard.addListener("keyboardDidHide", this._keyboardDidHide.bind(this));
  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
  
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return !string || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hidePreviewModal = () => {
    this.setState({ isVisiblePreviewModal: false, imageUrl: '', docRes: null });
  };

  handleAccountIdInputChange = (accountIdInput: string) => {
    this.setState({ accountIdInput });
  };

  handleMessageChange = (message: string) => {
    this.setState({ message });
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.files) {
      const file = event.target.files[0] as Blob;
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.setState({ imageUrl: readerEvent.target?.result as string, docRes: file, isVisiblePreviewModal: true });
      };
      reader.readAsDataURL(file);
      this.setState({ docRes: file, isVisiblePreviewModal: true });
    }
    else {
      this.setState({ imageUrl: "", docRes: null })
    }
  };

  handleSendMessage = () => {
    this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
    this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
  };

  handleInsertImage = () => {
    this.setState({isUploadDocumentDialogOpen:true})
  };

  getChatDetails = (token: string, chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUserToChat = (accountIdInput: string, chatId: number) => {
    if (!accountIdInput || this.isStringNullOrBlank(accountIdInput)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        accounts_id: [Number(accountIdInput)],
        chat_id: Number(chatId),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addUserToChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addUserToChatApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  leaveChatRoom = (chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: parseInt(chatId + "", 10),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveChatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (chatId: number, message: string, imageUrl?: string) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("message[message]", message);
    if (imageUrl) {
      formData.append("message[attachments][]", (this.state.docRes as Blob));
      this.setState({ docRes: null })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}/${chatId}/messages`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeNotificationStatus = () => {
    const { muted } = this.state;
    if (muted === null) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: !muted });
    }
    this.toggleMute();
  };

  toggleMute = () => {
    const { chatId, muted } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat: { muted: !muted },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleMuteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReadMessages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: this.state.chatId,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openFile = async () => {
    try {
      const response = await DocumentPicker.pickSingle({
        type: [
          DocumentPicker.types.images,
        ],
      });

      if (response) {
        this.setState({ imageUrl: response.uri, isVisiblePreviewModal: true, docRes: response })
      }
    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        runEngine.debugLog("Message Recived", "User Canceled Picker");
      } else {
        runEngine.debugLog("Message Recived", error);
      }
    }
  };

  inputAccountIdProps = {
    onChangeText: (text: string) => {
      this.setState({ accountIdInput: text });
    },
  };

  inputMessageProps = {
    onChangeText: (text: string) => {
      this.setState({ message: text });
    },
  };

  btnAddAccountProps = {
    onPress: () =>
      this.addUserToChat(this.state.accountIdInput, this.state.chatId),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => this.showModal(),
  };

  btnLeaveChatProps = {
    onPress: () => this.leaveChatRoom(this.state.chatId),
  };

  btnSendMessageProps = {
    onPress: () => {
      this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };

  btnClosePreviewModal = {
    onPress: () => this.hidePreviewModal(),
  }

  btnMuteProps = {
    onPress: () => this.changeNotificationStatus(),
  };

  btnInsertPhotoProps = {
    onPress: () => this.openFile(),
  };

  _keyboardDidShow = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: event.endCoordinates.height });
  };

  _keyboardDidHide = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: 0 });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) this.setState({ token });
      this.refreshChatInterval = setInterval(
        () => this.getChatDetails(this.state.token, this.state.chatId),
        30000
      );
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const chatData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const { chatId } = chatData;
      this.setState({ chatId }, () =>
        this.getChatDetails(this.state.token, chatId)
      );
    }
    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getChatApiCallId &&
      responseJson && responseJson.data
    ) {
      const chatData = responseJson.data;
      const { muted } = chatData.attributes.accounts_chats.find(
        (item: { attributes: { account_id: number } }) =>
          item.attributes.account_id === this.state.accountId
      ).attributes;
      this.setState({
        chatData,
        muted,
      });
      this.updateReadMessages();
    }
    if (
      restApiDataCondition &&
      apiRequestCallId === this.addUserToChatApiCallId
    ) {
      this.hideModal();
      this.getChatDetails(this.state.token, this.state.chatId);
    }
    
    if(apiRequestCallId===this.getCoversationListId){
      this.handleResponseData(responseJson)
    }
    if(apiRequestCallId===this.sendMessagePostApi){
      this.handleSendMessageResponse(responseJson)
    }
  }
//dummy data message filter
componentDidUpdate(prevProps:any) {
  if (this.props.userId !== prevProps.userId) {
    this.filterMessagesByUserId();
    this.GetUserConversationData();
  }
  if (prevProps.userListData !== this.props.userListData && this.props.userListData.length > 0) {
    this.setState({ isLoading: false });
  }
}

filterMessagesByUserId = () => {
  const { userId, userListData } = this.props;

  if (userListData && userListData.length > 0) {
  const userMessages = this.props.userListData.find((chatData: { chat_room: { id: number; }; }) => chatData.chat_room.id === userId);
  if (userMessages) {
    this.setState({  dummyUserName: userMessages.other_user_data.name, dummyMsgDate:this.formatDate(userMessages.chat_room.updated_at),imageUrl:userMessages.other_user_data.image_url }); 
  
  }  
}
}

handleInputChange = (event:ChangeEvent<HTMLInputElement>) => {
  this.setState({ dummyNewMessage: event.target.value });
}
handleReplyMessageClick = (message: string, image: string | null = null, type: string = "") => {
  this.setState((prevState) => ({
    replyMessageData: {
      ...prevState.replyMessageData,
      message: image ? "" : message,
      image: image || "",
      type:type || "",
    },
    isReplyMessage:true
  }));
}

dummyHandleSendMessage = () => {
  const formattedDate =  new Date().toISOString()
  const { userId } = this.props;
  const newMessageObject = {
    id: new Date().getTime(),
    local_message: {
      id: new Date().getTime(),
      message: this.state.dummyNewMessage,
      account_id: userId, 
      chat_id: userId,
      is_mark_read: false,
      created_at: formattedDate, // Use current time
      updated_at: formattedDate,
      type: 'sender', 
      attachments: [...this.state.uploadDocumentData.uploadFiles], // Attach uploaded files here

    }
  };

  // Handle reply message logic if `isReplyMessage` is true
  if (this.state.isReplyMessage) {
    this.setState((prevState) => ({
      replyMessageData: {
        ...prevState.replyMessageData,
        messageId: newMessageObject.id,
      },
      isReplyMessage: false,
    }));
  }

  // Add the new message to `dummyMessageList`
  this.setState((prevState) => ({
    dummyMassageList: [...prevState.dummyMassageList, newMessageObject],
    dummyNewMessage: '',
  }), () => {});
  this.sendMessageToserver();
};


handleReplyClose = () => {
  this.setState({isReplyMessage:false});
}
formatTimeAgo3 = (timestamp: string) => {
  const date = moment(timestamp);
  return date.format('hh:mm A'); // e.g., "08:20 PM"
};
handleImageClick = (images:any, type:string, timestamp:string) => {
  this.setState((prevState) => ({
    viewImageDialog: {
      ...prevState.viewImageDialog,
      viewImageArray:images,
      isImageDialogOpen:true,
      type:type,
      timestamp:this.formatTimeAgo3(timestamp)
    },
  }))
};
handleImageCloseDialog = () => {
  this.setState((prevState) => ({
    viewImageDialog: {
      ...prevState.viewImageDialog,
      viewImageArray:[],
      isImageDialogOpen:false, 
    },
  }))
};
handleImageDialogPrev = () => {
  this.setState((prevState) => ({
    viewImageDialog: {
      ...prevState.viewImageDialog,
      imageIndex:
        prevState.viewImageDialog.imageIndex === 0
          ? prevState.viewImageDialog.viewImageArray.length - 1
          : prevState.viewImageDialog.imageIndex - 1
    }
  }));
};
handleImageDialogNext = () => {
  this.setState((prevState) => ({
    viewImageDialog: {
      ...prevState.viewImageDialog,
      imageIndex:
        prevState.viewImageDialog.imageIndex === prevState.viewImageDialog.viewImageArray.length - 1
          ? 0
          : prevState.viewImageDialog.imageIndex + 1
    }
  }));
};
//end dummy data message filter
handleUploadDocumentCloseDialog = () => {
  this.setState({isUploadDocumentDialogOpen:false, uploadDocumentTypeError:"" ,uploadDocumentData:{uploadFiles:[], completedFiles:[]}})
}
handleDrop = (acceptedFiles: File[]) => {
  const fileTypeError = acceptedFiles.every(file => ['image/png', 'video/mp4', 'image/jpeg'].includes(file.type));
  if(!fileTypeError){
    this.setState({uploadDocumentTypeError:"Only PNG, JPEG and MP4 files are allowed"})
  }
  else{
    const filesWithProgress: UploadFile[] = acceptedFiles.map(file => ({
      file,
      progress: 0, 
    }));
    this.setState((prevState) => ({
      uploadDocumentData: {
        ...prevState.uploadDocumentData,
        uploadFiles: [...prevState.uploadDocumentData.uploadFiles, ...filesWithProgress],
      },
    }));
    filesWithProgress.forEach(uploadFile => {
      this.uploadFile(uploadFile);
    });
  }
};

uploadFile = (uploadFile: UploadFile) => {
  const { file } = uploadFile;
  const interval = setInterval(() => {
    if (uploadFile.progress < 100) {
      const newProgress = uploadFile.progress + 10; 
      uploadFile.progress = newProgress;
      this.setState((prevState) => ({
        uploadDocumentData: {
          ...prevState.uploadDocumentData,
          uploadFiles: [...this.state.uploadDocumentData.uploadFiles]
        },
      }));
    } else {
      clearInterval(interval);
      delete this.uploadIntervals[file.name];
      this.setState((prevState) => ({
        uploadDocumentData: {
          ...prevState.uploadDocumentData,
          uploadFiles: prevState.uploadDocumentData.uploadFiles.filter(f => f.file !== file),
          completedFiles: [...prevState.uploadDocumentData.completedFiles, file],
        },
      }));
    }
  }, 500);
  this.uploadIntervals[file.name] = interval;
};
handleUploadDocumentCloseClick = (DeleteFile:File) => {
  clearInterval(this.uploadIntervals[DeleteFile.name]);
    delete this.uploadIntervals[DeleteFile.name];
this.setState((prevState) => ({
  uploadDocumentData: {
    ...prevState.uploadDocumentData,
    uploadFiles: prevState.uploadDocumentData.uploadFiles.filter(f => f.file !== DeleteFile),
    completedFiles: prevState.uploadDocumentData.completedFiles.filter(file => file !== DeleteFile),
  }}));
}
  handleUploadFileClick = () => {
    const { userId } = this.props;
    const images = this.state.uploadDocumentData.completedFiles.map((file, index) => {
      const url = URL.createObjectURL(file); // Create a temporary URL for the file
      const fileType = file.type || 'application/octet-stream'; // Default to binary if type is missing
      return {
        id: index,
        file_name: file.name,
        file_type: fileType, // Add file_type here
        url: url,
      };
    });
  
    this.setState({ isUploadDocumentDialogOpen: false });
  
    if (this.state.uploadDocumentData.completedFiles.length > 0) {
      const newMessageDocumentObject = {
        id: new Date().getTime(),
        local_message: {
          id: new Date().getTime(),
          message: '', // No text as it's just an upload
          account_id: userId,
          chat_id: userId,
          is_mark_read: false,
          created_at: new Date().toISOString(), // Ensure consistent timestamp format
          updated_at: new Date().toISOString(),
          type: 'sender',
          attachments: images,
        },
        twilio_message: {}, // If needed, provide empty object or values
      };
  
      // Clear the upload data state
     
  
      // Update message list based on existing messages
      this.setState(
        (prevState) => ({
          dummyMassageList: [...prevState.dummyMassageList, newMessageDocumentObject],
          dummyNewMessage: '',
          uploadDocumentData: { uploadFiles: [], completedFiles: [] },
          uploadDocumentTypeError: "",
        }),
        () => {
          this.sendMessageToserver();
        }
      );
    }

  };
  
 
  loadConversations = async (client: { getSubscribedConversations: () => any; }) => {
    const conversations = await client.getSubscribedConversations();
    this.setState({ conversations: conversations.items });
  };

  // Fetch messages from a specific conversation
  loadMessages = async (conversation: string) => {
    this.setState({ activeConversation: conversation,  });
  };

  // Send message to the active conversation
  sendMessage = async () => {
    const { activeConversation, messageInput } = this.state;
    if (activeConversation && messageInput) {
      await activeConversation.sendMessage(messageInput);
      this.setState({ messageInput: '' });
      this.loadMessages(activeConversation); // Refresh messages after sending
    }
  };
  //twilio chat
  formatDate(createdAt: MomentInput) {
    const date = moment(createdAt);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
  
    if (date.isSame(today, 'day')) {
      return "Today";
    } else if (date.isSame(yesterday, 'day')) {
      return "Yesterday";
    } else {
      return date.format('DD/MM/YY'); // Format as '24/10/24'
    }
  }
  GetUserConversationData = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    this.getCoversationListId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint:`${configJSON.getCoversationendpoint}?conversation_sid=${this.props.conversationId}&chat_id=${this.props.userId}`,
      token:userInfoToken
    });
  };
  apiCall = async (data: IApiModel) => {
    const { method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
      header = {
        token: token,
      };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  handleResponseData(response:any){
    if(!response.errors){
      this.setState({dummyMassageList:response.response})
    }
  }
  handleSendMessageResponse(response:any){
     if(response){
      if(response.data){
        this.setState({isLoading:false})
      }else if(response.errors){
        this.showAlert('!Alert',response.errors)
      }
    }
  }
  checklastmsg() {
    const lastMessage = this.state.dummyMassageList[this.state.dummyMassageList.length - 1];
    const attachments = lastMessage?.local_message?.attachments || [];
    return attachments
  }
  sendMessageToserver = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    const formdata = new FormData();
    formdata.append("chat_id", this.props.userId.toString());
    formdata.append("conversation_sid", this.props.conversationId);
    if (this.state.dummyNewMessage !== '') {
      formdata.append("message", this.state.dummyNewMessage);
      this.sendMessagePostApi = await this.apiCall({
        method: configJSON.postApiMethod,
        endPoint: configJSON.sendMessageEndpont,
        token: userInfoToken,
        body: formdata,
        isJsonStringify: false,
      });
    } else {
      this.sendAttachmentToServer()
    }
   
  };
  sendAttachmentToServer= async () =>{
    const userInfoToken = localStorage.getItem("login_token");
      const formdata = new FormData();
      formdata.append("chat_id", this.props.userId.toString());
      formdata.append("conversation_sid", this.props.conversationId);
      formdata.append("message", this.state.dummyNewMessage);
      const attachments = await this.checklastmsg()
      if (Array.isArray(attachments) && attachments.length > 0) {
        for (const attachment of attachments) {
          if (attachment.url) {
              // Fetch and convert the URL to a Blob object
              const fileBlob = await fetch(attachment.url).then((res) => res.blob());
              // Append the file to FormData using "attachments[]" format
              formdata.append("attachments[]", fileBlob, attachment.file_name);
          }
        }
      }
      this.sendMessagePostApi = await this.apiCall({
        method: configJSON.postApiMethod,
        endPoint: configJSON.sendMessageEndpont,
        token: userInfoToken,
        body: formdata,
        isJsonStringify: false,
      });
  }

async downloadMedia(mediaUrl: string, type: 'image' | 'video') {
  const userInfoToken = localStorage.getItem("login_token");

  try {
      const response = await fetch(mediaUrl, {
          headers: {
              Authorization: `Bearer ${userInfoToken}`,
          },
      });

      if (!response.ok) throw new Error("Failed to fetch media");

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;

      // Set the appropriate download filename based on the media type
      link.download = type === 'video' ? 'downloaded-video.mp4' : 'downloaded-image.jpg';
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobUrl); // Clean up the Blob URL after download
  } catch (error) {
      console.error("Error downloading media:", error);
  }
}

  // Customizable Area End
}
