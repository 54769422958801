Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.httpContentType = "application/json";
exports.httpContentTypeFromData = "multipart/form-data";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";

exports.mainMenuCalendarLabel = 'Calendar';
exports.mainMenuPetsLabel = 'Pets';
exports.mainMenuWalletLabel = 'Wallet';
exports.mainMenuSettingsLabel = 'Settings';

exports.medicalConditions = "Medical conditions";
exports.medicalConditionsPlaceholder = "Please insert any illness that conditions your pet’s daily life";
exports.allergies = "Allergies";
exports.allergiesPlaceholder = "Please insert any allergies your pet may have";
exports.usualMedication = "Usual medication";
exports.usualMedicationPlaceholder = "Please insert medicine your pet is taking";
exports.lastCheckup = "Last health check up";
exports.lastCheckupPlaceholder = "Your pet's last health check up";
exports.microcheap = "Microchip number";
exports.microcheapPlaceholder = "Your pet's microchip number";

exports.clinic = "Clinic/Hospital";
exports.phone = "Phone";
exports.clinicAddress = "Clinic Address";
exports.doctor = "Doctor";
exports.company = "Company";
exports.policy = "Policy number";
exports.primaryContact = "Primary Contact";
exports.secondaryContact = "Secondary Contact";

exports.gender = "Gender";

exports.editBtn = "Edit";
exports.cancelBtn = "Cancel";
exports.saveBtn = "Save";
exports.deleteBtn = "Delete pet";
exports.continueBtn = "Continue";

exports.calm = "Calm";
exports.energetic = "Energetic";
exports.relaxed = "Relaxed";
exports.anxious = "Anxious";
exports.easyGoing = "Easy-going";
exports.shy = "Shy";
exports.friendly = "Friendly";
exports.aggressive = "Aggressive";
exports.gentle = "Gentle";
exports.strong = "Strong";
exports.obedient = "Obedient";
exports.rebbelious = "Rebbelious";

exports.personality = "Personality";
exports.personalityDesc = "How would you describe your pet in unknown environments?";
exports.habits = "Habits & Routines";

exports.selectPlaceholder = "Select";
exports.enterPlaceholder = "Enter";
exports.walks = "How many walks";
exports.walksShedules = "Walking schedules";
exports.potty = "How many potty";
exports.pottyShedules = "Potty schedules";
exports.meal = "How many meals";
exports.mealsShedule = "Meal schedules";

exports.myPets = "My Pets";
exports.name = "Name";
exports.general = "General";
exports.medical = "Medical";
exports.traits = "Traits";
exports.addNewPet = "Add new pet";
exports.insurance = "Insurance";

exports.petCategory = "Animal";
exports.species = "Species";
exports.breed = "Breed";
exports.weight = "Weight";
exports.size = "Size";
exports.fur = "Fur";

// Settings
exports.settings = "Settings";
exports.personal = "Personal";
exports.security = "Security & Privacy";
exports.payment = "Payment";

exports.firstName = 'First Name';
exports.lastName = 'Last Name';
exports.nationality = "Nationality";
exports.language = "Language";
exports.birthdate = "Birthdate";
exports.address = "Address";
exports.country = "Country";
exports.zipcode = "Zip Code";
exports.city = "City";

exports.wish = "Do you wish to Change ?";

exports.loginApiEndPoint = "bx_block_login/logins";
exports.petListApiEndPoint = "account_block/animal_types";
exports.createPetMedicalsEndPoint = "bx_block_categories/pet_medicals";
exports.createPetTreatsEndPoint = "bx_block_categories/traits";
exports.updatePetMedicalsEndPoint = "bx_block_categories/pet_medicals";
exports.updatePetTreatsEndPoint = "bx_block_categories/traits";
exports.updatePetGeneralEndPoint = "account_block/animal_types";
exports.deletePetEndPoint = "account_block/animal_types";
exports.getPetMedical = "bx_block_categories/pet_medicals";
exports.getPetTreats = "bx_block_categories/traits";
exports.getLoggedSessions = "account_block/logged_sessions";
exports.getPaymentMethods = "bx_block_stripe_integration/payment_methods/card_list";
exports.updatePassword = "account_block/accounts/change_password";
exports.updateEmail = "account_block/accounts/change_email_address";
exports.deleteAccount = "account_block/accounts/";
exports.getAccountData = "account_block/accounts/";
exports.updateAccountData = "bx_block_settings/settings/personal_setting";
exports.addNewCard = "bx_block_stripe_integration/payment_methods/create_card";
exports.updateCard = "bx_block_stripe_integration/payment_methods/set_default_card";
exports.deleteCard = "bx_block_stripe_integration/payment_methods/delete_card";
exports.showGallery='account_block/services/show_gallery';
exports.UploadGallary='account_block/services/upload_gallery';
exports.DeleteGallary='account_block/services/delete_gallery';
exports.deleteSessionEndPoint ='account_block/logged_sessions/end_device_session';
exports.validationApiContentType = "application/json";
exports.GetapponitmentList ="bx_block_calendar/vendor_calendar?filter_type";
exports.getVendorCalendarEndpoint = "bx_block_calendar/vendor_calendar?";
exports.getVendorCalendarEvents = "bx_block_calendar/vendor_calendar/date_wise_booked_data?"
exports.getRequestListEndpoint = "bx_block_request_management/requests";
exports.getAppointmentsEndpoint = "bx_block_calendar/customer_calendar?";
exports.removePhoto = "account_block/animal_types/remove_photo?id=";
exports.getAvailablityEndpoint = "bx_block_calendar/availabilities";
exports.getBookingAvailablityEndpoint = "bx_block_calendar/availabilities/show_availablitiy";
exports.sendBookingEmailEndpoint = "bx_block_calendar/vendor_calendar/booking_email";
exports.downloadCSVEndpoint = "bx_block_calendar/availabilities/show_csv";
exports.breedList = "bx_block_admin/animal_breeds/show_animal_breeds";
exports.noImageUrl = 'https://via.placeholder.com/600x600.png?text=No+Image';
exports.shift = 'Updated Time';
exports.RequestList='bx_block_calendar/vendor_calendar?'
exports.dummyAppointmentCardData = [
  {
    id:"1",
    date:"Wed, 2 June - Sat, 5 June",
    name:"Farrusco",
    serviceName:"Top Groomer",
    time:"Friday 15:30 - 17:30"
  },
  {
    id:"2",
    date:"Wed, 5 July - Sat, 6 July",
    name:"Rio",
    serviceName:"Top Groomer",
    time:"Sunday 10:00 - 12:30"
  },
  {
    id: "3",
    date: "Mon, 10 August - Wed, 12 August",
    name: "Bella",
    serviceName: "Pet Spa",
    time: "Tuesday 14:00 - 16:00"
  },
  {
    id: "4",
    date: "Thu, 15 September - Sun, 18 September",
    name: "Max",
    serviceName: "Vet Checkup",
    time: "Saturday 09:00 - 11:00"
  },
]

exports.defaultPetData = {
  birthdate: '',
  breed: '',
  fur: '',
  gender: '',
  name: '',
  pet_category: '',
  pet_photo: null,
  size: '',
  species: '',
  weight: '',
}

exports.GENERAL_INFO_SECTIONS = {
  GENERAL: "General",
  COMMODITIES: "Commodities",
  SERVICES: "Services",
  SPACES: "Spaces",
  ROOMS: "Rooms",
  PRICES: "Prices",
}
exports.SERVICES_ENUM = {
  DAY_CARE: "Day Care",
  DOG_WALKING: "Dog Walking",
  HOTELS: "Hotels",
  GROOMING: "Grooming",
  PET_SITTING: "Pet Sitting",
  TRAINING: "Training"
}
exports.ENDPOINTS = {
  GET_GENERAL_DETAILS: "account_block/services/show_services",
  CREATE_GENERAL_DETAILS: "account_block/services",
  UPDATE_GENERAL_DETAILS: "account_block/services",
  UPDATE_SERVICE_GALLERY: "account_block/services/upload_gallery?id=",
  DELETE_SERVICE_GALLERY: "account_block/services/delete_gallery?id="
}

exports.getCommoditiesEndPoint = "account_block/services/show_services?";
exports.updateCommoditiesEndPoint = "account_block/services/";
exports.addCommoditiesEndPoint = "account_block/services";

exports.addSpaceEndPoint = "account_block/sub_services";
exports.getSpaceEndPoint = "account_block/sub_services?service_id=";
exports.updateSpaceEndPoint = "account_block/sub_services/";

exports.getRoomEndPoint = "account_block/sub_services?service_id=";
exports.addRoomEndPoint = "account_block/sub_services";
exports.updateRoomEndPoint = "account_block/sub_services/";

exports.getServiceEndPoint = "account_block/sub_services?service_id=";
exports.addServiceEndPoint = "account_block/sub_services";
exports.updateServiceEndPoint = "account_block/sub_services/";
// Customizable Area End
