// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IFilteredService, IPriceDetails, ISubServicesList, IOherServiceForm } from "../../../../components/src/interfaces.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import { generateSubServicePrices, isValidNumber, returnTruthyString } from "../../../../components/src/HelperUtils";

const initalTransportation = {
    checked: false,
    title: "transportation",
    id: "",
    price: "",
    sub_service_id: ""
}
const initalMedication = {
    checked: false,
    title: "medication",
    id: "",
    price: "",
    sub_service_id: ""
}
interface IGetPriceDetailsResp {
    data: {
        sub_services: ISubServicesList[],
        data: IPriceDetails[]
    },
    message?: string
}

type TSwtichKey = "transportation" | "medication"

interface ISubServicesForm {
    sub_service_title: string,
    sub_service_id: string,
    price_data: ISubServiceAttrib[]
}
interface ISubServiceAttrib {
    "id": string,
    "title": string,
    "price": string | number,
    "sub_service_id": string
}
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    selectedService: IFilteredService;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    apiToken: string,
    isEditOn: boolean,
    subServicesForm: ISubServicesForm[],
    savedSubServicesForm: ISubServicesForm[],
    transportation: IOherServiceForm,
    medication: IOherServiceForm,
    savedTransportation: IOherServiceForm,
    savedMedication: IOherServiceForm,
    // ERROR
    errorArray: ISubServiceAttrib[],
    // API
    subServicesList: ISubServicesList[],
    getPriceDetails: IPriceDetails[],
    getPriceDetailsLoading: boolean,
    deleteComplementary: ISubServiceAttrib[],
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class PricesVerticalController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getPriceDetailsCallId: string = "";
    createPriceDetailsCallId: string = "";
    updatePriceDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            apiToken: localStorage.getItem("login_token") || "",
            isEditOn: false,
            subServicesForm: [],
            savedSubServicesForm: [],
            transportation: initalTransportation,
            medication: initalMedication,
            savedTransportation: initalTransportation,
            savedMedication: initalMedication,
            // ERROR
            errorArray: [],
            // API
            subServicesList: [],
            getPriceDetails: [],
            getPriceDetailsLoading: false,
            deleteComplementary: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.getPriceDetailsCallId:
                        this.getPriceDetailsResp(responseJson);
                        break;
                    case this.createPriceDetailsCallId:
                        this.createPriceDetailsResp(responseJson);
                        break;
                    case this.updatePriceDetailsCallId:
                        this.updatePriceDetailsResp(responseJson);
                        break;
                    default:
                        break;
                }
            }

        }
        // Customizable Area End
    }
    // Customizable Area Start

    getPriceDetailsResp = (responseJson: IGetPriceDetailsResp) => {
        this.setState({ getPriceDetailsLoading: false })
        if (responseJson && responseJson.data && !responseJson.message) {
            this.setSubServicesForms(responseJson)
        } else {
            this.setState({
                subServicesList: [],
                getPriceDetails: [],
                subServicesForm: [],
                savedSubServicesForm: [],
                transportation: initalTransportation,
                savedTransportation: initalTransportation,
                medication: initalMedication,
                savedMedication: initalMedication,
            })
        }
    }
    createPriceDetailsResp = (responseJson: { message: string }) => {
        if (responseJson && responseJson.message) {
            this.setState({ isEditOn: false })
            this.getPriceDetailsFn()
        }
    }
    updatePriceDetailsResp = (responseJson: { message: string }) => {
        if (responseJson && responseJson.message) {
            this.setState({ isEditOn: false, deleteComplementary: [] })
            this.getPriceDetailsFn()
        }
    }

    async componentDidMount() {
        this.fetchInitialApicall()
    }

    getPriceDetailsFn = async () => {
        const establishmentId = await getStorageData("establishment_ID");
        this.setState({
            getPriceDetailsLoading: true
        })
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        const header = { token: this.state.apiToken };

        const endpoint = `account_block/sub_services_prices?establishment_id=${establishmentId}&service_type=${this.props.selectedService.name}`
        this.getPriceDetailsCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(reqMessage.id, reqMessage);
    };


    setSubServicesForms = (responseJson: IGetPriceDetailsResp) => {
        const sub_service_data = responseJson.data.sub_services
        const price_data = responseJson.data.data
        const subServiceFormInitial = this.getInitialServiceForm(sub_service_data)

        if (sub_service_data.length && !price_data.length) {
            this.setState({
                subServicesList: sub_service_data,
                getPriceDetails: [],
                // FORMS
                subServicesForm: subServiceFormInitial,
                savedSubServicesForm: subServiceFormInitial,
                transportation: initalTransportation,
                savedTransportation: initalTransportation,
                medication: initalMedication,
                savedMedication: initalMedication,
            })
        }

        if (sub_service_data.length && price_data.length) {

            subServiceFormInitial.forEach((subService) => {
                subService.price_data.forEach((priceObj) => {
                    const matchingPrice = price_data.find((data) =>
                        (String(data.attributes.sub_service_id) == String(priceObj.sub_service_id)) &&
                        data.attributes.title == priceObj.title
                    );
                    if (matchingPrice) {
                        priceObj.id = returnTruthyString(matchingPrice.attributes.id);
                        priceObj.price = returnTruthyString(matchingPrice.attributes.price);
                    }
                });
            });
            const findByTitle = (title: string) => price_data.find((record) => record.attributes.title == title)
            const transportationObj = findByTitle("transportation")
            const transportation = {
                title: "transportation",
                checked: Boolean(transportationObj),
                id: returnTruthyString(transportationObj?.attributes.id),
                price: returnTruthyString(transportationObj?.attributes.price),
                sub_service_id: returnTruthyString(transportationObj?.attributes.sub_service_id),
            }
            const medicationObj = findByTitle("medication")
            const medication = {
                title: "medication",
                checked: Boolean(medicationObj),
                id: returnTruthyString(medicationObj?.attributes.id),
                price: returnTruthyString(medicationObj?.attributes.price),
                sub_service_id: returnTruthyString(medicationObj?.attributes.sub_service_id)
            }
            this.setState({
                getPriceDetails: responseJson.data.data,
                subServicesList: sub_service_data,
                // FORMS
                subServicesForm: subServiceFormInitial,
                transportation: transportation,
                medication: medication,
                // SAVED
                savedTransportation: transportation,
                savedSubServicesForm: subServiceFormInitial,
                savedMedication: medication,
            })
        }
        if (!sub_service_data.length && !price_data.length) {
            this.setState({
                getPriceDetails: [],
                subServicesList: [],
                // FORMS
                subServicesForm: [],
                savedSubServicesForm: [],
                transportation: initalTransportation,
                medication: initalMedication,
                savedTransportation: initalTransportation,
                savedMedication: initalMedication,
            })
        }
    }
    fetchInitialApicall = () => {
        if (this.props.selectedService.service_id) {
            this.getPriceDetailsFn()
        } else {
            // RESET STATES
            this.setState({
                getPriceDetailsLoading: false,
                getPriceDetails: [],
                subServicesList: [],
                // FORMS
                subServicesForm: [],
                savedSubServicesForm: [],
                transportation: initalTransportation,
                medication: initalMedication,
                savedTransportation: initalTransportation,
                savedMedication: initalMedication,
            })
        }
    }
    getInitialServiceForm = (subServiceList: ISubServicesList[]) => {
        let data: ISubServicesForm[] = []
        if (subServiceList.length) {
            subServiceList.forEach((subService) => {
                const price_data = generateSubServicePrices(subService);
                const service: ISubServicesForm = {
                    sub_service_title: subService.title,
                    sub_service_id: String(subService.id),
                    price_data: price_data
                }
                data.push(service);
            })
        }
        return data
    }

    setMedication = (checkValue: boolean) => {
        this.setState({
            medication: {
                ...this.state.medication,
                checked: checkValue
            }
        });
        const serviceExists = this.state.deleteComplementary.some(item => item.id == this.state.medication.id);
        if (!checkValue && this.state.medication.id && !serviceExists) {

            this.setState(prevState => ({
                deleteComplementary: [
                    ...prevState.deleteComplementary,
                    { ...prevState.medication, checked: checkValue }
                ]
            }));
        }
        if (checkValue && serviceExists) {
            this.setState(prevState => ({
                deleteComplementary: prevState.deleteComplementary.filter(item => item.id !== this.state.medication.id)
            }));
        }
    }

    setSwitchCheck = (checkedValue: boolean, key: TSwtichKey) => {
        if (key == "transportation") {
            this.setState({
                transportation: {
                    ...this.state.transportation,
                    checked: checkedValue
                }
            });
            const serviceExist = this.state.deleteComplementary.some(item => item.id == this.state.transportation.id);
            if (!checkedValue && this.state.transportation.id && !serviceExist) {
                this.setState(prevState => ({
                    deleteComplementary: [
                        ...prevState.deleteComplementary,
                        { ...prevState.transportation, checked: checkedValue }
                    ]
                }));
            }
            if (checkedValue && serviceExist) {
                this.setState(prevState => ({
                    deleteComplementary: prevState.deleteComplementary.filter(item => item.id !== this.state.transportation.id)
                }));
            }
        } else {
            this.setMedication(checkedValue);
        }

    };
    setSwitchPrice = (price_value: string, state_key: TSwtichKey) => {
        const validValue = isValidNumber(price_value) ? price_value : ""
        if (state_key == "transportation") {
            this.setState({
                transportation: {
                    ...this.state.transportation,
                    price: validValue
                }
            });
        } else {
            this.setState({
                medication: {
                    ...this.state.medication,
                    price: validValue
                }
            });
        }
    };
    handleCancelClick = () => {
        this.setState({
            isEditOn: false,
            subServicesForm: this.state.savedSubServicesForm,
            transportation: this.state.savedTransportation,
            medication: this.state.savedMedication,
            errorArray: [],
            deleteComplementary: []
        })
    }
    handleEditClick = () => {
        this.setState({
            isEditOn: true
        })
    }
    setPriceValue = (inputValue: string, price_key: string, sub_service_id: string) => {
        const validValue = isValidNumber(inputValue) ? inputValue : ""
        const updatedForm = this.state.subServicesForm.map((service) => {
            if (service.sub_service_id === sub_service_id) {
                return {
                    ...service,
                    price_data: service.price_data.map((priceItem) => {
                        if (priceItem.title === price_key) {
                            return {
                                ...priceItem,
                                price: validValue
                            };
                        }
                        return priceItem;
                    })
                };
            }
            return service
        });
        this.setState({
            subServicesForm: updatedForm
        })
    }
    getError = (title: string, sub_service_id: string, byTitle: boolean) => {
        const copyErrorArr = [...this.state.errorArray]

        let result = {
            error: false,
            errorMsg: ""
        }
        if (byTitle) {
            const matchingObj = copyErrorArr.find((errorObj) => (errorObj.title == title))
            if (matchingObj) {
                result.error = true
                result.errorMsg = "*Please enter valid Number"
            }
        } else {
            const matchingObj = copyErrorArr.find((errorObj) => (errorObj.title == title) && (errorObj.sub_service_id == sub_service_id))
            if (matchingObj) {
                result.error = true
                result.errorMsg = "*Please enter valid Number"
            }
        }
        return result
    }
    validateFormData = (bodyData: ISubServiceAttrib[]) => {
        const errorArr: ISubServiceAttrib[] = []
        const copyBodyArr: ISubServiceAttrib[] = JSON.parse(JSON.stringify(bodyData))

        copyBodyArr.forEach((record) => {
            if (!isValidNumber(String(record.price))) {
                errorArr.push(record)
            }
        })
        this.setState({
            errorArray: errorArr
        })
        return !errorArr.length
    }

    handleSaveClick = async () => {
        const { transportation, medication, subServicesList, subServicesForm } = this.state

        const combinedPriceData = [...subServicesForm].flatMap(service => service.price_data);

        // TRANSPORTATION BODY
        const notAllowedServices = [webConfigJSON.SERVICES_ENUM.PET_SITTING, webConfigJSON.SERVICES_ENUM.DOG_WALKING]
        const isShowTransportation = !notAllowedServices.includes(this.props.selectedService.name)
        if (isShowTransportation && transportation.checked) {
            const transportation_body = {
                id: transportation.id,
                title: transportation.title,
                price: transportation.price,
                sub_service_id: String(subServicesList[0].id)
            }
            combinedPriceData.push(transportation_body)
        }

        // MEDICATION BODY
        const notAllowedServicesMedication = [webConfigJSON.SERVICES_ENUM.GROOMING]
        const isShowMedication = !notAllowedServicesMedication.includes(this.props.selectedService.name)
        if (isShowMedication && medication.checked) {
            const medication_body = {
                id: medication.id,
                title: medication.title,
                price: medication.price,
                sub_service_id: String(subServicesList[0].id)
            }
            combinedPriceData.push(medication_body)
        }
        const reqBody = {
            data: combinedPriceData,
            delete: this.state.deleteComplementary,
        }
        const isFormValidated = this.validateFormData(combinedPriceData)

        const header = { token: this.state.apiToken, "Content-Type": "application/json" };
        const establishmentId = await getStorageData("establishment_ID");

        if (isFormValidated) {
            if (this.state.getPriceDetails.length) {

                const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                const endpoint = `account_block/sub_services_prices/${establishmentId}`
                this.updatePriceDetailsCallId = reqMessage.messageId;
                reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqBody));
                reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
                reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
                runEngine.sendMessage(reqMessage.id, reqMessage);

            } else {
                const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                const endpoint = `account_block/sub_services_prices`
                this.createPriceDetailsCallId = reqMessage.messageId;
                reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqBody));
                reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
                reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
                runEngine.sendMessage(reqMessage.id, reqMessage);
            }
        }
    }
    // Customizable Area End
}

