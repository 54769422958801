// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Views } from "react-big-calendar";

interface IDayList {
    label: string,
    key: string,
}
const DaysList: IDayList[] = [
    {
        label: "Monday",
        key: "Monday",
    },
    {
        label: "Tuesday",
        key: "Tuesday",
    },
    {
        label: "Wednesday",
        key: "Wednesday",
    },
    {
        label: "Thursday",
        key: "Thursday",
    },
    {
        label: "Friday",
        key: "Friday",
    },
    {
        label: "Saturday",
        key: "Saturday",
    },
    {
        label: "Sunday",
        key: "Sunday",
    }
]
type TViewKey = keyof typeof Views
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isEditOn: boolean,
    daysList: IDayList[],
    openAddEvent: boolean,
    // MODAL
    eventName: string,
    eventStartDate: null | Date;
    eventEndDate: null | Date;
    // CALENDAR
    calendarView: (typeof Views)[TViewKey];

    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class ViewEmployeeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            isEditOn: false,
            daysList: DaysList,
            openAddEvent: false,
            // MODAL
            eventName: "",
            eventStartDate: new Date(),
            eventEndDate: null,
            // CALENDAR
            calendarView: "week"
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleEdit = () => {
        this.setState({
            isEditOn: true
        })
    }
    handleCancel = () => {
        this.setState({
            isEditOn: false
        })
    }
    handleOpenAddEvent = () => {
        this.setState({
            openAddEvent: true
        })
    }
    handleClosAddEvent = () => {
        this.setState({
            openAddEvent: false,
            // RESET
            eventName: "",
            eventStartDate: new Date(),
            eventEndDate: null
        })
    }
    setEventName = (value: string) => {
        const filteredValue = value.trimStart()
        this.setState({
            eventName: filteredValue
        })
    }
    setEventDate = (date: Date | null, key: keyof Pick<S, "eventStartDate" | "eventEndDate">) => {
        this.setState({
            [key]: date
        } as Pick<S, "eventStartDate" | "eventEndDate">);
    }
    changeCalendarView = (view: (typeof Views)[TViewKey]) => {
        this.setState({ calendarView: view })
    }

    // Customizable Area End
}

