export const unchecked = require('../assets/uncheckbox.png');
export const checked = require('../assets/checkbox_.png');
export const RupeeIcon = require("../assets/rupee.png");
export const Group = require("../assets/group_.png");
export const smallarrow = require("../assets/smallarrow.png");
export const backbutton = require("../assets/button_1.png");
export const crossButton = require("../assets/crossButton.png");
export const location = require("../assets/image_mingcute_location-fill.png");
export const MarkerSmall = require("../assets/marker_small.svg");
export const Cellout = require("../assets/locationpin.png");
