import React from 'react';
import GenericModal from '../../../../../components/src/GenericModal';
import { Box, CircularProgress, Typography, styled } from '@material-ui/core';
import { AvailablityData, AvailablityItem, ServiceList } from '../../types';


type IProps = {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    serviceType: ServiceList;
    disableOutside: boolean;
    data: AvailablityData;
    isLoading: boolean;
    apiError: string;
    onClickOnAvailablity: (sub_service_id?: string, date?: string, isValid?: boolean) => void;
}

const title: Record<ServiceList, string> = {
    'Day Care': 'Available spaces',
    'Dog Walking': 'Availability',
    'Hotels': 'Available Rooms',
    'Grooming': 'Available spaces',
    'Pet Sitting': 'Available spaces',
    'Training': 'Available spaces'
}

const tableHeader: Record<ServiceList, string>  = {
    'Day Care': 'Space Name',
    'Dog Walking': 'Service',
    'Hotels': 'Room Type',
    'Grooming': 'Service',
    'Pet Sitting': 'Space Name',
    'Training': 'Service'
}

const AvailabilityModal = (props: IProps) => {
    const { isOpen, onClose, serviceType, disableOutside, data, isLoading, apiError, onClickOnAvailablity } = props;

    return(
        <GenericModal
            id='availability' 
            isOpen={isOpen} 
            title={title[serviceType]} 
            onClose={onClose}
            bodyStyle={{ padding: '26px 27px', width: 'auto' }} 
            headerStyle={{ color: '#6F05EA', fontSize: '32px', paddingLeft: '33px'  }}
            disableOutside={disableOutside}
            contentStyle={{ overflowX: 'auto'}}
        >
            <ModalWrapper>
                {isLoading && 
                    <Box className='loader'>
                        <CircularProgress style={{ color: '#6F05EA'}} />
                    </Box> 
                }
                {data.items.length > 0 && 
                <>
                    <Box className="table-row header">
                        <Typography className='header-item'>{tableHeader[serviceType]}</Typography>
                        <Typography className='header-item'>Number of Bookings</Typography>
                        <Typography className='header-item'>Capacity</Typography>
                    </Box>
                    <Box className="table-body">
                        {data.items.map((item) => (
                            <Box className="table-row item" onClick={() => onClickOnAvailablity(`${item.sub_service_id}`, data.date, true)}>
                                <Typography className='row-name'>{item.sub_service_title}</Typography>
                                <Typography className='row-field'>{item.no_of_booking}</Typography>
                                <Typography className='row-field'>{item.capacity}</Typography>
                            </Box>
                        ))}
                    </Box>
                </>
                }
                {apiError && <Typography className='header-item'>{apiError}</Typography>}
            </ModalWrapper>
        </GenericModal>
    )
}

const ModalWrapper = styled('div')({
    maxWidth: '500px',
    minWidth: '500px',
    "& .table-row": {
        display: 'flex', gap: '16px', padding: '2px 6px', borderRadius: '4px',
    },
    "& .item": {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: 'rgba(111, 5, 234, 0.2)'
        }
    },
    "& .header-item": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 500,
        flex: 1,
        width: '100%'
    },
    "& .loader": {
        minWidth: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 0px'
    },
    "& .table-body": {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        paddingBottom: '26px',
    },
    "& .row-name": {
        flex: 1, fontSize: '12px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', color: '#6F05EA'
    },
    "& .row-field": { flex: 1, fontSize: '12px' },
    "& .header": {
        marginBottom: '6px',
        
    }
})


export default AvailabilityModal;