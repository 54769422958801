import React from "react";
// Customizable Area Start
import {
  Box, styled, Typography, Avatar, Divider, CircularProgress, Button, IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BookingCheckoutController, { Props, webConfigJSON } from "./BookingCheckoutController.web";
import moment from "moment";
import { LoadingButton } from "../../../../components/src/HelperComponents";
import { checkCondition, getExpMonth, returnTruthyString } from "../../../../components/src/HelperUtils";
import { Price } from "../../../../components/src/interfaces.web";
import { addCard, Amex, DefaultCard, DefaultSelectedCard, Mastercard, Visa } from "../assets";
import CloseIcon from "@material-ui/icons/Close";
import AddCardStripe from "./AddCardStripe.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6928F2",
      contrastText: "#fff",
    }
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const CardBrandIcon = (brand: string) => {
  switch (brand.toLowerCase()) {
    case 'visa':
      return Visa;
    case 'mastercard':
      return Mastercard;
    case 'amex':
      return Amex;
    default:
      return DefaultCard;
  }
}
// Customizable Area End

export default class BookingCheckout extends BookingCheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderEndDate = () => {
    const { start_date, end_date, service_name, is_repeat_weekly, selected_repeat_days } = this.props.BookingData

    let service_end_date = ""
    if (((service_name === webConfigJSON.SERVICES_ENUM.HOTELS) && end_date)) {
      service_end_date = end_date ? " - " + moment(end_date).format('Do MMM') : ""
    }

    if (is_repeat_weekly && selected_repeat_days.length && end_date && start_date) {
        service_end_date = " - " + moment(end_date).format('Do MMM')
    }
    return service_end_date
  }
  renderBookingDetails = () => {
    const { paymentSuccess } = this.state
    const { establishment_name, pet_details, start_date } = this.props.BookingData
    const pet_img = pet_details?.attributes?.pet_photo
    const pet_name = returnTruthyString(pet_details?.attributes?.name)
    const service_start_date = start_date ? moment(start_date).format('Do MMM') : ""
    const service_end_date = this.renderEndDate()

    return (
      <>
        {paymentSuccess ? this.renderPaymentSuccess() : <></>}
        <DetailsWrapper>
          {paymentSuccess ?
            <BookingFont>Booking Details</BookingFont> :
            <EstablishmentTitle>{establishment_name}</EstablishmentTitle>
          }
          <AvatarBox>
            <PetAvatar src={pet_img}>{pet_name[0]}</PetAvatar>
            <PetTitle>{pet_name}</PetTitle>
          </AvatarBox>
          <ColumnBox>
          {paymentSuccess ? <EstablishSuccess>{establishment_name}</EstablishSuccess>:<></>}
            <SpaceBetweenBox>
              <LeftBox>
                <LabelFont>Reservation date</LabelFont>
              </LeftBox>
              <RightBox>
                <LabelFont>{service_start_date}{service_end_date}</LabelFont>
              </RightBox>
            </SpaceBetweenBox>
            {this.renderServicePrice()}
            {this.renderComplementaries()}
          </ColumnBox>
          <CustomHR />
          {this.renderTotal()}
        </DetailsWrapper>
      </>
    )
  }
  renderServicePrice = () => {
    const { hotel_nights, sub_service } = this.props.BookingData

    const sub_service_price = sub_service?.price
    if (hotel_nights && sub_service) {
      const total_nights = "€ " + sub_service?.price + " x " + hotel_nights + " nights"
      const total_nights_price = (sub_service?.price * hotel_nights)
      return (
        <>
          <SpaceBetweenBox>
            <LeftBox>
              <LabelFont>{total_nights}</LabelFont>
            </LeftBox>
            <RightBox>
              <LabelFont>€ {total_nights_price}</LabelFont>
            </RightBox>
          </SpaceBetweenBox>
        </>
      )
    }
    return (
      <>
        <SpaceBetweenBox>
          <LeftBox>
          </LeftBox>
          <RightBox>
            <LabelFont>€ {sub_service_price}</LabelFont>
          </RightBox>
        </SpaceBetweenBox>
      </>
    )
  }
  renderComplementaries = () => {
    const { show_complementary, medication, transportation, selected_repeat_days } = this.props.BookingData

    const compService = (service: Price | null) => {
      return (
        <SpaceBetweenBox style={{ marginTop: "4px" }}>
          <CompSubTitleFont>{service?.title}</CompSubTitleFont>
          <LabelFont>€ {service?.price}</LabelFont>
        </SpaceBetweenBox>
      )
    }
    return (
      <>
        {show_complementary ?
          <Box>
            <CompHeadingFont>Complementary services</CompHeadingFont>
            {medication ? compService(medication.service) : <></>}
            {transportation ? compService(transportation.service) : <></>}
          </Box> :
          <></>
        }
      </>
    )
  }
  renderTotal = () => {
    const { createBookingResponse } = this.props.BookingData
    const total_price = createBookingResponse?.total_price ? createBookingResponse?.total_price : ""
    const first_scchedule_price = createBookingResponse?.first_schedule_price ? createBookingResponse?.first_schedule_price : ""
    const show_price = first_scchedule_price ? first_scchedule_price : total_price
    return (
      <SpaceBetweenBox>
        <LeftBox><TotalHeading>Total</TotalHeading></LeftBox>
        <RightBox>
          <TotalPrice>€ {show_price}</TotalPrice>
        </RightBox>
      </SpaceBetweenBox>
    )
  }
  renderCards = () => {
    const { paymentFailure } = this.state
    return (
      <>
        <CardSection>
          <SubCardTitle>Select Card</SubCardTitle>
          <SelectCardFont>
            select your payment card
          </SelectCardFont>
          {paymentFailure ? this.renderPaymentFailed() : <></>}
          {this.renderCardList()}
          <ButtonsWrapper>
            <DynamicButton style={style.greyBtn} buttonText="Cancel" onClick={this.props.handleCloseCheckoutPage}
            >
              Cancel
            </DynamicButton>
            <DynamicButton
              style={style.blueBtn}
              buttonText="Pay Now"
              disabled={!this.state.selectedPaymentCard || !this.state.paymentCardsList.length}
              onClick={this.makePaymentCall}
              isLoading={this.state.makePaymentApiLoading}
              data-testid="pay-now-main"
            >
              Pay Now
            </DynamicButton>
          </ButtonsWrapper>
        </CardSection>
      </>
    )
  }
  renderCardList = () => {
    const { paymentCardsListLoading, paymentCardsList, selectedPaymentCard } = this.state
    if (paymentCardsListLoading && !paymentCardsList.length) return <CardLoaderBox><CardLoader /></CardLoaderBox>

    return (
      <CardsWrapper>
        {paymentCardsList.length ?
          <>
            {paymentCardsList.map((paymentCard) => {
              const isDefault = paymentCard.id == selectedPaymentCard?.id
              const cardTitle = `${paymentCard.brand} ending in ${paymentCard.last4}`
              const cardExpiry = `Expiry ${getExpMonth(paymentCard.exp_month)}/${paymentCard.exp_year}`
              const cardBG = checkCondition(isDefault, "#E2CDFB", "#fff") as string
              return (
                <>
                  <CardBox style={{ backgroundColor: cardBG }} key={paymentCard.id} onClick={() => this.setNewCard(paymentCard)} data-testid="card-box-main">
                    <CardImageBox>
                      <img src={CardBrandIcon(paymentCard.brand)} width={"100%"} height={"auto"} />
                    </CardImageBox>

                    <CardFontWrapper>
                      <CardTitleFont>{cardTitle}</CardTitleFont>
                      <CardExpiryFont>{cardExpiry}</CardExpiryFont>
                    </CardFontWrapper>
                    <Box>
                      {isDefault ? <AddCardImage src={DefaultSelectedCard} /> : <></>}
                    </Box>
                  </CardBox>
                </>

              )
            })}
          </> : <></>}
        {this.renderAddCard()}
      </CardsWrapper>
    )
  }
  renderAddCard = () => {
    const { openAddCard, errorAddCard } = this.state
    return (
      <>
        <AddCardBox onClick={this.handleOpenAddCard} data-testid="add-card-box">
          <AddCardImage src={addCard} />
          <AddNewCardFont>Add new card</AddNewCardFont>
        </AddCardBox>
        {openAddCard ?
          <AddCardStripe
            data-testid="add-card-modal"
            navigation={this.props.navigation}
            isOpen={openAddCard}
            onClose={this.handleCloseAddCard}
            onSubmit={this.createPaymentCard}
            submitLoader={this.state.createPaymentCardLoading}
            // DEFAULT CARD
            setAsDefault={this.state.setAsDefault}
            handleChangeDefault={this.handleChangeDefault}
            // ERROR
            error={this.state.errorAddCard}
            clearError={this.clearAddCardError}
          />
          : <></>
        }
      </>
    )
  }

  renderPaymentSuccess = () => {
    return (
      <SuccessMsgWrapper data-testid="success-message-box">
        <SuccessMsgBox>Payment Success</SuccessMsgBox>
        <SubMsgBox>{webConfigJSON.DEFAULT_MSG.PAYMENT_SUCCESS}</SubMsgBox>
      </SuccessMsgWrapper>
    )
  }
  renderPaymentFailed = () => {
    return (
      <FailedMsgWrapper>
        <FailedMsgBox>Payment Failed</FailedMsgBox>
        <FailedSubMsgBox>{webConfigJSON.DEFAULT_MSG.PAYMENT_FAILED}</FailedSubMsgBox>
      </FailedMsgWrapper>
    )
  }
  renderErrorBox = (errorMsg: string) => {
    return (
      <>
        {errorMsg ?
          <ErrorBox>
            {errorMsg}
            <ErrorCloseButton onClick={this.clearAddCardError}>
              <ErrorCloseIcon />
            </ErrorCloseButton>
          </ErrorBox> :
          <></>
        }
      </>
    )
  }
  renderHomePageButton = () => {
    return (
      <>
        <HomeBtnWrapper>
          <HomePageButton onClick={this.goToHomePage} data-testid="home-page-btn">
            Home Page
          </HomePageButton>
        </HomeBtnWrapper>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { paymentSuccess } = this.state
    return (
      <ThemeProvider theme={theme}>
        <MainWrapper>
          {this.renderBookingDetails()}
          {!paymentSuccess ? this.renderCards() : this.renderHomePageButton()}
        </MainWrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  backgroundColor: "#F6F4F9",
  padding: "90px 150px",
  userSelect: "none",
  "@media (max-width: 1600px)": {
    padding: "60px 120px",
  },
  "@media (max-width: 1280px)": {
    padding: "50px 80px",
  },
  "@media (max-width: 960px)": {
    padding: "40px 40px",
  },
  "@media (max-width: 600px)": {
    padding: "32px",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  },
})

const DetailsWrapper = styled(Box)({
  backgroundColor: "#fff",
  padding: "38px 48px",
  "@media (max-width: 960px)": {
    padding: "32px",
  },
  "@media (max-width: 600px)": {
    padding: "24px",
  },
})

const EstablishmentTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "36px",
  fontWeight: 600,
  lineHeight: "48px",
  color: "#000000",
  "@media (max-width: 1600px)": {
    fontSize: "30px",
    lineHeight: "34px",
  },
  "@media (max-width: 960px)": {
    fontSize: "24px",
    lineHeight: "30px",
  },
})
const BookingFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "36px",
  fontWeight: 600,
  lineHeight: "48px",
  color: "#6F05EA",
  "@media (max-width: 1600px)": {
    fontSize: "30px",
    lineHeight: "34px",
  },
  "@media (max-width: 960px)": {
    fontSize: "24px",
    lineHeight: "30px",
  },
})

const AvatarBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "42px 0",
  gap: "3px",
  "@media (max-width: 1600px)": {
    margin: "24px 0 42px 0",
  },
})
const PetAvatar = styled(Avatar)({
  height: "82px",
  width: "82px",
  fontSize: "32px",
  fontWeight: 600,
  fontFamily: "Montserrat",
  border: "0.5px solid #B7B7B7",
  "@media (max-width: 600px)": {
    height: "60px",
    width: "60px",
  },
})
const PetTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "30px",
  fontWeight: 600,
  lineHeight: "38px",
  color: "#000000",
  "@media (max-width: 1600px)": {
    fontSize: "24px",
  },
})

const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "14px",
})
const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "12px",
  alignItems: "center"
})
const LeftBox = styled(Box)({
  padding: 0
})
const RightBox = styled(Box)({
  padding: 0
})

const CustomHR = styled(Divider)({
  margin: "42px 0",
  height: "1px",
  color: "#B7B7B7",
  backgroundColor: "#B7B7B7",
  "@media (max-width: 1600px)": {
    margin: "32px 0",
  },
})
const TotalHeading = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "32px",
  color: "#000000",
  "@media (max-width: 960px)": {
    fontSize: "20px",
  },
})
const TotalPrice = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "36px",
  fontWeight: 700,
  color: "#6F05EA",
  "@media (max-width: 1600px)": {
    fontSize: "30px",
  },
  "@media (max-width: 600px)": {
    fontSize: "24px",
  },
  "@media (max-width: 400px)": {
    fontSize: "20px",
  },
})

const CardSection = styled(Box)({
  backgroundColor: "#fff",
  marginTop: "18px",
  padding: "28px 48px",
  "@media (max-width: 960px)": {
    padding: "32px",
  },
  "@media (max-width: 600px)": {
    padding: "24px",
  },
})

const SubCardTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "32px",
  color: "#6F05EA",
  "@media (max-width: 960px)": {
    fontSize: "20px",
  },
  "@media (max-width: 400px)": {
    fontSize: "18px",
  },
})
const SelectCardFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "22px",
  color: "#000000",
  marginTop: "18px",
  "@media (max-width: 960px)": {
    fontSize: "16px",
    marginTop: "12px",
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
    marginTop: "0px",
  },
})

const CardsWrapper = styled(Box)({
  margin: "40px 0",
  padding: "0 100px",
  display: "flex",
  flexDirection: "column",
  gap: "26px",
  "@media (max-width: 1600px)": {
    padding: "0 60px",
  },
  "@media (max-width: 1280px)": {
    padding: "0px 32px",
  },
  "@media (max-width: 960px)": {
    padding: "0px",
  },
})

const ButtonsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "end",
  gap: "26px"
})

const DynamicButton = styled(LoadingButton)({
  width: "100%",
  maxWidth: "180px",
  height: "56px",
  borderRadius: "31px",
  // FONT
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "24px",
  textTransform: "none",
  "&.Mui-disabled": {
    color: "#B7B7B7 !important",
    border: "1px solid #B7B7B7 !important"
  },
  "@media (max-width: 1600px)": {
    fontSize: "18px",
    height: "48px",
  },
  "@media (max-width: 1280px)": {
    fontSize: "16px",
  },
})

const SuccessMsgWrapper = styled(Box)({
  marginBottom: "40px",
  backgroundColor: "#FFFFFF",
  padding: "45px 72px",
  textAlign: "center",
  "@media (max-width: 1600px)": {
    padding: "32px 64px",
  },
  "@media (max-width: 960px)": {
    padding: "24px",
  },
})
const SuccessMsgBox = styled(Box)({
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "30px",
  color: "#059669",
  "@media (max-width: 1600px)": {
    fontSize: "18px",
  },
})
const SubMsgBox = styled(Box)({
  marginTop: "8px",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "32px",
  color: "#000000"
})
const FailedMsgWrapper = styled(Box)({
  textAlign: "center",
  margin: "20px 0"
})
const FailedMsgBox = styled(Box)({
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "30px",
  color: "#C10000",
  "@media (max-width: 1600px)": {
    fontSize: "18px",
  },
})
const FailedSubMsgBox = styled(Box)({
  marginTop: "8px",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "32px",
  color: "#000000"
})
const CardLoaderBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "32px 0"
})

const CardLoader = styled(CircularProgress)({
  color: "#6F05EA",
  height: 32,
  width: 32
})

const CardBox = styled(Box)({
  cursor: "pointer",
  minHeight: "129px",
  display: "flex",
  alignItems: "center",
  gap: "38px",
  borderRadius: "10px",
  boxShadow: "0px 0px 7.5px 0px #0000001A",
  padding: "0px 38px",
  "@media (max-width: 1280px)": {
    minHeight: "100px",
    gap: "20px",
    padding: "8px 28px",
  },
  "@media (max-width: 600px)": {
    minHeight: "60px",
    gap: "20px",
    padding: "8px 24px",
  },
  "@media (max-width: 400px)": {
    padding: "8px 12px",
    gap: "12px",
  }

})
const CardImageBox = styled(Box)({
  padding: "10px",
  width: 73,
  height: 51,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 1280px)": {
    width: 64,
    height: 64,
  },
  "@media (max-width: 960px)": {
    width: 48,
    height: 48,
  },
  "@media (max-width: 600px)": {
    padding: "0px",
    width: 32,
    height: 32,
  },
})
const CardFontWrapper = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  "@media (max-width: 600px)": {
    gap: "4px",
  },
})
const CardTitleFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#424242",
  userSelect: "none",
  "@media (max-width: 1280px)": {
    fontSize: "18px",
    lineHeight: "22px",
  },
  "@media (max-width: 960px)": {
    fontSize: "16px",
    lineHeight: "20px",
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
    lineHeight: "18px",
  }
})
const CardExpiryFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#0F172A",
  userSelect: "none",
  "@media (max-width: 1280px)": {
    fontSize: "14px",
    lineHeight: "18px",
  },
  "@media (max-width: 600px)": {
    fontSize: "12px",
    lineHeight: "18px",
  }
})
const AddCardBox = styled(Box)({
  cursor: "pointer",
  borderRadius: "10px",
  backgroundColor: "#F7F7F7",
  display: "flex",
  alignItems: "center",
  gap: "60px",
  padding: "43px 60px",
  "@media (max-width: 1280px)": {
    minHeight: "100px",
    padding: "8px 28px",
  },
  "@media (max-width: 600px)": {
    minHeight: "60px",
    gap: "20px",
    padding: "8px 24px",
  },
  "@media (max-width: 400px)": {
    padding: "8px 12px",
    gap: "12px",
  },
})
const AddCardImage = styled(`img`)({
  "@media (max-width: 1280px)": {
    height: "32px",
    width: "32px"
  },
  "@media (max-width: 600px)": {
    height: "24px",
    width: "24px"
  },
  "@media (max-width: 400px)": {
    height: "20px",
    width: "20px"
  }
})
const AddNewCardFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#B7B7B7",
  userSelect: "none",
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
})


const EstablishSuccess = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "22px",
  color: "#000000",
  "@media (max-width: 1600px)": {
    fontSize: "18px",
    lineHeight: "22px",
  },
  "@media (max-width: 960px)": {
    fontSize: "16px",
    lineHeight: "20px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "18px",
  },
})

const LabelFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#000000",
  "@media (max-width: 1600px)": {
    fontSize: "18px",
    lineHeight: "22px",
  },
  "@media (max-width: 960px)": {
    fontSize: "16px",
    lineHeight: "20px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "18px",
  },
})
const CompHeadingFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 600,
  color: "#000000",
  lineHeight: "20px",
  "@media (max-width: 960px)": {
    fontSize: "14px",
  },
  "@media (max-width: 400px)": {
    fontSize: "12px",
  },
})
const CompSubTitleFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  color: "#000000",
  lineHeight: "20px",
  textTransform: "uppercase",
  "@media (max-width: 960px)": {
    fontSize: "14px",
  },
  "@media (max-width: 400px)": {
    fontSize: "12px",
  },
})
const ErrorBox = styled(Box)({
  display: 'flex',
  minHeight: "40px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: '8px 16px',
  borderLeft: '4px solid #DC2626',
  backgroundColor: '#FEE2E2',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 400,
  color: '#DC2626',
  borderRadius: "4px"
})
const ErrorCloseButton = styled(IconButton)({
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
})
const ErrorCloseIcon = styled(CloseIcon)({
  height: 16,
  width: 16,
  color: "#DC2626"
})
const HomeBtnWrapper = styled(Box)({
  padding: "32px",
  "@media (max-width: 1280px)": {
    padding: "24px",
  },
  "@media (max-width: 600px)": {
    padding: "24px 0",
  },
})
const HomePageButton = styled(Button)({
  width: "100%",
  height: "56px",
  borderRadius: "31px",
  border: "1px solid #6F05EA",
  color: "#6F05EA",
  // FONT
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "24px",
  textTransform: "none",
  "@media (max-width: 1600px)": {
    fontSize: "18px",
    height: "48px",
  },
  "@media (max-width: 1280px)": {
    fontSize: "16px",
  },
})
const style = {
  blueBtn: {
    border: "1px solid #6F05EA",
    color: "#6F05EA"
  } as React.CSSProperties,
  greyBtn: {
    border: "1px solid #4B4B4B",
    color: "#4B4B4B"
  } as React.CSSProperties,
  smallCardFont: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    color: "#424242",
    userSelect: "none"
  } as React.CSSProperties,
  smallCardExpiryFont: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    color: "#424242",
    userSelect: "none"
  } as React.CSSProperties,
}
// Customizable Area End
