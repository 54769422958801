import {
    FormControl,
    MenuItem,
    Select,
    styled,
  } from "@material-ui/core";
  import React, { useState } from "react";
  import { DropdownIcon } from "./assets/assets";
  
  const RotateIcon: React.FC<{ open: boolean }> = ({ open }) => {
    return (
      <img
        src={DropdownIcon}
        style={{
          transition: "transform 0.2s ease",
          transform: open ? "rotate(0deg)" : "rotate(180deg)",
        }}
      />
    );
  };
  
  const CustomMenuItem = styled(MenuItem)({
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000",
  
    "&.Mui-selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#F3EBFF",
    },
  });
  
  const CustomSelect = styled(Select)({
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    color: "#B7B7B7",
    width: "193px",
    height: "44px",
    overflow: "hidden",
    paddingLeft: 0,

    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent !important'
    },

    "& .MuiSelect-select": {
        backgroundColor: "transparent",
        paddingRight: "10px",
        width: '150px',
        border: 'none',
        height: '44px',
        padding: '12px'
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        borderColor: 'transparent',
        boxShadow: 'none'
    },
    "& .Mui-focused .MuiSelect-select": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiList-padding": {
      padding: 0,
    },
  });

  type Props<T> = {
    value: T;
    list: {
        label: string,
        value: string
    }[];
    onChange: (value: T) => void;
    paperProps: object;
  }
  
  const GeneralSelect = <T,>({ value, list, onChange, paperProps }: Props<T>) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value) {
            onChange(event.target.value as T);
        }
      };
  
    return (
      <FormControl variant="outlined" fullWidth>
        <CustomSelect
          data-test-id="general-select"
          labelId="select-label"
          value={value}
          onChange={handleChange}
          displayEmpty
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          IconComponent={() => <RotateIcon open={isOpen} />}
          MenuProps={{
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: 200,
                overflowY: "auto",
                ...paperProps
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
            {list.map((item, idx) => (
                <CustomMenuItem key={item.value} value={item.value}>
                {item.label}
                </CustomMenuItem>
            ))}
        </CustomSelect>
      </FormControl>
    );
  };
  
  export default GeneralSelect;