import moment from "moment";
import { CalendarEventTypeOne, CalendarItem, VendorEvent, VendorEventService } from "../../../blocks/customisableuserprofiles/src/types";

type EventsData = {
  services: Record<string, VendorEvent[]>;
  count: number;
}

type CalendarDataEvents = {
  services: Record<string, CalendarItem[]>;
}

export const createCalendarEventsData = (eventsData: CalendarDataEvents): CalendarEventTypeOne[] => {
  const events: CalendarEventTypeOne[] = [];
  const services = Object.keys(eventsData.services);

  for(let hour = 0; hour < services.length; hour++) {
    const serviceKey = services[hour];
    const eventsList = eventsData.services[serviceKey];
    eventsList.forEach((service) => {
      const start = moment(service.date, 'YYYY-MM-DD').startOf('day').add(hour, 'hours'); 
      const end = start.clone().add(1, 'hours'); 
      const event: CalendarEventTypeOne = {
        start: start.toDate(),
        end: end.toDate(),
        ss_capacity: service.ss_capacity,
        ss_schedules_count: service.ss_schedules_count,
        sub_service_price: service.sub_service_price,
        title: 'Test',
        sub_service_id: serviceKey
      };
      events.push(event);
    })
  }

  return events;
}

// export const createHourlyIntervals = (calendarDate: Date, eventsData: EventsData): VendorEventService[] => {
//     const givenDate = moment(calendarDate);
//     const startOfWeek = givenDate.clone().startOf('isoWeek'); // Start from Monday
//     const endOfWeek = givenDate.clone().endOf('isoWeek'); // End on Sunday
//     const keys = Object.keys(eventsData.services);
  
//     const intervals = [];
  
//     // Loop through each day of the week from Monday to Friday
//     for (let day = startOfWeek.clone(); day.isBefore(endOfWeek.clone().add(1, 'days')); day.add(1, 'days')) {
//       // Only create intervals for Monday to Friday
//       if (day.day() === 0 || day.day() === 6) {
//         continue; // Skip Sunday (0) and Saturday (6)
//       }
  
//       // Create hourly intervals for the current day
//       for (let hour = 0; hour < eventsData.count; hour++) {
//         const start = day.clone().startOf('day').add(hour, 'hours'); // Start time
//         const end = start.clone().add(1, 'hours'); // End time (1 hour later)

//         const interval = {
//           start: start.toDate(),
//           end: end.toDate(),
//           service_type: keys[hour],
//           title: `Event ${keys[hour]}`,
//           count: 0,
//         }

//         eventsData.services[keys[hour]].forEach(event => {
//           if(day.startOf('day').isBetween(moment(event.start).startOf('day'), moment(event.end).endOf('day'), null, '[]')) {
//             interval.count += 1;
//           }
//         })
  
//         intervals.push(interval);
//       }
//     }
  
//     return intervals;
//   };