import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from "react";
import { getDeltaValues } from "../../../components/src/helpers";

export type SearchItem = {
  id: number,
  establishment_name: string,
  address: string,
  city: string,
  country: string,
  zipcode: string,
  longitude: number,
  latitude: number,
  phone_number: number,
  email: string,
  facebook_url: string,
  instagram_url: string,
  tiktok_url: string,
  activated: boolean,
  linkedin_url: string,
  created_at: string,
  updated_at: string,
  service_price: number,
  establishment_photo: string | null,
  galleries_urls: string[],
  vandor_name: string,
  service_details: {
    "id": number | string,
    "service_type": string
  }
};

const mockItems = {
  data: [
    {
      id: "1",
      title: "VillaDog - Hotel Canino",
      image: "https://picsum.photos/1600/900",
      address: "R. Vieira Pinto 433, 4430-255 Vila Nova de Gaia",
      rooms: 2,
      price: 160,
      noTaxPrice: 120,
      latitude: 41.132441,
      longitude: -8.59561,
    },
    {
      id: "2",
      title: "Pet Hotel Gaia",
      image: "https://picsum.photos/1600/900",
      address:
        "R. Azevedo Magalhães 161 165, 4430-024 Vila Nova de Gaia, Portugal",
      rooms: 1,
      price: 200,
      noTaxPrice: 180,
      latitude: 41.1330534,
      longitude: -8.6008566,
    },
    {
      id: "3",
      title: "The Castle Dog Hotel",
      image: "https://picsum.photos/1600/900",
      address:
        "4431-952, R. Azevedo Magalhães 314, 4431-952 Vila Nova de Gaia, Portugal",
      rooms: 10,
      price: 540,
      noTaxPrice: 475,
      latitude: 41.1330534,
      longitude: -8.6008566,
    },
  ],
};

export type Filters = {
  price: {
    max: string;
    min: string;
  };
  transport: boolean;
  commodities: string[];
  hotelType: string[];
  grooming: string[];
  dayCare: string[];
  training: string[];
};
type Region = {
  latitude: number;
  longitude: number;
  latitudeDelta?: number;
  longitudeDelta?: number;
};
export interface  SearchResultItem{
  id: number,
  establishment_name: string,
  address: string,
  country: string,
  city: string,
  longitude: number,
  latitude: number,
  email: string,
  phone_number: number ,
  facebook_url: string,
  instagram_url: string,
  linkedin_url: string,
  tiktok_url: string,
  activated: boolean,
  zipcode: string,
  created_at: string,
  updated_at: string,
  service_price: number ,
  establishment_photo: string | null,
  galleries_urls: string[],
  vandor_name: string,
  service_details: {
    "id": number | string,
    "service_type": string
  }
};
const defaultsearchItem=[{
  id: 0,
  establishment_name: '',
  address: '',
  country:'',
  city: '',
  zipcode:'',
  longitude:0,
  latitude:0,
  email: '',
  phone_number: 0,
  facebook_url: '',
  instagram_url: '',
  linkedin_url: '',
  tiktok_url: '',
  activated: false,
  created_at: '',
  updated_at: '',
  vandor_name:'',
  service_price: 0,
  establishment_photo: '',
  galleries_urls:[],
  service_details: {
    "id":0,
    "service_type": ''
  }
}]
const defaultsMock=[{
  id: 0,
  establishment_name: '',
  address: '',
  country:'',
  latitude:null,
  city: '',
  zipcode:'',
  longitude:null,
  email: '',
  facebook_url: '',
  instagram_url: '',
  linkedin_url: '',
  tiktok_url: '',
  activated: false,
  phone_number: null,
  created_at: '',
  updated_at: '',
  vandor_name:'',
  service_price: null,
  establishment_photo: '',
}]
import {Group} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  searchItems: SearchItem[];
  onSearch:(item:SearchItem)=>void;
    center: {
      lat: number;
      lng: number;
    };
    markers:Array<SearchItem>;
    open?:boolean
    handleClose?:()=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  minPrice: any,
  maxPrice: any,
  daily:boolean,
  pool:boolean,
  garden:boolean;
  enableModal:boolean;
  filters: Filters;
  searchItems:SearchItem[];
  region: Region;
  searchTab: number;
  selectedMarker: any,

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetSearchResults: string = "";
  results: SearchItem[] = [];
  mapRef: React.RefObject<google.maps.Map>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];
    this.results = props.searchItems;
    this.state = {
      minPrice: '',
      maxPrice: '',
      daily:false,
  pool:false,
  garden:false,
  enableModal:false,
  filters: {
    price: {
      max: "",
      min: "",
    },
    transport: false,
    commodities: [],
    hotelType: [],
    grooming: [],
    dayCare: [],
    training: [],
  },
  searchItems: defaultsearchItem,
  region: {
    latitude: 38.719567,
    longitude: -9.139996,
    latitudeDelta: getDeltaValues(50, 38.719567).latDelta,
    longitudeDelta: getDeltaValues(50, 38.719567).longDelta,
  },
  searchTab: 0,
  selectedMarker: null,
    };
    this.mapRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if(this.props.open){
      this.setState({enableModal:true})
    }
   if(this.props.searchItems){
    this.setState({searchItems:this.props.searchItems})
   }
  }

  handleClose(){
    if(this.props.handleClose){
      this.props.handleClose()
    this.setState({enableModal:!this.state.enableModal})
    }
  }
  changeFilterValue = (type: keyof Filters, field: string, value?: string) => {
    if (type === "price") {
      this.setState({
        filters: {
          ...this.state.filters,
          price: {
            ...this.state.filters.price,
            [field]: value,
          },
        },
      });
    } else if (type === "transport") {
      this.setState({
        filters: {
          ...this.state.filters,
          transport: !this.state.filters.transport,
        },
      });
    } else {
      this.setState((prevState) => {
        return {
          filters: {
            ...prevState.filters,
            [type]: prevState.filters[type].includes(field)
              ? prevState.filters[type].filter((i) => i !== field)
              : [...prevState.filters[type], field],
          },
        };
      });
    }
  };

  // Customizable Area End
}
