import React from 'react';
import { Request } from '../CustomisableUserProfilesController';
import { Box, Typography, styled } from '@material-ui/core';
import { getRequestData } from '../../../../components/src/helpers';

type Props = {
    request: Request | null;
}

const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    paddingLeft: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',

    "& .header-title": {
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: "Montserrat",
        [theme.breakpoints.up("sm")]: {
            fontSize: '18px',
          },
    },

    "& .sub-header": {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: "Montserrat"
    }

}))

const RequestStatus = ({ request }: Props) => {
    if(!request) {
        return null;
    }

    const requestData = getRequestData(request);

    if(!requestData.title) {
        return null;
    }

    return (
        <Wrapper>
            <Typography style={{ color: requestData.color }} className='header-title'>
                {requestData.title}
            </Typography>
            {!!requestData.start && <Typography style={{ color: requestData.color }} className='sub-header'>{requestData.start}</Typography>}
            {!!requestData.end && <Typography style={{ color: requestData.color }} className='sub-header'>{requestData.end}</Typography>}
        </Wrapper>
    )
}

export default RequestStatus;