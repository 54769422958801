import moment from "moment";
import { Request } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";

type ReqData = {
    title: string,
    color: string,
    start?: string,
    end?: string
}

export const getRequestData = (request: Request): ReqData => {
    const data: ReqData = { title: '', color: '#D4AD2D' };
    const { created_by, status, request_type, end_date, start_date } = request;

    if(created_by === 'customer') {
        if(status === 'pending') {
            switch (request_type) {
                case 'Cancellation Request':
                    data.title = 'Cancellation request sent'
                    break;
                case 'Transportation Request':
                    data.title = 'Transportation request sent'
                    break;
                case 'Reschedule Request':
                    data.title = 'Change request sent';
                    if(start_date) data.start = `Start Date: ${moment(start_date).format('DD/MM/YYYY')}`;
                    if(end_date) data.end = `End Date: ${moment(end_date).format('DD/MM/YYYY')}`;
                default:
                    break;
            }
        }
    } else {
        if(request_type === 'Cancellation Request') {
            data.title = 'This appointment has been deleted by the vendor';
            data.color = '#C10000'
        }
    }

    return data;
}