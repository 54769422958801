import { Button, styled } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
const MarkerIcon = require("./assets/marker-icon.svg");

const MapWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "375px",
  borderRadius: "12px",
  [theme.breakpoints.down(960)]: {
    // width: "600px",
  },
  [theme.breakpoints.down(700)]: {
    // width: "500px",
  },
  [theme.breakpoints.down(600)]: {
    // width: "380px",
    height: "325px",
  },
  [theme.breakpoints.down(450)]: {
    // width: "280px",
    height: "300px",
  },
}));

type Props = {
  center: {
    lat: number;
    lng: number;
  };
  markers: {
    position: {
      lat: number;
      lng: number;
    };
  };
};

const BookingWebMap = ({ center, markers }: Props) => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 12,
        disableDefaultUI: true,
        zoomControl: false,
      });

      new window.google.maps.Marker({
        position: markers.position,
        map,
        icon: MarkerIcon,
      });
    }
  }, []);

  return center && markers ? <MapWrapper ref={mapRef}></MapWrapper> :<></>;
};

export default BookingWebMap;
