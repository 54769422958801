// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IAnimal, IFilteredService, IGeneralDetails } from "../../../../components/src/interfaces.web";
import { getAnimalListGeneral, isValidString, isValidTimeRange, returnTruthyString, validateDayTimeRange } from "../../../../components/src/HelperUtils";
import { getStorageData } from "../../../../framework/src/Utilities";

type DayKey = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday"
interface IForm {
    bio_about: string
    cancellation_policy: string
    accepted_animals: IFormAcceptedAnimals
    schedule_information: ScheduleInformation
    lunch_break: IFormLunchBreak
}
interface IFormAcceptedAnimals {
    dog: boolean
    cats: boolean
    fish: boolean
    bird: boolean
    rabbit: boolean
    reptile: boolean
    rodents: boolean
}

interface ScheduleInformation {
    Monday: IDay
    Tuesday: IDay
    Wednesday: IDay
    Thursday: IDay
    Friday: IDay
    Saturday: IDay
    Sunday: IDay
}
interface IDay {
    start_time: string
    end_time: string
}
interface IFormLunchBreak {
    start_time: string
    end_time: string
}

const initialValues = {
    bio_about: "",
    cancellation_policy: "",
    accepted_animals: {
        "dog": false,
        "cats": false,
        "fish": false,
        "bird": false,
        "rabbit": false,
        "reptile": false,
        "rodents": false
    },
    schedule_information: {
        Monday: {
            start_time: "",
            end_time: ""
        },
        Tuesday: {
            start_time: "",
            end_time: ""
        },
        Wednesday: {
            start_time: "",
            end_time: ""
        },
        Thursday: {
            start_time: "",
            end_time: "",
        },
        Friday: {
            start_time: "",
            end_time: ""
        },
        Saturday: {
            start_time: "",
            end_time: ""
        },
        Sunday: {
            start_time: "",
            end_time: ""
        }
    },
    lunch_break: {
        start_time: "",
        end_time: ""
    }
}
const initialDaysCheckbox = {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false
}
const initialFormErrors = {
    bio_about: "",
    cancellation_policy: "",
    accepted_animals: "",
    schedule_information: {
        main: "",
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: "",
        Saturday: "",
        Sunday: ""
    },
    lunch_break: ""
}
interface IGeneralDetailsResp {
    data: IGeneralDetails
    meta?: { message: string }
    errors?: string[]
}
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    selectedService: IFilteredService
    getServicesList: (id: string) => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    apiToken: string,
    isEditOn: boolean;
    animalList: IAnimal[];
    form: IForm;
    daysCheckbox: Record<DayKey, boolean>
    selectAllCheckbox: boolean;
    formErrors: {
        bio_about: string,
        cancellation_policy: string,
        accepted_animals: string,
        schedule_information: {
            main: string,
            Monday: string,
            Tuesday: string,
            Wednesday: string,
            Thursday: string,
            Friday: string,
            Saturday: string,
            Sunday: string
        },
        lunch_break: string
    },
    // SAVED
    savedForm: IForm;
    savedDaysCheckbox: Record<DayKey, boolean>
    savedSelectAllCheckbox: boolean;

    // API
    generalDetails: IGeneralDetails | null,
    generalDetailsLoading: boolean,
    createUpdateLoading: boolean,

    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class GeneralController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getGeneralDetailsCallId: string = "";
    createGeneralDetailsCallId: string = "";
    updateGeneralDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            apiToken: localStorage.getItem("login_token") || "",
            isEditOn: false,
            animalList: [],
            // FORM 
            form: initialValues,
            daysCheckbox: initialDaysCheckbox,
            selectAllCheckbox: false,
            // SAVED FORM
            savedForm: initialValues,
            savedDaysCheckbox: initialDaysCheckbox,
            savedSelectAllCheckbox: false,
            formErrors: initialFormErrors,
            // API
            generalDetails: null,
            generalDetailsLoading: false,
            createUpdateLoading: false,

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.getGeneralDetailsCallId:
                        this.getGeneralDetailsResp(responseJson);
                        break;
                    case this.createGeneralDetailsCallId:
                        this.createGeneralDetailsResp(responseJson);
                        break;
                    case this.updateGeneralDetailsCallId:
                        this.updateGeneralDetailsResp(responseJson);
                        break;
                    default:
                        break;
                }
            }

        }
        // Customizable Area End
    }
    // Customizable Area Start

    getGeneralDetailsResp = (responseJson: IGeneralDetailsResp) => {
        this.setState({
            generalDetailsLoading: false
        })
        if (responseJson && responseJson?.data && !responseJson.errors) {
            this.setState({
                generalDetails: responseJson.data
            })
            this.reinitializeFormValues(responseJson?.data)
        } else {
            this.setState({
                generalDetails: null,
                form: initialValues,
                daysCheckbox: initialDaysCheckbox,
                selectAllCheckbox: false,
                savedForm: initialValues,
                savedDaysCheckbox: initialDaysCheckbox,
                savedSelectAllCheckbox: false,
            })
        }
    }
    createGeneralDetailsResp = (responseJson: IGeneralDetailsResp) => {
        const establishmentId = localStorage.getItem("establishment_ID") || ""
        this.setState({
            createUpdateLoading: false
        })
        if (responseJson && responseJson?.data && !responseJson.errors) {
            this.setState({
                isEditOn: false
            })
            this.getGeneralDetails()
            this.props.getServicesList(establishmentId);
        }

    }
    updateGeneralDetailsResp = (responseJson: IGeneralDetailsResp) => {
        this.setState({
            createUpdateLoading: false
        })
        if (responseJson && responseJson?.data && !responseJson.errors) {
            this.setState({
                isEditOn: false
            })
            this.getGeneralDetails()
        }

    }
    async componentDidMount() {
        this.setAnimalList()
        this.getAndSetData()
    }

    getGeneralDetails = async () => {
        const establishmentId = await getStorageData("establishment_ID");
        this.setState({
            generalDetailsLoading: true
        })
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        const header = { token: this.state.apiToken };

        const endpoint = `${webConfigJSON.ENDPOINTS.GET_GENERAL_DETAILS}?service[establishment_id]=${establishmentId}&service[service_type]=${this.props.selectedService.name}`
        this.getGeneralDetailsCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(reqMessage.id, reqMessage);
    };

    getAndSetData = () => {
        if (this.props.selectedService.service_id) {
            this.getGeneralDetails()
        } else {
            // RESET STATES
            this.setState({
                generalDetails: null,
                form: initialValues,
                daysCheckbox: initialDaysCheckbox,
                selectAllCheckbox: false,
                savedForm: initialValues,
                savedDaysCheckbox: initialDaysCheckbox,
                savedSelectAllCheckbox: false,
            })
        }
    }

    reinitializeFormValues = (responseJson: IGeneralDetails) => {
        const { dog, cats, fish, bird, rabbit, reptile, rodents, schedule_informations } = responseJson.attributes

        const accepted_animals_value = {
            dog: Boolean(dog),
            cats: Boolean(cats),
            fish: Boolean(fish),
            bird: Boolean(bird),
            rabbit: Boolean(rabbit),
            reptile: Boolean(reptile),
            rodents: Boolean(rodents),
        }

        const findObj = (key: string) => schedule_informations.find((record) => record.day == key)

        const schedule_value = {
            Monday: {
                start_time: returnTruthyString(findObj("Monday")?.start_time),
                end_time: returnTruthyString(findObj("Monday")?.end_time)
            },
            Tuesday: {
                start_time: returnTruthyString(findObj("Tuesday")?.start_time),
                end_time: returnTruthyString(findObj("Tuesday")?.end_time)
            },
            Wednesday: {
                start_time: returnTruthyString(findObj("Wednesday")?.start_time),
                end_time: returnTruthyString(findObj("Wednesday")?.end_time)
            },
            Thursday: {
                start_time: returnTruthyString(findObj("Thursday")?.start_time),
                end_time: returnTruthyString(findObj("Thursday")?.end_time)
            },
            Friday: {
                start_time: returnTruthyString(findObj("Friday")?.start_time),
                end_time: returnTruthyString(findObj("Friday")?.end_time)
            },
            Saturday: {
                start_time: returnTruthyString(findObj("Saturday")?.start_time),
                end_time: returnTruthyString(findObj("Saturday")?.end_time)
            },
            Sunday: {
                start_time: returnTruthyString(findObj("Sunday")?.start_time),
                end_time: returnTruthyString(findObj("Sunday")?.end_time)
            }
        }
        const days_checkbox_value = {
            Monday: Boolean(findObj("Monday")),
            Tuesday: Boolean(findObj("Tuesday")),
            Wednesday: Boolean(findObj("Wednesday")),
            Thursday: Boolean(findObj("Thursday")),
            Friday: Boolean(findObj("Friday")),
            Saturday: Boolean(findObj("Saturday")),
            Sunday: Boolean(findObj("Sunday"))
        }

        const lunchBreakObj = findObj("Lunch_Break")
        const lunch_break_value = {
            start_time: returnTruthyString(lunchBreakObj?.start_time),
            end_time: returnTruthyString(lunchBreakObj?.end_time)
        }
        this.setState({
            form: {
                bio_about: returnTruthyString(responseJson.attributes.about),
                cancellation_policy: returnTruthyString(responseJson.attributes.cancellation_policy),
                accepted_animals: accepted_animals_value,
                schedule_information: schedule_value,
                lunch_break: lunch_break_value,
            },
            daysCheckbox: days_checkbox_value,
            selectAllCheckbox: Object.values(days_checkbox_value).every(Boolean),
            // SET SAVED
            savedForm: {
                bio_about: returnTruthyString(responseJson.attributes.about),
                cancellation_policy: returnTruthyString(responseJson.attributes.cancellation_policy),
                accepted_animals: accepted_animals_value,
                schedule_information: schedule_value,
                lunch_break: lunch_break_value,
            },
            savedDaysCheckbox: days_checkbox_value,
            savedSelectAllCheckbox: Object.values(days_checkbox_value).every(Boolean),
        })
    }

    SetFormValue = (value: string, fieldName: string) => {
        const updatedValue = value.trimStart()
        this.setState({
            form: {
                ...this.state.form,
                [fieldName]: updatedValue
            }
        })
    }
    SetSelectAllCheckbox = (checked: boolean) => {
        if (checked) {
            const daysCheckboxCopy = JSON.parse(JSON.stringify(this.state.daysCheckbox));
            Object.keys(daysCheckboxCopy).forEach(dayName => {
                daysCheckboxCopy[dayName] = checked;
            });
            this.setState({
                daysCheckbox: daysCheckboxCopy,
                selectAllCheckbox: checked,
            })
        }
    };

    SetScheduleDayCheckbox = (checkedValue: boolean, Day: DayKey) => {
        const updatedDaysCheckbox = {
            ...this.state.daysCheckbox,
            [Day]: checkedValue
        };
        // check is all days are selected
        const allSelected = Object.values(updatedDaysCheckbox).every(Boolean);
        this.setState({
            daysCheckbox: updatedDaysCheckbox,
            selectAllCheckbox: allSelected
        });
    };

    SetScheduleTime = (value: string, DayName: DayKey, time_type: "start_time" | "end_time") => {
        const start_time_value = time_type == "start_time" ? value : this.state.form.schedule_information[DayName].start_time
        const end_time_value = time_type == "end_time" ? value : ""
        this.setState({
            form: {
                ...this.state.form,
                schedule_information: {
                    ...this.state.form.schedule_information,
                    [DayName]: {
                        ...this.state.form.schedule_information[DayName],
                        start_time: start_time_value,
                        end_time: end_time_value
                    }
                }
            }
        })
    }
    SetAnimalsCheckbox = (checked: boolean, animalName: string) => {
        const newValues = {
            ...this.state.form.accepted_animals,
            [animalName]: checked
        }

        this.setState({
            form: {
                ...this.state.form,
                accepted_animals: newValues
            }
        })
    };
    SetLunchBreak = (value: string, time_type: "start_time" | "end_time") => {
        const start_time_value = time_type == "start_time" ? value : this.state.form.lunch_break.start_time
        const end_time_value = time_type == "end_time" ? value : ""
        this.setState({
            form: {
                ...this.state.form,
                lunch_break: {
                    start_time: start_time_value,
                    end_time: end_time_value
                }
            }
        })

    }

    setAnimalList = () => {
        this.setState({
            animalList: getAnimalListGeneral(this.props.selectedService.name)
        })
    }
    handleCancelClick = () => {
        this.setState({
            isEditOn: false,
            form: this.state.savedForm,
            daysCheckbox: this.state.savedDaysCheckbox,
            selectAllCheckbox: this.state.savedSelectAllCheckbox,
            formErrors: initialFormErrors
        })
    }
    handleEditClick = () => {
        this.setState({
            isEditOn: true
        })
    }

    getLunchBreakBody = (schedule_body: ScheduleInformation) => {
        const { lunch_break } = this.state.form

        const servicesNames = [webConfigJSON.SERVICES_ENUM.TRAINING, webConfigJSON.SERVICES_ENUM.GROOMING]
        const isLunchIncludes = servicesNames.includes(this.props.selectedService.name)
        if (isLunchIncludes && lunch_break.start_time && lunch_break.end_time) {
            const lunch_break_body = {
                ...schedule_body,
                "Lunch_Break": lunch_break
            }
            return lunch_break_body
        }
        return schedule_body

    }
    getFormBody = () => {
        const establishmentId = localStorage.getItem("establishment_ID") || ""
        const { bio_about, cancellation_policy, schedule_information, accepted_animals } = this.state.form
        const { daysCheckbox } = this.state

        // SCHEDULE BODY
        const schedule_body_checked = Object.keys(schedule_information).reduce((result, day) => {
            const { start_time, end_time } = schedule_information[day as DayKey];
            if (daysCheckbox[day as DayKey]) {
                result[day as DayKey] = { start_time, end_time };
            }
            return result;
        }, {} as typeof schedule_information);
        const schedule_body = JSON.parse(JSON.stringify(schedule_body_checked))

        // LUNCH BREAK BODY
        const schedule_lunch_body = this.getLunchBreakBody(schedule_body)
        let formBody = {
            "service_type": this.props.selectedService.name,
            "establishment_id": establishmentId,
            "about": bio_about,
            "cancellation_policy": cancellation_policy,
            "schedule_information": {
                "days": schedule_lunch_body
            },
        }
        // ACCEPTED ANIMALS BODY
        const includesAcceptedAnimals = this.props.selectedService.name !== webConfigJSON.SERVICES_ENUM.DOG_WALKING
        if (includesAcceptedAnimals) {
            formBody = {
                ...formBody,
                ...accepted_animals
            }
        } else {
            formBody = {
                ...formBody,
                ...{ dog: true }
            }
        }

        return formBody
    }
    validateForm = () => {
        const { bio_about, cancellation_policy, schedule_information, accepted_animals, lunch_break, } = this.state.form
        const { daysCheckbox } = this.state

        const bio_error = isValidString(bio_about) ? "" : "*Please enter Bio/About"
        const cancellation_error = isValidString(cancellation_policy) ? "" : "*Please enter Cancellation Policy"
        const animal_error_default = Object.values(accepted_animals).some(value => value === true) ? "" : "*Please select at least One"

        const isShowAnimal = this.props.selectedService.name !== webConfigJSON.SERVICES_ENUM.DOG_WALKING
        const animal_error = isShowAnimal ? animal_error_default : ""

        // VALIDATE SCHEDULE FORM
        const atLeastOne = Object.values(daysCheckbox).some(value => value === true) ? "" : "*Please select at least One"
        const Monday = validateDayTimeRange(schedule_information.Monday.start_time, schedule_information.Monday.end_time, daysCheckbox.Monday)
        const Tuesday = validateDayTimeRange(schedule_information.Tuesday.start_time, schedule_information.Tuesday.end_time, daysCheckbox.Tuesday)
        const Wednesday = validateDayTimeRange(schedule_information.Wednesday.start_time, schedule_information.Wednesday.end_time, daysCheckbox.Wednesday)
        const Thursday = validateDayTimeRange(schedule_information.Thursday.start_time, schedule_information.Thursday.end_time, daysCheckbox.Thursday)
        const Friday = validateDayTimeRange(schedule_information.Friday.start_time, schedule_information.Friday.end_time, daysCheckbox.Friday)
        const Saturday = validateDayTimeRange(schedule_information.Saturday.start_time, schedule_information.Saturday.end_time, daysCheckbox.Saturday)
        const Sunday = validateDayTimeRange(schedule_information.Sunday.start_time, schedule_information.Sunday.end_time, daysCheckbox.Sunday)

        // VALIDATE LUNCH BREAK FORM
        const servicesNames = [webConfigJSON.SERVICES_ENUM.TRAINING, webConfigJSON.SERVICES_ENUM.GROOMING]
        const isLunchIncludes = servicesNames.includes(this.props.selectedService.name)
        const lunch_error = isLunchIncludes ? isValidTimeRange(lunch_break.start_time, lunch_break.end_time) : ""

        const newFormErrors = {
            bio_about: bio_error,
            cancellation_policy: cancellation_error,
            accepted_animals: animal_error,
            schedule_information: {
                main: atLeastOne,
                Monday,
                Tuesday,
                Wednesday,
                Thursday,
                Friday,
                Saturday,
                Sunday
            },
            lunch_break: lunch_error
        }
        const is_schedule_validated = Object.values(newFormErrors.schedule_information).every(value => value == "")
        this.setState({
            formErrors: newFormErrors
        })

        if (!Boolean(bio_error) && !Boolean(cancellation_error) && !Boolean(animal_error) && !Boolean(lunch_error) && is_schedule_validated) {
            return true
        }
        return false
    }

    handleSaveClick = () => {
        const bodyData = this.getFormBody()
        const header = {
            "token": this.state.apiToken,
            "Content-Type": "application/json"
        };
        const reqBodyData = {
            "service": bodyData
        }
        const isValidated = this.validateForm()
        if (isValidated) {

            if (this.props.selectedService.service_id) {
                const service_id = this.props.selectedService.service_id
                // CALL PATCH API
                this.setState({
                    createUpdateLoading: true
                })
                const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

                const endpoint = `${webConfigJSON.ENDPOINTS.CREATE_GENERAL_DETAILS}/${service_id}`
                this.updateGeneralDetailsCallId = reqMessage.messageId;
                reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqBodyData));
                reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
                reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
                runEngine.sendMessage(reqMessage.id, reqMessage);

            } else {
                // CALL POST API
                this.setState({
                    createUpdateLoading: true
                })
                const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

                const endpoint = `${webConfigJSON.ENDPOINTS.CREATE_GENERAL_DETAILS}`
                this.createGeneralDetailsCallId = reqMessage.messageId;
                reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqBodyData));
                reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
                reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
                runEngine.sendMessage(reqMessage.id, reqMessage);
            }
        }
    }
    // Customizable Area End
}

