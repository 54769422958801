Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start




exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";
exports.loginApiEndPoint = "bx_block_login/logins";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

// Web vars
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.validationSubjectEmpty = "*Please enter your subject.";
exports.validationDetailsEmpty = "*Please enter your details.";
exports.validationFileSize = "*Your attachments is more than 5MB";
exports.validationFileType = '*Unsupported Format, must be PNG or JPG';

exports.tipMessage = "*Please do not upload files larger than 5 MB";
exports.btnSend = 'Send';
exports.pageTitle = "Contact Us";
exports.labelSubject = "Subject*";
exports.labelDetails = "Details*";
exports.labelAttachFiles = "Add Attachments";

exports.modalTitle = "Verify Email";
exports.modalDescription = "We received your form, will look into that and come back to you as soon as possible.";
exports.modalDoneBtn = "Done";

exports.uploadBoxText = "upload file";
exports.uploadBoxExt = "(png, jpg)"; 

exports.contactUsSubmitApiEndpoint = 'bx_block_contact_us/contacts';
exports.subjectPlaceHolder = "Subject for your enquiry"
exports.detailsPlaceHolder = "Please enter details"
// Customizable Area End