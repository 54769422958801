export const DiscountBanner = require("../assets/discount-banner.png");
export const LandingPageFooterImg = require("../assets/ad_card_1_.png");
export const NextArrowIcon = require("../assets/next-arrow-icon.svg");
export const RecentlyBookCardImg = require("../assets/recently-book-card-img.png");
export const LocationIcon = require("../assets/location-icon.svg");
export const carouselPrevArrowIcon = require("../assets/carousel-prev-icon.svg");
export const carouselNextArrowIcon = require("../assets/carousel-next-icon.svg");
export const FindServiceCardImg = require("../assets/find-service-card-img.jpeg");
export const BookingImg1 = require('../assets/booking-img-1.png');
export const BookingImg2 = require('../assets/booking-img-2.png');
export const BookingImg3 = require('../assets/booking-img-3.png');
export const BookingLogoImg = require('../assets/booking-logo-img.png');
export const CalendarIcon = require('../assets/calendar-icon.svg');
export const DailyCareIcon = require('../assets/daily-care-icon.svg');
export const DailyWalkIcon = require('../assets/daily-walk-icon.svg');
export const FreshWaterIcon = require('../assets/fresh-water-icon.svg');
export const IndoorRoomIcon = require('../assets/indoor-rooms-icon.svg');
export const FunZoneIcon = require('../assets/fun-zone-icon.svg');
export const OutDoorRoomIcon = require('../assets/outdoor-rooms-icon.svg');
export const NatureContactIcon = require('../assets/nature-contact-icon.svg');
export const GreenZoneIcon = require('../assets/green-zone-icon.svg');
export const TransportationIcon = require('../assets/transportation-icon.svg');
export const MedicationIcon = require('../assets/medication-icon.svg');
export const errorIcon  = require('../assets/error-icon.svg');
export const FootPrintIcon = require("../assets/footprint-icon.svg");
