import { Box, Button, InputLabel, TextField, Typography, styled } from '@material-ui/core';
import React from 'react';
import CheckboxList from './CheckboxList';
import { Filters } from '../AdvancedSearchController';
import { SearchData, useSearchHeaderContext } from '../../../../components/src/context/SearchHeaderContext';


type Props = {
    type: number,
    values: Filters,
    onChange: (type: keyof Filters, field: string, value?: string) => void;
    onSearch: (data: SearchData) => void;
}

const SwitchWrapper = styled(Box)(({ theme }) => ({
    "& .MuiSwitch-root": {
        width: 54,
        height: 26,
        padding: 0,
        marginTop: '12px'
    },
    "& .title": {
        color: '#6F05EA',
        fontSize: '16px',
        fontWeight: 600,
        width: '100%',
        paddingBottom: '8px',
        borderBottom: '1px solid #C4C4C4'
    },
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(27px)',
            color: 'rgba(111, 5, 234, 1)',
            '& + .MuiSwitch-track': {
            backgroundColor: 'rgba(111, 5, 234, 0.2)',
            opacity: 1,
            border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
            },
        },
        },

        "& .MuiSwitch-thumb": {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        },
    
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
}));

const PriceWrapper = styled(Box)({
    display: 'flex', 
    flexDirection: 'column',
    gap: '11px',

    "& .MuiOutlinedInput-notchedOutline": {
        border: 0
    },

    "& .range": {
        display: 'flex',
        gap: '14px',
        paddingBottom: '8px'
    },

    "& .euro-icon": {
        color: '#6F05EA',
        fontSize: '16px',
        fontWeight: 400
    },

    "& .label": {
        color: '#000',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Montserrat',
    },
    "& .input-wrapper": {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    "& .input-general": {
        background: '#fff',
        borderRadius: '8px',
    },
    "& .MuiInputBase-root":{
        display:'flex'
      }
});

const styles = {
    filterLabel: {
        color: '#6F05EA',
        fontSize: '16px',
        fontWeight: 600
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    input: {
        color: '#A6A6A6',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        backgroundColor: 'transparent',
        border: 'none',
    },
    applyBtn: {
        borderRadius: '24px',
        border: '1px solid #6F05EA',
        fontSize: '16px',
        color: '#6F05EA',
        fontWeight: 700
    }
}


const FilterSection = ({ type, values, onChange, onSearch }: Props) => {
    const { searchData } = useSearchHeaderContext();
    
    const handleNumberChange = (value: string) => {
        if (!value || /^\d+$/.test(value)) {
            return value;
        }

        return '';
    };

    const handleChange = (title: keyof Filters, item: string) => {
        onChange(title, item)
    }

    const renderTabFilters = () => {
        const hotelTypeList = [
            { label: 'Individual Room', value: 'Individual room'},
            { label: 'Suite Room', value: 'Suite room'},
            { label: 'Collective Room', value: 'Collective room'},
        ]

        const trainingList = [
            {
              label: "Individual Training",
              value: "individual training"
            },
            {
              label: "Aggressive behaviour change",
              value: "aggressive behaviour change"
            },
            {
              label: "Individual Puppy Training (2-6months)",
              value: "individual puppy training (2-6months)"
            },
            {
              label: "Individual junior training (7-18 months)",
              value: "individual junior training (7-18 months)"
            },
            {
              label: "Individual kitten training (2-6 months)",
              value: "individual kitten training (2-6 months)"
            },
            {
              label: "Special train (like certified training for potential dangerous dogs)",
              value: "special train"
            },
            {
              label: "General group training",
              value: "general group training"
            },
            {
              label: "Individual home sessions adult",
              value: "individual home sessions adult"
            },
            {
              label: "Individual home sessions puppy (2-6 months)",
              value: "individual home sessions puppy (2-6 months)"
            },
            {
              label: "Individual home sessions junior (7-18 months)",
              value: "individual home sessions junior (7-18 months)"
            },
          ]

        const commoditiesList = [
            { label: 'Outdoor Hotel', value: 'outdoor_hotel'},
            { label: 'Pool', value: 'pool'},
            { label: 'Veterinary Support', value: 'veterinary_support'},
            { label: 'Indoor Hotel	', value: 'indoor_hotel'},
            { label: 'Toys At Display	', value: 'toys_at_display'},
            { label: 'Litter Boxes', value: 'litter_boxes'},
            { label: 'Fresh Water', value: 'fresh_water'},
            { label: 'Natural Food', value: 'natural_food'},
            { label: 'Poop Spaces', value: 'poop_spaces'},
            { label: 'Comfortable Beds', value: 'comfortable_beds'},
            { label: 'Individual Room', value: 'individual_room'},
            { label: 'Cat Trees', value: 'cat_trees'},
            { label: 'Daily Walks', value: 'daily_walks'},
            { label: 'Group Rooms', value: 'group_rooms'},
            { label: 'Catio', value: 'catio'},
            { label: 'Garden', value: 'garden'},
            { label: 'Socializing Activities', value: 'socializing_activities'},
        ]

        switch (type) {
            case 0:
                return (
                    <>
                        <CheckboxList
                            list={commoditiesList} 
                            title="Commodities"
                            field="commodities" 
                            values={values.commodities}
                            onToggle={handleChange}
                        />
                        <CheckboxList 
                            list={hotelTypeList}
                            title="Room type"
                            field="hotelType"
                            values={values.hotelType}
                            onToggle={handleChange}
                        />
                    </>
                );
            case 2:
                return (
                    <CheckboxList 
                        list={[]} 
                        title="Grooming Services"
                        field="grooming" 
                        values={values.grooming}
                        onToggle={handleChange}
                    /> 
                );
            case 4: 
                return (
                    <>
                        <CheckboxList 
                            list={commoditiesList} 
                            title="Commodities"
                            field="dayCare" 
                            values={values.dayCare}
                            onToggle={handleChange}
                        />
                        
                        <SwitchWrapper>
                            <CheckboxList 
                                list={[{ label: 'Transportation', value: 'transport'}, { label: 'Medication', value: 'medication' }]} 
                                title="Additional Services"
                                field="transport" 
                                values={values.transport}
                                onToggle={handleChange}
                            />
                        </SwitchWrapper>
                    </>  
                );
            case 5: 
                return (
                    <CheckboxList 
                        list={trainingList} 
                        title=" Training types"
                        field="training" 
                        values={values.training}
                        onToggle={handleChange}
                    />
                )
            
            default:
                return null;
        }
    }

    return (
        <>
            <PriceWrapper>
                <Typography style={styles.filterLabel}>Price range</Typography>
                <Box className='range'>
                    <Box className='input-wrapper'>
                        <InputLabel className="label">
                            From
                        </InputLabel>
                        <TextField
                            data-test-id='min-price'
                            className='input-general'
                            variant='outlined'
                            value={values.price.min}
                            onChange={event => onChange('price', 'min', handleNumberChange(event.target.value))}
                            placeholder="00"
                            inputProps={{
                                style: styles.input
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Typography className='euro-icon'>€</Typography>
                                ),
                            }}
                        />
                    </Box>
                    <Box className='input-wrapper'>
                        <InputLabel className="label">To</InputLabel>
                        <TextField
                            data-test-id='max-price'
                            className='input-general'
                            variant='outlined'
                            value={values.price.max}
                            onChange={event => onChange('price', 'max', handleNumberChange(event.target.value))}
                            placeholder="00"
                            inputProps={{
                                style: styles.input
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Typography className='euro-icon'>€</Typography>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                {renderTabFilters()}
                <Button style={{ ...styles.applyBtn, textTransform: 'none'}} onClick={() => onSearch(searchData)}>Apply</Button>
            </PriceWrapper>
        </>
    )
}


export default FilterSection;