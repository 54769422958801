import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import { chunkArray } from "../../../components/src/HelperUtils";
import {removeGalleryIcon} from "./assets";
// Customizable Area End

import EstablishmentGalleryController, {
  Props,
} from "./EstablishmentGalleryController.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class EstablishmentGalleryWeb extends EstablishmentGalleryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // <img src={removeGalleryIcon} />

  
  renderGallery = () => {
    const chunkImageArray = chunkArray(this.props.serviceImageList, 5); // Using props instead of state
    return chunkImageArray.map((images, index) => (
      <Grid container spacing={1} style={{ flexDirection: (index + 1) % 2 || images.length !== 5 ? "row" : "row-reverse", marginBottom: "10px" }}>
        {(images.length === 5 || (index + 1) % 2 === 1) && (
          <LargeImageGrid item>
            <ImageWrapper>
              <StyledImage src={images[0].file_url} data-test-id="large-img" />
              {this.props.showDelete && (
                <DeleteButton onClick={() => this.handleDelete(images[0].id)}>
                  <img src={removeGalleryIcon} />
                </DeleteButton>
              )}
            </ImageWrapper>
          </LargeImageGrid>
        )}
        <SmallImagesGrid length={images.length} item data-test-id="small-img-grid">
          <Grid container style={{ height: "100%" }} spacing={1}>
            {((index + 1) % 2 ? images.slice(1, 5) : images.slice(0, 4)).map((img: any) => (
              <Grid item xs={12} sm={6} style={{ height: "208px" }} key={img.id}>
                <ImageWrapper>
                  <StyledImage src={img.file_url} data-test-id="small-img" />
                  {this.props.showDelete && (
                    <DeleteButton data-test-id="delete" onClick={() => this.handleDelete(img.id)}>
                      <img src={removeGalleryIcon} />
                    </DeleteButton>
                  )}
                </ImageWrapper>
              </Grid>
            ))}
          </Grid>
        </SmallImagesGrid>
      </Grid>
    ));
  };
  
  
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.renderGallery()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const LargeImageGrid = styled(Grid)({
  height: "417px",
  width: "44%",
  "@media (max-width: 960px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    height: "204px",
  },
});

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const SmallImagesGrid = styled(Grid)(
  ({ length = 0 }: { length?: number }) => ({
    height: length > 2 ? "417px" : "204px",
    width: "56%",
    "@media (max-width: 960px)": {
      width: "100%",
      height: "unset",
    },
    "@media (max-width: 600px)": {
      height: "unset",
    },
  })
);

const ImageWrapper = styled("div")({
  position: "relative",
});

const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: "-18px",
  right: "-18px",
});
// Customizable Area End