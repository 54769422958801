// Customizable Area Start
import { FormEvent } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
    loadStripe,
    Stripe,
    StripeCardNumberElement,
    StripeCardNumberElementChangeEvent,
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeElements,
    StripeElementsOptions,
} from "@stripe/stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { checkCondition, returnTruthyString } from "../../../../components/src/HelperUtils";

// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (card_token: string) => void;
    submitLoader: boolean;
    // DEFAULT
    setAsDefault: boolean;
    handleChangeDefault: (checked: boolean) => void;
    error: string;
    clearError: () => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    errorMessage: string,
    cardHolderName: string,
    cardBrand: string,
    cardComplete: {
        name: boolean,
        number: boolean,
        cvv: boolean,
        expiry: boolean
    },
    cardErrors: {
        name: string,
        number: string,
        cvv: string,
        expiry: string
    },
    submitBtnLoading: boolean
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class AddCardStripeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    stripePromise = loadStripe(webConfigJSON.STRIPE.PUBLISHABLE_KEY);
    stripePaymentObject: Stripe | null = null;
    options: StripeElementsOptions = {
        appearance: {},
    };
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            errorMessage: "",
            cardHolderName: "",
            cardBrand: "",
            cardComplete: {
                name: false,
                number: false,
                cvv: false,
                expiry: false
            },
            cardErrors: {
                name: "",
                number: "",
                cvv: "",
                expiry: ""
            },
            submitBtnLoading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleSubmit = async (
        event: FormEvent,
        stripe: Stripe | null,
        elements: StripeElements | null
    ) => {
        this.stripePaymentObject = stripe;
        event.preventDefault();
        this.closeErrorMessage()
        this.setSubmitBtnLoading(true)
        if (this.isCardHasError() || !this.isCardDetailsAdded()) {
            this.setCardError()
            this.setSubmitBtnLoading(false)
            return
        }
        if(!this.isValidCardBrand()){
            this.setErrorMessage(webConfigJSON.CARD_ERROR.CARD_BRAND)
            this.setSubmitBtnLoading(false)
            return;
        }

        if (!stripe || !elements) {
            this.setErrorMessage(webConfigJSON.CARD_ERROR.STRIPE_NOT_LOADED)
            this.setSubmitBtnLoading(false)
            return;
        }
        const { error: submitError } = await elements.submit();
        if (submitError) {
            this.setErrorMessage(returnTruthyString(submitError.message))
            this.setSubmitBtnLoading(false)
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        // CREATE TOKEN
        const { error, token } = await stripe.createToken(
            cardElement as StripeCardNumberElement,
            {
                name: this.state.cardHolderName,
            },
        );

        if (error && error.message) {
            this.setErrorMessage(returnTruthyString(error.message))
            this.setSubmitBtnLoading(false)
        }

        if (token && token.id) {
            this.setSubmitBtnLoading(false)
            this.props.onSubmit(token.id)
        }
    };

    closeErrorMessage = () => {
        this.props.clearError()
        this.setState({
            errorMessage: ""
        })
    }
    setErrorMessage = (message: string) => {
        this.setState({
            errorMessage: message
        })
    }
    handleChangeName = (cardHolderName: string) => {
        this.closeErrorMessage()
        const nameError = checkCondition(Boolean(!cardHolderName), webConfigJSON.CARD_ERROR.NAME, "") as string
        this.setState({
            cardHolderName: cardHolderName,
            cardErrors: {
                ...this.state.cardErrors,
                name: nameError

            },
            cardComplete: {
                ...this.state.cardComplete,
                name: Boolean(cardHolderName)
            }
        })
    }
    handleChangeNumber = (event: StripeCardNumberElementChangeEvent) => {
        this.closeErrorMessage()
        const numberError = checkCondition(Boolean(event?.error?.message), event?.error?.message, "") as string

        this.setState({
            cardBrand: event?.brand,
            cardErrors: {
                ...this.state.cardErrors,
                number: numberError
            },
            cardComplete: {
                ...this.state.cardComplete,
                number: event.complete
            }
        })
    }
    handleChangeExpiry = (event: StripeCardExpiryElementChangeEvent) => {
        this.closeErrorMessage()
        const expiryError = checkCondition(Boolean(event?.error?.message), event?.error?.message, "") as string
        this.setState({
            cardErrors: {
                ...this.state.cardErrors,
                expiry: expiryError
            },
            cardComplete: {
                ...this.state.cardComplete,
                expiry: event.complete
            }
        })
    }
    handleChangeCVC = (event: StripeCardCvcElementChangeEvent) => {
        this.closeErrorMessage()
        const cvvError = checkCondition(Boolean(event?.error?.message), event?.error?.message, "") as string
        this.setState({
            cardErrors: {
                ...this.state.cardErrors,
                cvv: cvvError
            },
            cardComplete: {
                ...this.state.cardComplete,
                cvv: event.complete
            }
        })
    }

    isCardHasError = () => {
        const { number, expiry, cvv, name } = this.state.cardErrors
        return Boolean(name || number || expiry || cvv)
    }
    isCardDetailsAdded = () => {
        const { name, number, expiry, cvv } = this.state.cardComplete
        return name && number && expiry && cvv
    }
    setCardError = () => {
        const { name, number, expiry, cvv } = this.state.cardComplete
        let nameError = ""
        let numberError = ""
        let expiryError = ""
        let cvvError = ""

        if (!name) {
            nameError = webConfigJSON.CARD_ERROR.NAME
        }
        if (!number) {
            numberError = webConfigJSON.CARD_ERROR.NUMBER
        }
        if (!expiry) {
            expiryError = webConfigJSON.CARD_ERROR.EXPIRY
        }
        if (!cvv) {
            cvvError = webConfigJSON.CARD_ERROR.CVV
        }

        this.setState({
            cardErrors: {
                name: nameError,
                number: numberError,
                expiry: expiryError,
                cvv: cvvError
            }
        })
    }
    handleCancel = () => {
        this.props.onClose()
    }
    setSubmitBtnLoading = (loadingStatus: boolean) => {
        this.setState({
            submitBtnLoading: loadingStatus
        })
    }

    isValidCardBrand = () => {
        const { cardBrand } = this.state
        const validBrands = ["visa", "mastercard"]
        let isBrandValid = false
        
        if (cardBrand && validBrands.includes(cardBrand)) {
            isBrandValid = true
        }
        return isBrandValid
    }
    // Customizable Area End
}

