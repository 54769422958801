import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import {getImageColumn, capitalizeFirstLetter} from "../../../../components/src/HelperUtils";
import SpacesController, { Props } from "./SpacesController.web";
import {
  NextArrowIcon,
  removeGalleryIcon,
  uploadGalleryIcon,
  ViewClose,
} from "../assets";
import { Formik } from "formik";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { LoadingButton, SimpleDialog } from "../../../../components/src/HelperComponents";
import SubServiceGalleryWeb from "./SubServiceGallery.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const SpacesContainer = styled(Box)({
  width:"100%",
  "& .newSpacesBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "72px",
  },
  "& .newSpacesBtn": {
    fontSize: "18px",
    fontWeight: 700,
    color: "#6F05EA",
    borderRadius: "100px",
    fontFamily: "Montserrat",
    border: "1px solid #6F05EA",
    padding: "8px 36px",
    textTransform: "none",
    height: "40px",
    lineHeight: "24px",
  },
  "& .disableNewSpacesBtn": {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    color: "#78716C",
    borderRadius: "100px",
    border: "1px solid #78716C",
    padding: "8px 36px",
    textTransform: "none",
    height: "40px",
    lineHeight: "24px",
  },
});

const DialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      maxWidth: 1240,
    },
    "& .dialogTitle": {
      padding: "40px 60px",
      "& h2": {
        fontSize: "32px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        color: "#6F05EA",
        "@media (max-width: 600px)": {
          fontSize: "24px",
        },
      },
      "@media (max-width: 600px)": {
        padding: "32px",
      },
      "@media (max-width: 400px)": {
        padding: "24px",
      },
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "18px",
      fontFamily: "Montserrat",
      fontWeight: 400,
      color: "#6F05EA",
    },
    "& .MuiInputBase-input": {
      marginLeft: "8px",
      marginTop: "4px",
      fontFamily: "Montserrat",
      fontSize: "12px",
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      fontStyle: "italic",
    },
    "& .MuiInput-underline::before": {
      borderBottom: "1px solid #D9D9D9",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid #D9D9D9",
    },
    "& .selectPlaceholder": {
      fontSize: "12px",
      color: "rgba(166, 166, 166, 1)",
      fontFamily: "Montserrat",
      fontStyle: "italic",
    },
    "& .selectMenu": {
      fontSize: "16px",
      color: "#0F172A",
      fontFamily: "Montserrat",
    },
    "& .allowedPetSelect": {
      width: "222px",
      marginTop: "42px",
      "& .MuiSelect-select:focus":{
        background:"transparent"
      },
      "@media (max-width: 600px)": {
        minWidth: "100%",
      },
    },
    "& .descriptionInput": {
      width: "361px",
      marginTop: "42px",
      "@media (max-width: 600px)": {
        width: "100%",
      },
      "& textarea": {
        resize: "none",
        borderRadius: "8px !important",
        height: "83px",
        fontFamily: "Montserrat",
      },
      "& .MuiInputBase-multiline": {
        marginTop: "25px",
        border: "1px solid #D9D9D9",
        borderRadius: "8px",
      },
      "& .MuiInput-underline::before": {
        borderBottom: "none",
      },
      "& MuiInputBase-multiline::placeholder": {
        resize: "none",
        height: "83px",
        fontFamily: "Montserrat",
      },
    },
    "& .spaceGalleryContainer": {
      marginTop: "32px",
    },
    "& .spaceGalleryTitle": {
      fontSize: "14px",
      fontFamily: "Montserrat",
      color: "#6F05EA",
      fontWeight: 400,
      marginBottom: "6px",
    },
    "& .uploadInputContainer": {
      minWidth: "84px",
      maxWidth: "84px",
      height: "84px",
      width: "84px",
      border: "1px solid #D9D9D9",
      borderRadius: "9px",
      textAlign: "center",
      textTransform: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
      "& .MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
      },
    },
    "& .uploadIcon": {
      height: "24px",
      width: "24px",
    },
    "& .uploadInputTitle": {
      fontSize: "10px",
      fontFamily: "Montserrat",
      width: 54,
    },
    "& .uploadFileInput": {
      height: "82px",
      width: 83,
      opacity: 0,
    },
    "& .previewContainer": {
      display: "grid",
      gap: 12,
      gridTemplateColumns: "repeat(6,1fr)",
      flexWrap: "wrap",
      width: "560px",
      "@media (max-width: 959px)": {
        gridTemplateColumns: "repeat(4,1fr)",
        width: "400px",
      },
      "@media (max-width: 600px)": {
        gridTemplateColumns: "repeat(3,1fr)",
        width: "280px",
      },
      "@media (max-width: 480px)": {
        gridTemplateColumns: "repeat(2,1fr)",
        width: "180px",
      },
      "@media (max-width: 400px)": {
        gridTemplateColumns: "repeat(1,1fr)",
        width: "130px",
      },
      "& .file-preview": {
        position: "relative",
        height: 83,
        width: 83,
        "& .preview-image": {
          width: 83,
          height: 83,
          objectFit: "cover",
          borderRadius: 6,
        },
        "& .remove-btn": {
          position: "absolute",
          right: 0,
          left: "69px",
          bottom: "70px",
        },
      },
    },
    "& .uploadContainer": {
      display: "flex",
      columnGap: "12px",
      rowGap: "12px",
      flexWrap: "wrap"
    },
    "& .dialogActionContainer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "26px",
      marginTop: "70px",
    },
    "& .cancelButton": {
      borderColor: "#78716C",
      color: "#78716C",
      fontSize: 18,
      fontWeight: "700",
      fontFamily: "Montserrat",
    },
    "& .actionButton": {
      height: "56px",
      width: "100%",
      maxWidth: "246px",
      border: "1px solid",
      textTransform: "none",
      borderRadius: "100px",
    },
    "& .saveButton": {
      borderColor: "#6F05EA",
      color: "#6F05EA",
      fontSize: 18,
      fontFamily: "Montserrat",
      fontWeight: "700",
    },
    "& .errorText": {
      color: "#f44336",
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
    },
  },
});
const ContentWrapper = styled(DialogContent)({
  padding: "0px 60px",
  "@media (max-width: 600px)": {
    padding: "0 32px",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  },
})

const SpaceWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  minHeight: "48px",
  height: "100%",
  alignItems: "center",
  padding: "8px 24px",
  rowGap: "8px",
  justifyContent: "space-between",
  "@media (max-width: 600px)": {
    padding: "8px",
  },
  flexWrap: "wrap",
});

const TitleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  width:"225px",
  flexWrap:"wrap"
});

const CapacityWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  alignItems: "center",
  flexWrap: "wrap",
  minWidth: 100,
});

const DescWrapper = styled(Box)({
  display: "flex",
  width: "370px",
  flexDirection: "row",
  columnGap: "8px",
  alignContent: "center",
  flexWrap: "wrap",
  rowGap: "8px",
  "@media (max-width: 600px)": {
    width: "300px"
  },
  "@media (max-width: 400px)": {
    width: "250px"
  }
});

const SpaceListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  "& .no-data-found":{
    color:"rgb(183, 183, 183)",
    fontSize:18,
    textAlign:"center",
    fontFamily: 'Montserrat',
    fontWeight:600,
  },
  "& .progressBarContainer":{
    display:"flex",
    justifyContent:"center",
    "& .MuiCircularProgress-colorPrimary":{
      color:"#6F05EA"
    }
  }
});

const LabelFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#0F172A",
});

const ValueFont = styled(Typography)({
  maxWidth: 250,
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6F05EA",
});

const ValueFontDec = styled(Typography)({
  maxWidth: 250,
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6F05EA",
});

const StyledViewDialogContainer = styled(Dialog)({
  "& .MuiPaper-root":{
    "& ::-webkit-scrollbar": {
      width: "0px",
      borderRadius: "6px"
    },
    "& ::-webkit-scrollbar-track":{
      background: "#CBD5E1",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb":{
      background: "#6F05EA",
      borderRadius: "4px", 
      width: "6px", 
    },
    "& ::-webkit-scrollbar-thumb:hover":{
      background: "#6F05EA",
    }
  },
  "& .viewDialogContainer": {
    padding: "36px 32px 64px 47px",
    height:"100%",
    flexDirection:"column",
    display:"flex",
    "@media (max-width: 600px)": {
      padding:"18px 23px 32px 23px"
  },
  },
  "& .closeImageContainer": {
    display: "flex",
    justifyContent: "flex-end",
    "& img":{
      "@media (max-width: 600px)": {
        height:"16px",
        width:"16px"
    },
    }
  },
  "& .titleTypo": {
    color: "#6F05EA",
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  "& .infoContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    marginTop: 20,
    flexWrap: "wrap",
  },
  "& .infoTypo": {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    textAlign: "center",
    "& span": {
      color: "#6F05EA",
      fontFamily: "Montserrat",
      textWrap: "wrap",
    },
  },
  "& .descriptionTypo": {
    fontSize: "11px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    textAlign: "justify",
    whiteSpace:"pre-wrap"
  },
  "& .descriptionContainer": {
    marginTop: "28px",
    backgroundColor: "#FBFBFB",
    padding: 8,
    borderRadius: 8,
  },
  "& .imgGalleryContainer": {
    overflowY:"hidden",
    padding: "0px 21px 0 8px", 
    marginRight: "9px"
  },
  "& .card": {
    border: "none",
    boxShadow: "none",
    height: "100%",
  },
  "& .media": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "& .viewDialogBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop:"62px",
    marginRight:"32px"
  },
  "& .viewEditBtn": {
    padding: "8px 0",
    height: "40px",
    width: "140px",
    borderRadius: "100px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Montserrat",
    lineHeight: "24px",
    textTransform: "none",
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  },
  "& .imageGalleryContainer":{
    display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      "& .MuiImageList-root":{
        overflowY:"hidden"
      }
  },
  "& .imageGalleryImage":{
    height:"100%",
    width:"100%"
  }
});

const ResponsiveImageList = styled(ImageList)({
  maxWidth:"750px",
  "& .MuiImageListItem-root":{
    // height:"100% !important",
    maxHeight:"250px !important",
  },
  "@media (max-width: 600px)": {
    "& .MuiImageListItem-root":{
      width:"50% !important",
    }
  },
  "@media (max-width: 450px)": {
    "& .MuiImageListItem-root":{
      width:"50% !important",
    }
  },
});

const ArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
  }
});
const StyledCreateSpace = styled(Dialog)({
  "& .MuiDialog-paperWidthMd":{
    maxWidth:"751px"
  },
  "& .CreateSpaceContainer":{
    padding:"33px"
  }
});
const CreateSpaceImgWrapper = styled(Box)({
  display:"flex",
  justifyContent:"flex-end"
});
const CloseIconButton = styled(IconButton)({
  height:"32px", 
  width:"32px"
});
const CreateSpaceInfoWrapper = styled(Box)({
  maxWidth:"547px", 
  padding:"153px 70px 171px 70px", 
  textAlign:"center",
  "@media (max-width: 960px)": {
    padding:"133px 50px 151px 70px"
  },
  "@media (max-width: 600px)": {
    padding:"100px 0px 120px 0px"
  },
  "& .CreateSpaceTypo":{
    fontSize:"28px",
    fontWeight:600,
    lineHeight:"34px",
    "@media (max-width: 960px)": {
      fontSize:"24px",
    },
    "@media (max-width: 600px)": {
      fontSize:"20px",
    },
  },
  "& .pleasFillTypo":{
    fontSize:"24px",
    fontWeight:500,
    lineHeight:"29px",
    "@media (max-width: 960px)": {
      fontSize:"20px",
    },
    "@media (max-width: 600px)": {
      fontSize:"16px",
    },
  }
});


const spaceSchema = Yup.object().shape({
  spaceTitle: Yup.string()
    .required("*Space Title is required")
    .trim(),
  capacity: Yup.number()
    .typeError("*Capacity must be a number")
    .required("*Capacity is required"),
  allowedPet: Yup.string()
    .required("*Allowed Pet is required")
    .trim(),
  description: Yup.string()
    .required("*Description is required")
    .trim(),
  images: Yup.array()
    .min(1, "*One image is required"),
});
// Customizable Area End

export default class SpacesWeb extends SpacesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSpaceCard = () => {
    return (
      <Box>
        <SpaceListWrapper>
          {this.state.getSpaceList.length && !this.state.getSpaceListLoading   ? (this.state.getSpaceList.map((item) => {
            return (
              <>
                <SpaceWrapper key={item.id}>
                    <TitleWrapper>
                      <LabelFont>Space Title :</LabelFont>
                      <ValueFont noWrap>
                        <span title={item.attributes.title}>{item.attributes.title}</span>
                      </ValueFont>
                    </TitleWrapper>
                    <CapacityWrapper>
                      <LabelFont>Capacity :</LabelFont>
                      <ValueFont style={{ fontWeight: 600 }} noWrap>
                        {item.attributes.capacity}
                      </ValueFont>
                    </CapacityWrapper>
                  <DescWrapper>
                    <Box style={{display:"flex", columnGap:"8px"}}>
                     <LabelFont>Description :</LabelFont>
                     <ValueFontDec className="value-dec" noWrap>
                       <span title={item.attributes.description}>{item.attributes.description}</span>
                      </ValueFontDec>
                    </Box>
                  </DescWrapper>
                    <img
                      style={{ cursor: "pointer" }}
                    src={NextArrowIcon}
                    onClick={() => this.handleViewClick(item.id)}
                    data-test-id="view-data-btn"
                  />
                </SpaceWrapper>
              </>
            )
          })): (
            <></>
          )}
          {!this.state.getSpaceList.length && this.state.getSpaceListLoading ? 
          <Box className="progressBarContainer"><CircularProgress/></Box>
            : <></>}
          {!this.state.getSpaceList.length && !this.state.getSpaceListLoading ? 
          <Typography className="no-data-found">No results found!</Typography>
          : <></>} 
        </SpaceListWrapper>
      </Box>
    );
  };

  renderAddNewSpaceButton = () => {
    return (
      <Box className="newSpacesBtnContainer">
        <Button
          className="newSpacesBtn"
          onClick={this.handleAddNewSpacesClick}
          data-test-id="add-space-btn"
        >
           Add New Spaces
        </Button>
      </Box>
    );
  };

  renderAddSpacesDialog = () => {
    return (
      <>
        <DialogContainer
          fullWidth
          open={this.state.isDialogOpen}
          data-test-id="spaceDialog"
        >
            <DialogTitle className="dialogTitle">{this.state.isEdit ? "Edit Spaces" : "Add Spaces"}</DialogTitle>

            <ContentWrapper>
              <Formik
                initialValues={this.state.spaceFormInitialValue}
                validationSchema={spaceSchema}
                enableReinitialize
                onSubmit={this.handleFormSubmit}
              >
                {({ errors, touched, handleSubmit, setFieldValue, getFieldProps, values }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <RowBox>
                        <LeftBox>
                        <TextField
                          {...getFieldProps("spaceTitle")}
                          id="spaceTitle"
                          label="Space title"
                          placeholder="Space title"
                          fullWidth
                          className="spaceTitleInput"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            Boolean(errors.spaceTitle) && touched.spaceTitle
                          }
                          helperText={(
                            touched.spaceTitle && errors.spaceTitle
                          )?.toString()}
                        />
                        </LeftBox>
                        <RightBox>
                        <TextField
                          {...getFieldProps("capacity")}
                          id="capacity"
                          label="Capacity"
                          placeholder="Enter Capacity"
                          fullWidth
                          margin="normal"
                          className="capacityInput"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(errors.capacity) && touched.capacity}
                          helperText={(
                            touched.capacity && errors.capacity
                          )?.toString()}
                        />
                        </RightBox>
                      </RowBox>
                      <Box>
                        <FormControl
                          className="allowedPetSelect"
                          error={
                            Boolean(errors.allowedPet) && touched.allowedPet
                          }
                        >
                          <InputLabel shrink>Allowed Pet</InputLabel>
                          <Select
                            {...getFieldProps("allowedPet")}
                            id="allowedPet"
                            displayEmpty
                            IconComponent={ArrowIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value="" disabled>
                              <span className="selectPlaceholder">
                                Select Pet
                              </span>
                            </MenuItem>
                            <MenuItem className="selectMenu" value="Dog">
                              Dogs
                            </MenuItem>
                            <MenuItem className="selectMenu" value="Cat">
                              Cats
                            </MenuItem>
                            <MenuItem className="selectMenu" value="Fish">
                              Fish
                            </MenuItem>
                            <MenuItem className="selectMenu" value="Bird">
                              Bird
                            </MenuItem>
                            <MenuItem className="selectMenu" value="Rabbit">
                              Rabbit
                            </MenuItem>
                            <MenuItem className="selectMenu" value="Reptile">
                              Reptile
                            </MenuItem>
                            <MenuItem className="selectMenu" value="Rodent">
                              Rodents
                            </MenuItem>
                          </Select>
                          {errors.allowedPet && touched.allowedPet && (
                            <FormHelperText
                              data-test-id="upload-error"
                              className="errorText"
                            >
                              {errors.allowedPet}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                      <Box>
                        <TextField
                          {...getFieldProps("description")}
                          id="description"
                          label="Description"
                          placeholder="Enter Description"
                          fullWidth
                          multiline
                          minRows={4}
                          className="descriptionInput"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            Boolean(errors.description) && touched.description
                          }
                          helperText={(
                            touched.description && errors.description
                          )?.toString()}
                        />
                      </Box>
                      <Box className="spaceGalleryContainer">
                        <Typography className="spaceGalleryTitle">
                          Space Gallery
                        </Typography>
                        <Box className="uploadContainer">
                          <Button
                            className="uploadInputContainer"
                            variant="contained"
                            component="label"
                          >
                            <img src={uploadGalleryIcon} alt="upload" />
                            <Typography className="uploadInputTitle">
                              upload file (png,jpg)
                            </Typography>
                            <input
                              type="file"
                              hidden
                              multiple
                              data-test-id="upload-img"
                              onChange={(event) => {
                                const fileList = event.currentTarget.files;
                                if (fileList) {
                                  const files = Array.from(fileList);
                                  const newFiles = [...values.images, ...files];
                                  setFieldValue('images', newFiles);
                                }
                              }}
                              accept=".png, .jpg"
                            />
                          </Button>
                          <ImageListWrapper>
                            {values.images.map((file, index) => (
                              <ImageBox key={index}>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`preview ${index}`}
                                  className="preview-image"
                                />
                                <RemoveBtn
                                  onClick={() => {
                                    const updatedFiles = values.images.filter((_, i) => i !== index);
                                    setFieldValue('images', updatedFiles);
                                  }}
                                  data-test-id="remove-btn"
                                  className="remove-btn"
                                >
                                  <img src={removeGalleryIcon} />
                                </RemoveBtn>
                              </ImageBox>
                            ))}
                          </ImageListWrapper>
                        </Box>
                        {
                          errors.images && touched.images && (
                            <FormHelperText
                              className="errorText"
                              data-test-id="upload-error"
                            >
                              {errors.images}
                            </FormHelperText>
                          )
                        }
                      </Box>
                      <Box className="dialogActionContainer">
                        <Button
                          onClick={this.handleDialogClose}
                          className="cancelButton actionButton"
                          data-test-id="cancel-btn"
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          isLoading={this.state.saveUpdateLoading}
                          className="saveButton actionButton"
                          type="submit"
                          data-test-id="save-btn"
                          buttonText="Save"
                        >
                        </LoadingButton>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </ContentWrapper>
        </DialogContainer>
      </>
    );
  };

  renderImageGallery = () => {
    return (
      <Box className="imageGalleryContainer">
        <SubServiceGalleryWeb serviceImageList={this.state.selectedViewData.attributes.images_urls} />
      </Box>
    );
  };

  renderSimpleDialog = () => {
    const { openSimpleModal, messageSimpleModal } = this.state;
    return (
      <>
        {openSimpleModal &&
          <SimpleDialog
            data-testid="simple-dialog"
            open={openSimpleModal}
            message={messageSimpleModal}
            showRightBtn
            rightBtnTitle="Okay"
            handleRightClick={this.simpleModalClose}
          />
        }
      </>
    )
  }

  renderViewDialog = () => {
    return (
      <StyledViewDialogContainer
        fullWidth
        open={this.state.isViewDialogOpen}
        maxWidth={"md"}
        data-test-id="view-data-modal"
      >
        <Box className="viewDialogContainer">
          <ViewDialogTitle>
            <Box className="closeImageContainer">
              <img src={ViewClose} onClick={this.handleViewDialogClose} data-test-id="view-close-btn" style={{ cursor: "pointer" }} />
            </Box>
            <Box style={{ paddingRight: "15px", marginBottom: "25px" }}>
              <Typography className="titleTypo">{this.state.selectedViewData.attributes.title}</Typography>
              <Box className="infoContainer">
                <Typography className="infoTypo">
                  Capacity : <span>{this.state.selectedViewData.attributes.capacity}</span>{" "}
                </Typography>
                <Typography className="infoTypo">
                  Allowed Pets: <span>{capitalizeFirstLetter(this.state.selectedViewData.attributes.allows_pet)}</span>
                </Typography>
              </Box>
              <Box className="descriptionContainer">
                <span className="descriptionTypo">
                  {this.state.selectedViewData.attributes.description}
                </span>
              </Box>
            </Box>
          </ViewDialogTitle>
          <DialogContent className="imgGalleryContainer">
            <Box>
              {this.renderImageGallery()}
            </Box>
          </DialogContent>
          <Box className="viewDialogBtnContainer">
            <Button
              className="viewEditBtn"
              onClick={this.handleViewEditClick}
              data-test-id="edit-btn"
            >
              Edit
            </Button>
            <Button
              onClick={this.handleHiddenClick}
              data-test-id="hidden-btn"
              style={{ display: "none" }}
            ></Button>
          </Box>
        </Box>
      </StyledViewDialogContainer>
    );
  };

  renderCreateSpaceModal = () => {
    return (
      <StyledCreateSpace
        fullWidth
        open={this.state.openCreateSpace}
        maxWidth={"md"}
        data-test-id="cerate-space-modal"
      >
        <Box className="CreateSpaceContainer">
          <CreateSpaceImgWrapper>
            <CloseIconButton onClick={() => this.handleCreateSpaceDialogClose()} data-test-id="space-close-btn"><img src={ViewClose} alt="close-icon" /></CloseIconButton>
          </CreateSpaceImgWrapper>
          <CreateSpaceInfoWrapper>
            <Typography className="CreateSpaceTypo">
              To create a space, we'll need some basic information. 
            </Typography>
            <Typography className="pleasFillTypo">
              Please fill in the general details and commodity specifics first!
            </Typography>
          </CreateSpaceInfoWrapper>
          </Box>
      </StyledCreateSpace>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <SpacesContainer>
          <Box>
            {this.renderSpaceCard()}
            {this.renderAddNewSpaceButton()}
            {this.renderAddSpacesDialog()}
            {this.state.selectedViewData.attributes && this.renderViewDialog()}
            {this.renderCreateSpaceModal()}
            {this.renderSimpleDialog()}
          </Box>
        </SpacesContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 32,
  flexWrap: "wrap"
})
const LeftBox = styled(Box)({
  maxWidth: 360,
  width: "100%",
})
const RightBox = styled(Box)({
  maxWidth: 124,
  width: "100%"
})
const ImageBox = styled(Box)({
  width: "83px",
  height: "83px",
  position: "relative",
  padding: 0,
  border: "1px solid #D9D9D9",
  borderRadius: "9px",
  "& .preview-image": {
    borderRadius: "9px",
    width: "83px",
    height: "83px",
  },
  "& .remove-btn": {
    position: "absolute",
    top: -10,
    right: -10,
    padding: 0,
  }
})
const RemoveBtn = styled(IconButton)({
  position: "absolute",
  top: -10,
  right: -10,
  padding: 0,
})
const ImageListWrapper = styled(Box)({
  width: "100%",
  maxWidth: 580,
  display: "flex",
  flexDirection: "row",
  columnGap: 12,
  rowGap: 20,
  flexWrap: "wrap",
})

const ViewDialogTitle = styled(DialogTitle)({
  padding: "0px 25px 0px 0px"
})
// Customizable Area End
