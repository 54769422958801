import { Box, Typography, styled } from '@material-ui/core';
import React from 'react';

type Props = {
    label: string,
    width: number,
    children: React.ReactNode
}

const Wrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',

    "& .label": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 400
    },

    "& .content": {
        borderBottom: '1px solid #D9D9D9',
        height: '34px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '8px'
    }
})

const DisplayField = ({ label, width, children }: Props) => {

    return (
        <Wrapper style={{ width: `${width}px`}}>
            <Typography className='label'>{label}</Typography>
            <Box className='content'>
                {children}
            </Box>
        </Wrapper>
    )
}

export default DisplayField;