import moment from "moment";
import React from "react";
import { EventProps } from "react-big-calendar";
import { Box, Paper, Typography, styled } from "@material-ui/core";
import { CalendarEventTypeOne } from "../../../blocks/customisableuserprofiles/src/types";

const WeekWrapper = styled(Paper)(({ theme }) => ({
  minHeight: "70%",
  minWidth: '100%',
  color: "#000",
  borderRadius: "6px",
  display: "flex",
  justifyContent: 'space-between',
  flexDirection: "column",
  border: '1px solid #A8A29E',
  overflow: "hidden",
  padding: "4px 4px",
  fontFamily: 'Montserrat',
  [theme.breakpoints.up("md")]: {
    minWidth: '70%',
    padding: "4px 8px",
  },

  "& .event-content": {
    overflow: "hidden",
    alignSelf: "flex-start",
    width: "100%",
    fontSize: "12px",
    display: 'flex',
    flexDirection: 'column'
  },
  "& .event-price": {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px'
  },
  "& .event-counts": {
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 700,
    wordBreak: 'break-all'
  },
  "& .event-dot": {
    minWidth: '8px',
    minHeight: '8px',
    width: '8px',
    height: '8px',
    borderRadius: '50%'
  },
  "& .count-wrapper": {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    flexWrap: 'wrap-reverse'
  },
  "& .event-booked": {
    fontSize: '7px',
    fontWeight: 400,
    alignSelf: 'flex-end',
    lineHeight: '7px'
  }
}));

const WeekEventService: React.FC<EventProps<CalendarEventTypeOne>> = ({ event }) => {
  const isFull = event.ss_schedules_count >= event.ss_capacity; 

  return (
    <WeekWrapper className="week-event-wrapper" elevation={1} style={{ backgroundColor: isFull? "#ECFDF5" : "#F5F5F4",}}>
      <Box className="event-content">
        <Typography className="event-price">{`${event.sub_service_price} €`}</Typography>
        <Box className="count-wrapper">
            <div className="event-dot" style={{ background: isFull ? '#059669' : '#A8A29E'}}/>
            <Typography className="event-counts">{`${event.ss_schedules_count}/${event.ss_capacity}`}</Typography>
        </Box>
      </Box>
      <Typography className="event-booked">{isFull ? 'Fully booked' : ''}</Typography>
    </WeekWrapper>
  );
};

export default WeekEventService;
