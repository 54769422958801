import React from "react";
// Customizable Area Start
import {
  Avatar, Box, Button, Grid, Typography, Divider, Checkbox, MenuItem,
  CircularProgress, FormHelperText,Container,Modal
} from "@material-ui/core";
import Select, { SelectProps } from '@material-ui/core/Select';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import BookingController, { Props, webConfigJSON } from "./BookingController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { LandingPageHeader } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import {
  AddressLocation, TransportationIcon, MedicationIcon, checkedIcon, unCheckedIcon, warningIcon, CommoditiesIcon,
  petPawIcon, RightBtnArrowIcon, DateCalendar, checkRepeat, unCheckRepeat,
  capacityCalendar,selectedmarkerpin,crossButton,MarkerSmall
} from "./assets";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import BookingWebMap from "../../../components/src/landingpage/BookingWebMap";
import DatePicker from "react-datepicker";
import { BookingButton, CancellationPolicy, CommoditiesList, DialogWithImage, RepeatButton, RepeatDayButton, SimpleDialog } from "../../../components/src/HelperComponents";
import { capitalizeFirstLetter, checkCondition, getDateAfterWeek, modifiedPetList, nextDayDate, returnTruthyString } from "../../../components/src/HelperUtils";
import ServiceBookingTableWeb from "./component/ServiceBookingTable";
import { Price } from "../../../components/src/interfaces.web";
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import ServiceGalleryWeb from "./component/ServiceGallery.web";
import BookingCheckout from "./component/BookingCheckout.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6928F2",
      contrastText: "#fff",
    }
  },
  typography: {
    fontFamily: "Montserrat",
  },
});
const selectMenuProps: Partial<SelectProps['MenuProps']> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const Adornment = (imgSrc: string) => (
  <img src={imgSrc} style={{ height: "24px", width: "24px" }} />
);
// Customizable Area End

export default class Booking extends BookingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderServiceHeader = () => {
    const establishmentData = this.state.getDetailsData?.data?.attributes?.establishment;
    const establishmentTitle = returnTruthyString(capitalizeFirstLetter(establishmentData?.establishment_name));
    const establishmentAddress = returnTruthyString(establishmentData?.address);
    const serviceImgList = this.getServiceGallery();
    const isGalleryShow = serviceImgList.length > 0;
    return (
      <>
        <ServiceTitle>{establishmentTitle}</ServiceTitle>
        <AddressBox>
          <img src={AddressLocation} style={style.locationImg} />
          <AddressFont>
            {establishmentAddress}
          </AddressFont>
        </AddressBox>
        <Box></Box>
        {isGalleryShow && this.renderServiceGallery()}
        {this.renderServiceAbout()}
      </>
    )
  }
  renderServiceGallery = () => {
    let img1, img2, img3, img4, img5;
    const galleryImageList = this.getServiceGallery();
    if (Array.isArray(galleryImageList) && galleryImageList.length > 0) {
      img1 = galleryImageList[0]?.image_url;
      img2 = galleryImageList[1]?.image_url;
      img3 = galleryImageList[2]?.image_url;
      img4 = galleryImageList[3]?.image_url;
      img5 = galleryImageList[4]?.image_url;
    }
    const isShowVieMore = galleryImageList.length > 5
    return (
      <>
        <GalleryWrapper>
          <GalleryGrid>
            {img1 && <LeftImageBox>{<img src={img1} style={style.subImg} />}</LeftImageBox>}
            <RightImageBox>
              <ImageBoxWrapper>
                {img2 && <ImageBox >{<img src={img2} style={style.subImg} />}</ImageBox>}
                {img3 && <ImageBox >{<img src={img3} style={style.subImg} />}</ImageBox>}
              </ImageBoxWrapper>
              <ImageBoxButtonWrapper>
                {img4 && <ImageBox >{<img src={img4} style={style.subImg} />}</ImageBox>}
                {img5 && <ImageBox >{<img src={img5} style={style.subImg} />}</ImageBox>}
                {isShowVieMore && <ViewMoreBtn onClick={this.handleViewMore} data-test-id="view-more-btn">View More</ViewMoreBtn>}
              </ImageBoxButtonWrapper>
            </RightImageBox>
          </GalleryGrid>
        </GalleryWrapper>
      </>
    )
  }

  renderServiceAbout = () => {
    const establishmentInfo = this.state.getDetailsData?.data?.attributes?.establishment;
    const about = this.state.getDetailsData?.data?.attributes?.about;
    const establishmentName = returnTruthyString(capitalizeFirstLetter(establishmentInfo?.establishment_name));
    const establishmentAbout = returnTruthyString(about);
    const establishmentURL = this.state.getDetailsData?.data?.attributes?.establishment_image;

    return (
      <>
        <AboutWrapper>
          <Box>
            <EstablishmentBox>
              <EstablishmentAvatar src={establishmentURL}>{establishmentName?.charAt(0)}</EstablishmentAvatar>
              <EstablishmentFont>
                {establishmentName}
              </EstablishmentFont>
            </EstablishmentBox>
          </Box>
          <AboutBox>
            <SubHeadingFont>About</SubHeadingFont>
            {about ? (<AboutSpan>{establishmentAbout}</AboutSpan>) : (<NoDataSpan>No Data Found</NoDataSpan>)}
          </AboutBox>
        </AboutWrapper>
      </>
    )
  }

  renderSubServicePart = () => {
    const isCommodityShow = [webConfigJSON.SERVICES_ENUM.DAY_CARE, webConfigJSON.SERVICES_ENUM.HOTELS].includes(this.state.getDetailsData?.data?.attributes?.service_type);
    return (
      <>
        <Box style={{ marginTop: "20px" }}>
          <Grid container spacing={4}>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
              {this.renderSubService()}
              {isCommodityShow && this.renderCommodities()}
              {this.renderComplementary()}
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
              {this.state.isDetailsLoading ? this.renderLoader() : this.renderBookingDates()}
              {this.renderCancellationPolicy()}
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }

  renderSubService = () => {
    const serviceType = this.serviceName()
    const tableData = this.state.getDetailsData?.data?.attributes?.sub_services_with_prices;
    return (
      <>
        <CustomHR style={{ marginBottom: 30 }} />
        {!tableData && !serviceType && this.state.isDetailsLoading ?
          this.renderLoader()
          : <></>
        }
        {tableData && tableData.length > 0 && serviceType && !this.state.isDetailsLoading ? (
          <ServiceBookingTableWeb navigation={undefined} id={""}
            data-testid="service-price-table"
            serviceType={serviceType}
            data={tableData}
            selectedSubService={this.state.selectedSubService}
            setSubService={this.setSubService}
          />
        ) : (<></>)
        }
      </>
    )
  }

  renderCommodities = () => {
    const commodities = this.getCommoditiesList();

    return (
      <>
        <CommoditiesWrapper>
          <SubHeadingFont>Commodities</SubHeadingFont>
          <CommoditiesList CommoditiesIcon={CommoditiesIcon} commoditiesArray={commodities}/>
        </CommoditiesWrapper>
      </>
    )
  }
  renderWarning = () => {
    return (
      <WarningBox>
        <img src={warningIcon} />
        <Typography style={style.warningFont}>
          {webConfigJSON.DEFAULT_MSG.TRANSPORTATION_WARNING}
        </Typography>
      </WarningBox>
    )
  }
  renderComplementary = () => {
    const complementaryServices = this.getComplementaryService();
    const { selectedTransportaion, selectedMedication, isDetailsLoading } = this.state
    const showComplementary = complementaryServices.length > 0 && !isDetailsLoading
    return (
      <>
        {showComplementary ? (
          <>
            <Box style={{ marginTop: "40px" }}>
              <CustomHR />
              <ComplementaryWrapper>
                <SubHeadingFont>Complementary services</SubHeadingFont>
                <CompServiceBoxWrapper>
                  {complementaryServices.map((compService, index) => {
                    const complementaryTitle = returnTruthyString(compService.title);
                    const complementaryPrice = returnTruthyString(compService.price.toFixed(2));
                    const complementaryChecked = checkCondition(compService.title === webConfigJSON.MEDICATION_SERVICE, selectedMedication.checked, selectedTransportaion.checked) as boolean
                    const complementaryType = checkCondition(compService.title === webConfigJSON.MEDICATION_SERVICE, "medication", "transportation") as "medication" | "transportation"
                    const complementaryImg = checkCondition(compService.title === webConfigJSON.MEDICATION_SERVICE, MedicationIcon, TransportationIcon) as string
                    return (
                      <CompServiceBox key={index}>
                        <CustomCheckbox
                          checkedIcon={<img src={checkedIcon} />}
                          icon={<img src={unCheckedIcon} />}
                          data-test-id="complementary-services"
                          checked={complementaryChecked}
                          onChange={(event) => this.setComplementaryService(event.target.checked, complementaryType, compService)}
                        />
                        <img src={complementaryImg} />
                        <Typography style={style.compServiceFont}>
                          {complementaryTitle}
                        </Typography>
                        <Typography style={style.compCurrencyFont}>
                          € {complementaryPrice}
                        </Typography>
                      </CompServiceBox>
                    );
                  })}
                </CompServiceBoxWrapper>

                {selectedTransportaion?.checked ? this.renderWarning() : <></>}
              </ComplementaryWrapper>
              <CustomHR />
            </Box>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }
  renderBookingDates = () => {
    const BookNowBtnImg = <img src={RightBtnArrowIcon} style={style.rightArrowImg} />
    return (
      <>
        <BookingWrapper id="book-now-section">
          {this.renderSelectPet()}
          {this.renderOtherFields()}
          <BookingButton
            isLoading={this.state.createBookingLoading}
            imageTag={BookNowBtnImg}
            buttonText="Book Now"
            onClick={this.onSave}
            type={"submit"}
            data-testid="booking-button">
          </BookingButton>
           
          <Typography style={style.notChargedFont}>{webConfigJSON.DEFAULT_MSG.NOT_CHARGED}</Typography>
          {this.renderBookingPrice()}
        </BookingWrapper>
      </>
    )
  }
  renderBookingPrice = () => {
    const { selectedSubService, selectedMedication, selectedTransportaion } = this.state

    const calculatedPrice = this.calculateTotalPrice()
    const noOfNights = checkCondition(Boolean(this.calculateHotelNights()), this.calculateHotelNights()+ " x ", "") as string
    const TotalPrice = checkCondition(calculatedPrice == 0, "___", "€ " + calculatedPrice.toFixed(2)) as string
    const subServicePrice =  checkCondition(Boolean(returnTruthyString(selectedSubService?.price)), "€ " + returnTruthyString(selectedSubService?.price.toFixed(2)), "___") as string
    const isComplementarySelected = selectedMedication.checked || selectedTransportaion.checked
    const showMediation = selectedMedication.checked && selectedMedication.service !== null
    const showTransportation = selectedTransportaion.checked && selectedTransportaion.service !== null
    const compService = (service: Price | null) => {
      return (
        <SpaceBetweenBox style={{ marginTop: "20px" }}>
          <Typography style={style.compServiceNameFont}>{service?.title}</Typography>
          <Typography style={style.compServicePriceFont}>€ {service?.price?.toFixed(2)}</Typography>
        </SpaceBetweenBox>
      )
    }
    return (
      <>
        <SpaceBetweenBox>
          <Typography style={style.serviceNameFont}>{this.serviceName()}</Typography>
          <Typography style={style.serviceNameFont}>{noOfNights}{subServicePrice}</Typography>
        </SpaceBetweenBox>

        {isComplementarySelected ?
          <Box style={style.compServiceSummary}>
            <Typography style={style.compServiceTitleFont}>Complementary services</Typography>
            {showMediation ? compService(selectedMedication.service) : <></>}
            {showTransportation ? compService(selectedTransportaion.service) : <></>}
          </Box>
          : <></>
        }

        <CustomHR style={style.marginHR} />
        <TotalPriceWrapper>
          <SpaceBetweenBox>
            <TotalFont>Total</TotalFont>
            <TotalPriceFont>{TotalPrice}</TotalPriceFont>
          </SpaceBetweenBox>
        </TotalPriceWrapper>
      </>
    )
  }
  renderOtherFields = () => {
    const activity_name = this.serviceName()
    switch (activity_name) {
      case webConfigJSON.SERVICES_ENUM.DAY_CARE:
      case webConfigJSON.SERVICES_ENUM.PET_SITTING:
        return this.DayCareSittingFields()
      case webConfigJSON.SERVICES_ENUM.DOG_WALKING:
        return this.DogWalkingFields()
      case webConfigJSON.SERVICES_ENUM.HOTELS:
        return this.HotelsFields()
      case webConfigJSON.SERVICES_ENUM.TRAINING:
        return this.TrainingFields()
    }
  }
  DayCareSittingFields = () => {
    const { isRepeatWeekly } = this.state

    return (
      <>
        <CustomFieldsWrapper >
          <CustomFieldsBox>{this.renderStartDate()}</CustomFieldsBox>
          <CustomFieldsBox>{isRepeatWeekly ? this.renderEndDate() : <></>}</CustomFieldsBox>
        </CustomFieldsWrapper>
        {this.renderRepeatDays()}
      </>
    )
  }
  DogWalkingFields = () => {
    const { isRepeatWeekly } = this.state
    return (
      <>
        <CustomFieldsWrapper>
          <CustomFieldsBox>{this.renderStartDate()}</CustomFieldsBox>
          <CustomFieldsBox>{this.renderTimeField()}</CustomFieldsBox>
        </CustomFieldsWrapper>
        {isRepeatWeekly ?
          <CustomFieldsWrapper>
            <CustomFieldsBox>{this.renderEndDate()}</CustomFieldsBox>
            <CustomFieldsBox></CustomFieldsBox>
          </CustomFieldsWrapper> : <></>
        }
        {this.renderRepeatDays()}
      </>
    )
  }
  HotelsFields = () => {
    return (
      <>
        <CustomFieldsWrapper style={{ marginBottom: 27 }}>
          <CustomFieldsBox>{this.renderStartDate()}</CustomFieldsBox>
          <CustomFieldsBox>{this.renderEndDate()}</CustomFieldsBox>
        </CustomFieldsWrapper>
      </>
    )
  }
  TrainingFields = () => {
    return (
      <>
        <Box style={style.startDatestyle}>
          {this.renderStartDate()}
        </Box>
      </>
    )
  }

  renderRepeatDays = () => {
    const { isRepeatWeekly, repeatDays, error } = this.state
    const disableRepeat = [webConfigJSON.SERVICES_ENUM.DAY_CARE, webConfigJSON.SERVICES_ENUM.DOG_WALKING].includes(this.serviceName())
    return (
      <>
        <RepeatDaysWrapper>
          <RepeatButton
            buttonText="Repeat Weekly"
            checked={isRepeatWeekly}
            checkedIcon={checkRepeat}
            unCheckedIcon={unCheckRepeat}
            onClick={this.handleRepeatBtn}
            data-testid="repeat-button"
            disabled={disableRepeat}
          />
          {isRepeatWeekly ?
            (<>
              <Box>
                <RepeatDayBox>
                  {repeatDays.map((eachDay, dayIndex) => {
                    return <RepeatDayButton key={dayIndex}
                      buttonText={eachDay.label}
                      checked={eachDay.checked}
                      onClick={() => this.setRepeatDay(dayIndex)}
                      data-testid={`day-button-${dayIndex}`}
                      disabled={this.getRepeatDayDisable(eachDay)}
                    />
                  })}
                </RepeatDayBox>
                <FormHelperText error>{error.repeatDays}</FormHelperText>
              </Box>
            </>
            ) : <></>}

        </RepeatDaysWrapper>
      </>
    )
  }

  renderSelectPet = () => {
    const { selectedPetId, error } = this.state
    const allowedPetList = this.getAllowedPetList()
    return (
      <Box>
        <InputBoxWrapper>
          <CustomLabel>Select Pet</CustomLabel>
          <CustomField
            fullWidth
            startAdornment={Adornment(petPawIcon)}
            MenuProps={selectMenuProps}
            IconComponent={CustomMenuIcon}
            variant="outlined"
            value={selectedPetId}
            onChange={(event) => this.setPetId(event.target.value as string | number)}
            data-testid="pet-input-field"
            displayEmpty
          >
            <MenuFont value={""} disabled>Select Pet</MenuFont>
            {allowedPetList.map((petItem, index) => {
              return (
                <MenuFont key={index} value={petItem.attributes.id}>
                  {returnTruthyString(petItem.attributes.name)}
                </MenuFont>
              )
            })}
          </CustomField>
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedPetId}</FormHelperText>
      </Box>
    )
  }

  renderStartDate = () => {
    const { selectedStartDate, error } = this.state
    return (
      <>
        <InputBoxWrapper>
          <CustomLabel>Start Date</CustomLabel>
          <DatePicker
            showIcon
            selectsStart
            withPortal
            toggleCalendarOnIconClick
            placeholderText="Select Start Date"
            dateFormat="EEE dd/MM/yyyy"
            minDate={new Date()}
            selected={selectedStartDate}
            onChange={(event) => this.setStartEndDate(event, "start")}
            icon={<img src={DateCalendar} alt="calendar-icon" />}
            data-testid='service-start-date'
          />
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedStartDate}</FormHelperText>
      </>
    )
  }
  renderEndDate = () => {
    const { selectedStartDate, selectedEndDate, error, isRepeatWeekly } = this.state
    const isRecurrenceServices = [
      webConfigJSON.SERVICES_ENUM.DAY_CARE,
      webConfigJSON.SERVICES_ENUM.DOG_WALKING,
      webConfigJSON.SERVICES_ENUM.PET_SITTING
    ].includes(this.serviceName()) || isRepeatWeekly

    const minEndDate = checkCondition(isRecurrenceServices, getDateAfterWeek(selectedStartDate), nextDayDate(selectedStartDate)) as Date | null

    return (
      <>
        <InputBoxWrapper>
          <CustomLabel>End Date</CustomLabel>
          <DatePicker
            showIcon
            startDate={selectedStartDate}
            selectsEnd
            withPortal
            toggleCalendarOnIconClick
            placeholderText="Select End Date"
            dateFormat="EEE dd/MM/yyyy"
            minDate={minEndDate}
            selected={selectedEndDate}
            onChange={(event) => this.setStartEndDate(event, "end")}
            icon={<img src={DateCalendar} alt="calendar-icon" />}
            data-testid='service-end-date'
          />
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedEndDate}</FormHelperText>
      </>
    )
  }
  renderTimeField = () => {
    const { selectedShiftTime, error } = this.state
    return (
      <>
        <InputBoxWrapper>
          <CustomLabel>Select Time</CustomLabel>
          <CustomField
            fullWidth
            startAdornment={Adornment(DateCalendar)}
            MenuProps={selectMenuProps}
            IconComponent={CustomMenuIcon}
            variant="outlined"
            displayEmpty
            value={selectedShiftTime}
            onChange={(event) => this.setShiftTime(event.target.value as string)}
            data-testid='time-shift'
          >
            <MenuFont value={""} disabled>Select Time</MenuFont>
            <MenuFont value={"Morning"}>Morning</MenuFont>
            <MenuFont value={"Evening"}>Evening</MenuFont>
          </CustomField>
        </InputBoxWrapper>
        <FormHelperText error>{error.selectedShiftTime}</FormHelperText>
      </>
    )
  }

  renderCancellationPolicy = () => {
    const policyText = returnTruthyString(this.state.getDetailsData?.data?.attributes?.cancellation_policy);
    const { isDetailsLoading } = this.state
    const title = checkCondition(isDetailsLoading,"","Cancellation policy") as string;
    return (
      <>
        <PolicyWrapper>
          <SubHeadingFont>{title}</SubHeadingFont>
          <CancellationPolicy loading={isDetailsLoading} policyText={policyText}/>
        </PolicyWrapper>
      </>
    );
  }
  //ViewMap
  renderMapViewModal = (item: any) => {
    return (
      <>
          <Modal
            open={this.state.open}
            onClose={() => this.handleCloseModal('backdropClick')}
            aria-labelledby="modal-modal-title"
            data-test-id="handleModal"
            aria-describedby="modal-modal-description"
            hideBackdrop={false}
          >
            <ModalPageWrapper maxWidth="xl" data-test-id="advanced-search-wrapper">
              <ContainerBoxMap style={{ display: 'flex',width:'115%' }}>
                <Column className="map-wrapper2" style={{ flex: '1', position: 'relative', height: '100vh', marginTop: -35, marginLeft: -65,width:' 100%' }}>
                  {this.state.open &&(
                  <GoogleMap
                  data-test-id='googleid'
                    mapContainerStyle={containerStyle}
                    center={{ lat: this.state.mapCenter.lat, lng: this.state.mapCenter.lng }}
                    zoom={14}
                    onLoad={this.onMapLoad}
                    options={{
                      draggable: true, // Enable dragging
                      zoomControl: true, // Enable zoom controls
                      disableDefaultUI: true, // Disable default UI components
                      scrollwheel: true,
                      streetViewControl: true
                    }}
                    onClick={() => this.handleCloseInfoWindow()} // Close InfoWindow on map click
                  >
                    <Marker
                     data-test-id='markerid'
                      position={{
                        lat: this.state.mapCenter.lat || 0,
                        lng: this.state.mapCenter.lng || 0,
                      }}
                      onClick={() => this.handleMarkerClick(this.state.mapCenter)}
                      icon={{
                        url: selectedmarkerpin, // URL of your marker icon
                        scaledSize: new window.google.maps.Size(81, 81) // Adjust size as needed
                      }}
                    
                      
                    />
                    {this.state.shouldRecenterinfo && (
                     <InfoWindow
                     data-test-id='infowindowid'
                       position={{
                         lat: this.state.mapCenter.lat,  // Use marker's latitude
                         lng: this.state.mapCenter.lng  // Use marker's longitude
                       }}
                       onCloseClick={() => this.handleCloseInfoWindow}
                       options={{ disableAutoPan: true, pixelOffset: new window.google.maps.Size(0, -40), }}
                     >
                       <InfoWindowWrapper>
                         <Box className='establishmentimage' style={{ maxWidth: '100%', backgroundImage: `url(${item?.establishment_photo})` }} >
                         </Box>
                         <Box className='esta-info'>
                           <Box >
                             <Typography className='title'>{item?.establishment_name}</Typography>
                             <Box className='content-line'>
                               <img src={MarkerSmall} />
                               <Typography className='address-style2'>{item?.address}</Typography>
                             </Box>
                             <Box className='price-list'>
                               <Typography className='pricetag'>from {this.state.lowestPrice}€</Typography><Typography className='main-price'>/night</Typography>
                             </Box>
                           </Box>
                           <Box className='price-block2'>
                             <Button data-test-id='searchbtn' onClick={() => this.handleCloseModal('')} className='offer-btn'>Book</Button>
                           </Box>
                         </Box>
 
                       </InfoWindowWrapper>
                     </InfoWindow>
                    )}
                   
                  </GoogleMap>
)}

                </Column>
                <Box onClick={() => { this.handleCloseModal('') }} data-test-id="closemap" className="crossButton-box"><img src={crossButton} /></Box>
              </ContainerBoxMap>
            </ModalPageWrapper>
          </Modal>
      </>
    )
  }
  renderLocation = () => {
    const { isDetailsLoading } = this.state
    const establishmentInfo = this.state.getDetailsData?.data?.attributes?.establishment;
    const latitude = returnTruthyString(establishmentInfo?.latitude)
    const longitude = returnTruthyString(establishmentInfo?.longitude)

    const center = {
      lat: Number(latitude),
      lng: Number(longitude),
    };
    const markers = {
      position: center,
    };
   
    return (
      <>
        {!isDetailsLoading ?
          <LocationWrapper>
            <SubHeadingFont>Location</SubHeadingFont>
            {latitude && longitude ?
              <MapWrapper>
                <BookingWebMap center={center} markers={markers}></BookingWebMap>
                <ViewMapBtn data-test-id='handleopenbtn' onClick={()=>{this.handleOpen()}}>
                    View Map
                </ViewMapBtn>
                {this.state.open &&
                    this.renderMapViewModal(this.state.getDetailsData?.data?.attributes?.establishment)
                  }
              </MapWrapper>
              : <></>}
          </LocationWrapper>
          : <></>}
      </>
    )
  }

  renderLoader = () => {
    return (
      <LoaderContainer>
        <CustomLoader />
      </LoaderContainer>
    )
  }

  renderSimpleDialog = () => {
    const { openSimpleModal, messageSimpleModal } = this.state
    return (
      <>
        {openSimpleModal &&
          <SimpleDialog
            data-testid="simple-dialog"
            open={openSimpleModal}
            message={messageSimpleModal}
            showRightBtn
            rightBtnTitle="Okay"
            handleRightClick={this.simpleModalClose}
          />
        }
      </>
    )
  }

  capacityFullModal = () => {
    const image = <img src={capacityCalendar} alt="img" />
    const { openCapacityModal } = this.state
    const establishmentInfo = this.state.getDetailsData?.data?.attributes?.establishment;
    const establishmentName = returnTruthyString(capitalizeFirstLetter(establishmentInfo?.establishment_name));
    const modalTitle = `Looks like ${establishmentName} is booked up.`
    return (
      <>
        <DialogWithImage
          data-testid="capacity-full-dialog"
          open={openCapacityModal}
          imageTag={image}
          title={modalTitle}
          subTitle={webConfigJSON.DEFAULT_MSG.CAPACITY_SUBTITLE}
          btnText="Select different Time"
          handleClose={this.handleCloseCapacityModal}
          handleBtnClick={this.handleCloseCapacityModal}
        />
      </>
    )
  }

  renderServiceImageGallery = () => {
    const attr = this.state.getDetailsData?.data?.attributes;
    const combinedGalleries = [
      ...(attr?.service_galleries_urls || []).filter(gallery => gallery.file_name !== "no_image.png"),
      ...(attr?.establishment_galleries_urls || []).filter(gallery => gallery.file_name !== "no_image.png").map(gallery => ({
        image_url: gallery.file_url || ""
      })),
    ];
   
    return(
      <ServiceGalleryWeb handelCloseClick={() => this.handleCloseViewMore()} galleryData={combinedGalleries} isSubService={false} data-test-id="service-gallery"/>
    )
  }

  renderCheckoutPage = () => {
    const {
      selectedStartDate, selectedEndDate, selectedPetId,
      selectedSubService, selectedTransportaion, selectedMedication, petList,
      createBookingResponse
    } = this.state
    const customerPet = petList.find((petItem) => String(petItem.attributes.id) == selectedPetId)

    const establishmentData = this.state.getDetailsData?.data?.attributes?.establishment;
    const establishmentTitle = returnTruthyString(capitalizeFirstLetter(establishmentData?.establishment_name));

    // COMPLEMENTARY
    const transportation = selectedTransportaion.checked && selectedTransportaion.service !== null
    const medication = selectedMedication.checked && selectedMedication.service !== null
    const show_complementary = transportation || medication
    const is_repeat_weekly = Boolean(this.state.isRepeatWeekly && selectedEndDate)
    const selected_repeat_days = this.selectedRepeatDays()

    const BookingData = {
      pet_details: customerPet,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      service_name: this.serviceName(),
      sub_service: selectedSubService,
      show_complementary: show_complementary,
      medication: medication ? selectedMedication : null,
      transportation: transportation ? selectedTransportaion : null,
      total_price: this.calculateTotalPrice(),
      hotel_nights: this.calculateHotelNights(),
      establishment_name:  establishmentTitle,
      is_repeat_weekly: is_repeat_weekly,
      selected_repeat_days: selected_repeat_days,
      createBookingResponse: createBookingResponse,      
    }
    return (
      <>
        {
          <BookingCheckout navigation={this.props.navigation}
            handleCloseCheckoutPage={this.checkoutPageClose}
            BookingData={BookingData} 
          />
        }
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const isRecurrenceShow = [1,3,4].includes(this.state.tabValue);
    return (
      <>
        <ThemeProvider theme={theme}>
          <AppHeader navigation={this.props.navigation} />
          {this.state.showCheckoutPage ? this.renderCheckoutPage() : 
          <>
          <LandingPageHeader
            hideTitle
            tabValue={this.state.tabValue}
            onTabChange={this.handleTabChange}
            SearchDataFromHeader={(data) => this.goToAdvancedSearchPage(data)}
            isRepeatShow={isRecurrenceShow}
            data-test-id="landing-booking-page-header"
            petList={modifiedPetList(this.state.petList)}
            propData={this.state.headerData}
          />

          <MainWrapper>
            {this.state.isDetailsLoading ? this.renderLoader() : (<></>)}
            {!this.state.isDetailsLoading ? this.renderServiceHeader() : (<></>)}
            {this.renderSubServicePart()}
            {this.renderLocation()}
            {this.renderSimpleDialog()}
            {this.capacityFullModal()}
            {this.state.isGalleryOpen ? this.renderServiceImageGallery() : (<></>)}
          </MainWrapper>
          </>}
          <AppFooter navigation={this.props.navigation} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  marginTop: "150px",
  padding: "0 150px",
  "@media (max-width: 1600px)": {
    padding: "0 80px",
  },
  "@media (max-width: 960px)": {
    marginTop: "100px",
    padding: "0 40px",
  },
  "@media (max-width: 600px)": {
    padding: "0 32px",
  },
  // GLOBAL CALENDAR
  "& .react-datepicker__input-container": {
    marginTop: "2px",
    opacity: "1 !important",
    position: "unset",
    "& img": {
      width: "24px",
      height: "24px",
      padding: "0px 12px 4px 0px",
      cursor: "pointer !important",
    }
  },
  "& .react-datepicker__input-container input": {
    width: "100% ",
    height: "auto !important",
    padding: "0px 10px 0px 38px !important",
    border: "none !important",
    background: "transparent !important",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#0F172A",
    cursor: "pointer !important",
    "@media (max-width: 400px)": {
      fontSize: "14px",
    },
    "&::placeholder": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#0F172A",
      opacity: 1,
      "@media (max-width: 400px)": {
      fontSize: "14px",
    },
    },
    '&:focus': {
      border: "none !important",
      outline: "none !important",
    },
  },
})

const ServiceTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "36px",
  color: "#000000",
  fontWeight: 600,
  marginBottom: "30px",
  "@media (max-width: 1280px)": {
    fontSize: "32px",
  },
  "@media (max-width: 960px)": {
    fontSize: "28px",
    marginBottom: "24px",
  },
  "@media (max-width: 600px)": {
    fontSize: "24px",
    marginBottom: "16px",
  },
  "@media (max-width: 400px)": {
    fontSize: "20px",
  },
})
const AddressBox = styled(Box)({
  display: "flex"
})
const AddressFont = styled(Typography)({
  userSelect: "none",
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "24px",
  color: "#909090",
  "@media (max-width: 960px)": {
    fontSize: "16px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
  },
})

const SubHeadingFont = styled(Typography)({
  userSelect: "none",
  fontFamily: "Montserrat",
  fontSize: "26px",
  fontWeight: 600,
  lineHeight: "27px",
  color: "#000000",
  "@media (max-width: 1280px)": {
    fontSize: "24px",
  },
  "@media (max-width: 600px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "20px",
  },
})
const GalleryWrapper = styled(Box)({
  marginTop: "30px",
  borderRadius: "20px",
  maxHeight: '626px',
  overflow: 'hidden',
});

const GalleryGrid = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '8px',
});

const LeftImageBox = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxHeight: "623px",
  "@media (max-width: 1280px)": {
    maxHeight: "523px",
  },
  "@media (max-width: 960px)": {
    maxHeight: "423px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "323px",
  },
});

const RightImageBox = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const ImageBoxWrapper = styled(Box)({
  display: "flex",
  justifyContent: 'space-between',
  gap: '8px',
  height: "307px",
  "@media (max-width: 1280px)": {
    maxHeight: "257px",
  },
  "@media (max-width: 960px)": {
    maxHeight: "207px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "157px",
  },
});

const ImageBox = styled(Box)({
  flex: '1 1 calc(50% - 4px)',
  maxHeight: '307px',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  "@media (max-width: 1280px)": {
    maxHeight: "257px",
  },
  "@media (max-width: 960px)": {
    maxHeight: "207px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "157px",
  },
});

const AboutWrapper = styled(Box)({
  marginTop: "50px",
  display: "flex",
  flexDirection: "row",
  columnGap: "20px",
  rowGap: "32px",
  "@media (max-width: 959px)": {
    flexDirection: "column",
  },
  "@media (max-width: 600px)": {
    rowGap: "24px",
  },
})

const EstablishmentBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "30px",
  minWidth: "268px",
  maxWidth: "400px",
  "@media (max-width: 600px)": {
    columnGap: "24px",
  },
})
const EstablishmentAvatar = styled(Avatar)({
  height: "93px",
  width: "93px",
  fontFamily: "Montserrat",
  fontWeight: 700,
  backgroundColor: "#A6A6A6",
  "@media (max-width: 400px)": {
    height: "60px",
    width: "60px",
  },
})
const EstablishmentFont = styled(Typography)({
  userSelect: "none",
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#000000",
  "@media (max-width: 600px)": {
    fontSize: "18px",
  },
  "@media (max-width: 400px)": {
    fontSize: "16px",
  },
})
const AboutBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
})
const AboutSpan = styled(`span`)({
  userSelect: "none",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "27px",
  color: "#000000",
  whiteSpace: "pre-wrap",
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
})
const CommoditiesWrapper = styled(Box)({
  marginTop: "40px",
})


const ComplementaryWrapper = styled(Box)({
  padding: "40px 0px",
})
const CustomHR = styled(Divider)({
  color: "#B7B7B7"
})
const CompServiceBoxWrapper = styled(Box)({
  marginTop: "37px",
  marginBottom: "37px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "37px"
})
const CompServiceBox = styled(Box)({
  position: "relative",
  padding: "24px 13px 16px 13px",
  width: "100%",
  maxWidth: "180px",
  borderRadius: "8px",
  boxShadow: "0px 0px 7.5px 0px #0000001A",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
})
const CustomCheckbox = styled(Checkbox)({
  position: "absolute",
  top: "3px",
  right: "3px"
})
const WarningBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "8px"
})

const InputBoxWrapper = styled(Box)({
  borderRadius: "8px",
  border: "0.5px solid #CBD5E1",
  padding: "14px 20px",
  "@media (max-width: 400px)": {
    padding: "12px",
  }
})
const CustomLabel = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#A6A6A6",
  "@media (max-width: 400px)": {
    fontSize: "12px",
  },
})
const CustomMenuIcon = styled(KeyboardArrowDown)({
  color: '#0F172A',
});
const CustomField = styled(Select)({
  paddingLeft: 0,
  marginTop: 2,
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none !important",
  },
  "& .MuiInputBase-root": {
    outline: "none !important",
    border: "none !important",
  },
  "& .MuiSelect-select": {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    paddingLeft: "12px",
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'Montserrat',
    fontSize: "16px",
    fontWeight: 500,
    color: "#0F172A",
    "@media (max-width: 400px)": {
      fontSize: "14px",
    },
  },
  "& .MuiSelect-icon": {
    top: 'calc(50% - 12px)',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    outline: "none"
  },
  "& .MuiInputBase-input": {
    border: "none",
    outline: "none",
    height: 8,
    "&::placeholder": {
      color: "#0F172A",
      opacity: 1,
      fontFamily: 'Montserrat',
      fontSize: "20px",
      fontWeight: 500,
    },
  },
})
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400
})
const BookingWrapper = styled(Box)({
  marginTop: "40px",
  padding: "32px",
  boxShadow: "0px 0px 8px 0px #0000001A",
  borderRadius: "12px",
  backgroundColor: "#fff",
  "@media (max-width: 1279px)": {
    marginTop: "0px",
  },
  "@media (max-width: 600px)": {
    padding: "24px",
  },
  "@media (max-width: 400px)": {
    padding: "12px",
  }
})

const CustomFieldsWrapper = styled(Box)({
  marginTop: "27px",
  display: "flex",
  flexDirection: "row",
  gap: "27px",
  flexWrap: "nowrap",
  "@media (min-width: 1280px) and (max-width: 1515px) ": {
    flexWrap: "wrap"
  },
  "@media (max-width: 660px) ": {
    flexWrap: "wrap"
  },
})
const CustomFieldsBox = styled(Box)({
  width: "50%",
  "@media (min-width: 960px) and (max-width: 1515px) ": {
    width: "100%",
  },
  "@media (max-width: 660px) ": {
    width: "100%",
  },
})

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
})
const TotalPriceWrapper = styled(Box)({
  marginTop: "20px"
})
const TotalFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 600,
  color: "#000000"
})
const TotalPriceFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "36px",
  fontWeight: 700,
  color: "#6F05EA"
})
const RepeatDaysWrapper = styled(Box)({
  margin: "27px 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "12px",
  minHeight: '49px'
})
const RepeatDayBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  flexWrap: "wrap"
})


const PolicyWrapper = styled(Box)({
  marginTop: "40px",
  padding: "0px 24px",
  "@media (max-width: 1279px)": {
    padding: "0px",
  }
})


const LocationWrapper = styled(Box)({
  marginTop: "40px",
})
const MapWrapper = styled(Box)({
  position: "relative",
  marginTop: "27px",
  marginBottom: "8px",
  width: "100%",
  maxWidth: "692px",
  boxShadow: "0px 0px 4.7px 0px #0000001A",
  borderRadius: "12px"
})
const ViewMapBtn = styled(Button)({
  position: "absolute",
  right: 18,
  top: 18,
  padding: "15px 0px",
  width: "119px",
  lineHeight: "26px",
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "#fff",
  boxShadow: "none",
  borderRadius: "8px",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  "@media (max-width: 960px)": {
    fontSize: "16px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    width: "100px",
  },
})

const style: Record<string, React.CSSProperties> = {
  locationImg: {
    margin: "0 13px 0 9px"
  },
  compServiceFont: {
    marginTop: "12px",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#000000",
    textTransform: "capitalize"
  },
  compCurrencyFont: {
    marginTop: "8px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    color: "#000000"
  },
  warningFont: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#F87171"
  },
  subImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    userSelect: "none"
  },
  notChargedFont: {
    margin: "16px 0",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
    color: "#777777"
  },
  serviceNameFont: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 400,
    color: "#000000"
  },
  compServiceSummary: {
    marginTop: "20px"
  },
  compServiceTitleFont: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    lineHeight: "14px"
  },
  compServiceNameFont: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "26px",
    textTransform: "uppercase"
  },
  compServicePriceFont: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "24px",
  },
  marginHR: {
    margin: "20px 0"
  },
  rightArrowImg: {
    marginLeft: "32px"
  },
  startDatestyle: {
    margin: "27px 0"
  }
}

const NoDataSpan = styled(`span`)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "27px",
  color: "#A6A6A6",
  whiteSpace: "pre-wrap",
})

const CustomLoader = styled(CircularProgress)({
  color: "#6F05EA"
});
const LoaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginTop: "50px",
  marginBottom: "50px"
});
const ImageBoxButtonWrapper = styled(Box)({
  display: "flex",
  position:"relative",
  justifyContent: 'space-between',
  gap: '8px',
  height: "307px",
  "@media (max-width: 1280px)": {
    maxHeight: "257px",
  },
  "@media (max-width: 960px)": {
    maxHeight: "207px",
  },
  "@media (max-width: 600px)": {
    maxHeight: "157px",
  },
});
const ViewMoreBtn = styled(Button)({
  position:"absolute",
  bottom:"20px",
  right:"16px",
  borderRadius:"36px",
  border:"1px solid #6F05EA",
  backgroundColor:"#ECEBEBB2",
  color:"#6200EA",
  fontSize:"16px",
  fontWeight:600,
  textTransform:"none",
  width:"159px",
  height:"43px",
  "&:hover":{
    backgroundColor:"#ECEBEBB2"
  }
})
//ViewMap
const ContainerBoxMap = styled("div")({
  width:'103%',
  gap:'10px',
  flexWrap: "wrap",

});
const Column = styled("div")(({ theme }) => ({
  flex: "1 1 33%", 
  boxSizing: "border-box",
  padding: theme.spacing(2),
  [theme.breakpoints.down(960)]: {
    flex: "1 1 100%", 
  },
}));
const ModalPageWrapper = styled(Container)(({ theme }) => ({

  maxHeight: ' 90%',
  display: 'flex',
  width: '85%',
  overflow: 'hidden',
  flexDirection: "column",
  background: '#F6F4F9',
  left: '5%',
  top: '5%',
  marginTop: 54,
  borderRadius: 4,
  alignItems: 'flex-start',
  [theme.breakpoints.up(962)]: {
    width: "calc(100% - 300px)",
  },
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 532px)",
  },
  "& *": {
    boxSizing: "border-box",
  },

  [theme.breakpoints.down("xs")]: {
    width: "calc(100% - 40px)",
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },

  "& .filterslist": {
    width: "100%",
    background: "#F6F4F9",
    maxWidth: '315px',
    display: "flex",
    borderRadius: "8px",
    maxHeight: 'auto',
    marginTop: 30,
    boxShadow: '0px 4px 8px 0px #00000008',
    minHeight: '300px',
    overflowY: 'auto'
  },
  "& .map-wrapper2": {
    width: '100%',
    position: "relative",

  },
  "& .content": {
    width: '103%',
    height: '100%',
    background: '#F6F4F9',
    alignItems: 'flex-start',
    flexDirection: "column",
    overflow: 'scroll',
    borderRadius: 4,
  },
  "& .crossButton-box": {
    position: 'absolute',
    height: '44px',
    width: '44px',
    zIndex: 1,
    marginTop: '34px',
    marginRight: 190,
    marginLeft: 563,
    right: 0,
    [theme.breakpoints.up(1040)]: {
      marginTop: -26,
      marginRight: 190
    },
    [theme.breakpoints.up(1900)]: {
      marginRight: 137,
    },
    [theme.breakpoints.down(1040)]: {
      visibility: 'collapse'
    },
    [theme.breakpoints.up(1240)]: {
      marginRight: 158,
      marginTop: -23
    },

  },

}));
const InfoWindowWrapper = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 9.5px -1px rgba(0, 0, 0, 0.25)",
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '270px',
  borderRadius: 25,
  height: '306px',
  "& .establishmentimage": {
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '144px',
    backgroundSize: 'cover',
    overflow: 'hidden',
    backgroundPosition: 'center',
  },
  "& .pricetag": {
    fontWeight: 800,
    color: '#6F05EA',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat',
    marginLeft: 5

  },

  "& .esta-info": {
    marginLeft: 10,
    flexDirection: 'column',
    display: 'flex',
    height: 'auto',
    marginTop: 10
  },
  "& .content-line": {
    display: 'flex',
    gap: '12px',
    fontSize: '12px',
    color: '#000',
    alignItems: 'flex-start',
    fontWeight: 300,
    fontFamily: 'Montserrat',
    marginTop: 5

  },
  "& .address-style2": {
    fontSize: '12px',
    fontWeight: 300,
    color: '#000',
    fontFamily: 'Montserrat'
  },
  "& .title": {
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    fontSize: '18px',
    marginLeft: 3

  },
  "& .warning-text": {
    color: '#FFCE00',
    fontSize: '14px',
    fontWeight: 600
  },

  "& .price-block2": {
    marginTop: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '30px',

    alignItems: 'center',

  },
  "& .fees-text": {
    marginTop: '8px',
    marginBottom: '20px',
    color: '#777777',
    fontSize: '12px',
    fontWeight: 500
  },
  "& .link": {
    color: '#909090',
    fontSize: '12px',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  "& .price-section": {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(1600)]: {
      alignItems: 'end',
    },
  },
  "& .offer-btn": {
    color: '#fff',
    textTransform: 'none',
    backround: '#6F05EA',
    padding: '10px 36px',
    borderRadius: '35px',
    fontSize: '18px',
    backgroundColor: '#6F05EA',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    letterSpacing: 0,
    border: '1px solid #6F05EA',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    width: '160px',
    height: '32px'
  },
  "& .price-text": {
    color: '#777777',
    fontWeight: 500,
    marginBottom: '21px',
    fontSize: '14px',

  },
  "& .main-price": {
    color: '#6F05EA',
    fontSize: '16px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat'
  },
  "& .no-tax-price": {
    color: '#777777',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },

  "& .price-list": {
    display: 'flex',
    alignItems: 'center',
  },

}))
const containerStyle = {
  width: '100%',
  height: '1083px',
};
// Customizable Area End
