import React, { useState } from 'react';
import CustomerCalendar from './CustomerCalendar';
import { Box, Typography, styled } from '@material-ui/core';
import ApointmentDetails from './ApointmentDetails';
import ApointmentsList from './ApointmentsList';
import { Views } from 'react-big-calendar';
import { Shedule } from '../CustomisableUserProfilesController';

type Keys = keyof typeof Views;

type Props = {
    isLoading: boolean;
    calendarView: (typeof Views)[Keys];
    calendarDate: Date;
    changeView: (view: (typeof Views)[Keys]) => void;
    changeDate: (date: Date) => void;
    selectedEvent: Shedule | null;
    shedules: Shedule[];
    fetchAppointments: (view: (typeof Views)[Keys], date: Date) => void;
    toggleCancelation: () => void;
    toggleRequestChange: () => void;
    selectEvent: (event: Shedule | null) => void;
    changeRequestStatus: (type: 'rejected' | 'accepted') => void;
}

const CalendarTabWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '35px',

    '& .calendar-section': {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },

    "& .calendar-header": {
        fontSize: '32px',
        color: '#000',
        fontWeight: 600,
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            fontSize: '40px',
        }
    }
}))

const CalendarTab = ({ 
    isLoading,
    shedules, 
    selectEvent, 
    selectedEvent, 
    fetchAppointments, 
    toggleCancelation, 
    changeRequestStatus, 
    toggleRequestChange,
    calendarDate,
    calendarView,
    changeDate,
    changeView 
}: Props) => {

    const handleSelectEvent = (event: Shedule | null) => {
        selectEvent(event);
    }

    return (
        <CalendarTabWrapper>
            {selectedEvent 
                ? 
                <ApointmentDetails 
                    isLoading={isLoading}
                    selectedEvent={selectedEvent} 
                    onBackClick={handleSelectEvent} 
                    toggleCancelation={toggleCancelation}
                    toggleRequestChange={toggleRequestChange} 
                    changeRequestStatus={changeRequestStatus} 
                /> 
                : 
                <>
                    <Box className='calendar-section'>
                        <Typography className='calendar-header'>Calendar</Typography>
                        <CustomerCalendar
                            calendarDate={calendarDate}
                            calendarView={calendarView}
                            changeDate={changeDate}
                            changeView={changeView} 
                            events={shedules} 
                            onEventSelect={handleSelectEvent} 
                            fetchAppointments={fetchAppointments} 
                        />
                    </Box>
                    <ApointmentsList appointments={shedules} onEventSelect={handleSelectEvent} />
                </>
            }
            
        </CalendarTabWrapper>
    )
}

export default CalendarTab;