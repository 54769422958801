import React from 'react';
import GenericModal from '../../../../../components/src/GenericModal';
import { Avatar, Box, Button, CircularProgress, Typography, styled } from '@material-ui/core';
import { ServiceList, SubAvailability } from '../../types';
import { LoadImage } from '../../assets';
import DisplayField from './DisplayField';
import moment from 'moment';
import useBreakpoints from '../../../../../components/src/hooks/useBreakpoints';


type IProps = {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    serviceType: ServiceList;
    data: SubAvailability[] | undefined;
    isLoading: boolean;
    apiError: string;
    downloadCSV: (sId: string, date: string) => void;
}

const BookingDetailsModal = (props: IProps) => {
    const { isOpen, onClose, data, isLoading, apiError, downloadCSV } = props;
    const { less600 } = useBreakpoints();

    const details = data && data[0];
    const isFull = details && (details.no_of_booking >= details.capacity);

    const handleDownload = () => {
        if(details) {
            downloadCSV(`${details.sub_service_id}`, moment(details.date).format('DD-MM-YYYY'));
        }
    }

    return(
        <GenericModal
            id='booking-details' 
            isOpen={isOpen} 
            title={''} 
            onClose={onClose}
            bodyStyle={{ padding: less600 ? '24px 24px' : '26px 60px', width: '100%', maxWidth: '580px', }} 
            disableOutside={true}
        >
            <ModalWrapper>
                <Box className='header-wrapper'>
                    <Typography className='header-text'>Booking info</Typography>
                    {details && 
                        <Button onClick={handleDownload} startIcon={<img src={LoadImage}/>} variant='outlined' className='download-button'>Download in CSV</Button>
                    }
                </Box>
                {isLoading && 
                    <Box className='loader'>
                        <CircularProgress style={{ color: '#6F05EA'}} />
                    </Box> 
                }
                {apiError && <Typography className='header-item'>{apiError}</Typography>}
                {details && 
                    <>
                        <Box className='row'>
                            <DisplayField label='Capacity' width={124}>
                                <Typography style={{ fontSize: '11px', color: '#000'}}>{details.capacity}</Typography>
                            </DisplayField>
                            <DisplayField label='Price' width={124}>
                                <Typography style={{ fontSize: '12px', color: '#000', fontWeight: 500 }}>{`${details.price} €`}</Typography>
                            </DisplayField>
                        </Box>
                        <Box className='row'>
                            <DisplayField label='Number of Bookings' width={360}>
                                <Typography style={{ fontSize: '11px', color: '#7D7D7D'}}>
                                    {`${details.no_of_booking} `}
                                    <span style={{ fontSize: '11px', color: isFull ? '#F87171' : '#059669'}}>
                                        {isFull ? '(Fully booked)' : '(Not Fully booked)'}
                                    </span>
                                </Typography>
                            </DisplayField>
                            <DisplayField label='Date' width={124}>
                                <Typography style={{ fontSize: '11px', color: '#000'}}>{moment(details.date).format('DD/MM/YY')}</Typography>
                            </DisplayField>
                        </Box>
                        <Box className='people-list-wrapper'>
                            <Typography className='label'>Booked people</Typography>
                            <Box className='people-list'>
                                {details.users.map((person) => (
                                    <Box key={person.id} className='person-box'>
                                        <Avatar src={person.image_url?.url} style={{ border: '1px solid #6F05EA', width: '32px', height: '32px', minWidth: '32px' }} />
                                        <Typography className='table-text' style={{ flex: 1.2 }}>{person.name}</Typography>
                                        <Typography className='table-text' style={{ flex: 1.2 }}>{person.phone_number}</Typography>
                                        <Typography className='table-text' style={{ flex: 1.8 }}>{person.email}</Typography>
                                        <Typography className='table-text' style={{ flex: 1.8 }}>{person.address}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </>
                
                }
                
            </ModalWrapper>
        </GenericModal>
    )
}

const ModalWrapper = styled('div')(({ theme }) => ({
    maxWidth: '700px',
    minHeight: '30vh',
    "& .loader": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '20vh'
    },
    "& .table-text": {
        fontSize: '12px',
        fontWeight: 400,
        color: '#000'
    },
    "& .header-item": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 500,
        flex: 1,
        width: '100%'
    },
    "& .person-box": {
        display: 'flex', gap: '14px', padding: '4px 8px 8px 8px',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 2px 0px rgba(0,0,0,0.24)'
    },
    "& .row": {display: 'flex', gap: '60px', marginTop: '24px'},
    "& .people-list-wrapper": { display: 'flex', flexDirection: 'column', gap: '28px', marginTop: '36px'},
    "& .people-list": {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        maxHeight: '152px',
        overflowY: 'auto',
        paddingTop: '2px',
        paddingLeft: '1px',
        paddingRight: '12px'
    },
    "& .label": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 400,
    },
    "& .header-wrapper": {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'row',
            alignItems: 'center',

          },
    },

    "& .header-text": {
        color: '#6F05EA',
        fontSize: '32px',
        fontWeight: 700
    },

    "& .download-button": {
        textTransform: 'none',
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 500,
        borderRadius: '24px',
        borderColor: '#6F05EA',
        padding: '10px 16px',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)'
    }
}))


export default BookingDetailsModal;