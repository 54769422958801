import React from 'react';
import { Box, Typography } from "@material-ui/core"
import { Request, Shedule } from "../CustomisableUserProfilesController";
import { Portrait } from '../assets';
import Field from "./Field";
import moment from "moment";

type Props = {
    request: Request | null;
    selectedEvent: Shedule;
    fieldList: Record<any, boolean>;
}

const getIsFromCustomer = (request: Request | null) => {
   return request?.created_by === 'customer'
}

const DetailsApointmentFields = ({ request, selectedEvent, fieldList }: Props) => {
    const fromCustomer = getIsFromCustomer(request);

    return (
        <Box className='section-wrapper'>
            <Box className='section-header'>
                <img src={Portrait} />
                <Typography className='section-text'>Appointment Details</Typography>
            </Box>
            <Box className='fields-wrapper'>
                <Box className='field-set'>
                    {request && fromCustomer ? 
                        <>
                            <Field data-test-id="date" title={fieldList.endDate ? 'Old Start Date' : 'Old Date'} value={moment(selectedEvent.start_date).format('DD/MM/YYYY')} />
                            {fieldList.endDate && <Field data-test-id="end-date" title='Old End Date' value={moment(selectedEvent.end_date).format('DD/MM/YYYY')} />}
                            {fieldList.time && <Field data-test-id="time" title='Old Time' value={selectedEvent.shift} />}
                            <Field title='Price' value={`${selectedEvent.total_price}€`}/>
                        </> :
                        <>
                            <Field title={fieldList.endDate ? 'Start Date' : 'Date'} value={moment(selectedEvent.start_date).format('DD/MM/YYYY')}/>
                            <Field title='Price' value={`${selectedEvent.total_price}€`}/>
                        </>
                    }
                </Box>
                <Box className='field-set'>
                    {request && fromCustomer ? 
                        <>
                            <Field title={fieldList.endDate ? 'Updated Start Date' : 'Updated Date'} value={moment(request.start_date).format("DD/MM/YYYY")} />
                            {fieldList.endDate && <Field title='Updated End Date' value={moment(request.end_date).format('DD/MM/YYYY')} />}
                            {fieldList.time && <Field title='Updated Time' value={request.shift} />}
                            <Field title='Service Type' value={selectedEvent.service.service_type}/>
                        </> : 
                        <>
                            {fieldList.endDate && <Field title="End Date" value={moment(selectedEvent.end_date).format('DD/MM/YYYY')} />}
                            {fieldList.time && <Field title="Time" value={selectedEvent.shift} />}
                            <Field title='Service Type' value={selectedEvent.service.service_type}/>
                        </>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default DetailsApointmentFields;