import React from "react";
// Customizable Area Start
import {
  Box, styled, IconButton, Typography, Button, Checkbox, Select, FormControlLabel, MenuItem,
  Grid, FormLabel, TextField, InputAdornment, Dialog,
} from "@material-ui/core";
import ViewEmployeeController, { Props } from "./ViewEmployeeController.web";
import NavigationMenu from "../../../../blocks/navigationmenu/src/NavigationMenu.web";
import { AppFooter } from "../../../../components/src/AppFooter/appFooter";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import {
  CheckedCheckBox, UnCheckedCheckBox, UserIcon, CommunityIcon, EmailIcon, DummyAvatar,
  DummyAvatarEdit, DateCalendar
} from "../assets";
import { checkCondition, disableEndTime, generateTimeRange } from "../../../../components/src/HelperUtils";
import { SelectProps } from '@material-ui/core/Select';
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import CustomerCalendar from "../../../customisableuserprofiles/src/components/CustomerCalendar";
import { Shedule } from "../../../customisableuserprofiles/src/CustomisableUserProfilesController";
import { Views } from "react-big-calendar";

const selectMenuProps: Partial<SelectProps['MenuProps']> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const Adornment = (imgSrc: string) => (
  <InputAdornment position="start">
    <img src={imgSrc} />
  </InputAdornment>
);
// Customizable Area End

export default class ViewEmployee extends ViewEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEmployeeProfile = () => {
    return (
      <>
        <EmployeeWrapper>
          <DetailsWrapper>
            <FormWrapper>
              {this.renderForm()}
              {this.renderAvailability()}
              {this.renderFormButtons()}
            </FormWrapper>
          </DetailsWrapper>
        </EmployeeWrapper>
      </>
    )
  }
  renderForm = () => {
    const { isEditOn } = this.state
    return (
      <>
        <ImageBox>
          {isEditOn ? <img src={DummyAvatarEdit} /> : <img src={DummyAvatar} />}
        </ImageBox>
        <Grid container spacing={5}>
          <GridItem item md={6} sm={12} xs={12}>
            <CustomFormLabel>Employee Name</CustomFormLabel>
            <CustomField
              InputProps={{
                startAdornment: Adornment(UserIcon),
              }}
            />
          </GridItem>
          <GridItem item md={6} sm={12} xs={12}>
            <CustomFormLabel>Select Role</CustomFormLabel>
            <CustomField
              select
              InputProps={{
                startAdornment: Adornment(CommunityIcon),
              }}
              SelectProps={{
                MenuProps: selectMenuProps,
                IconComponent: CustomMenuIcon
              }}
            >
              <MenuFont value={10}>Option 1</MenuFont>
              <MenuFont value={20}>Option 2</MenuFont>
              <MenuFont value={30}>Option 3</MenuFont>
            </CustomField>
          </GridItem>
          <GridItem item md={6} sm={12} xs={12}>
            <CustomFormLabel>Email</CustomFormLabel>
            <CustomField
              type="email"
              InputProps={{
                startAdornment: Adornment(EmailIcon),
              }}
            />
          </GridItem>
          <GridItem item md={6} sm={12} xs={12}>
            <CustomFormLabel>Phone</CustomFormLabel>
            <PhoneInput
              buttonStyle={{
                border: "none",
                background: "transparent",
              }}
              country={"in"}
              inputStyle={{
                border: "none",
                width: "100%",
                borderBottom: "1px solid #D9D9D9",
                borderRadius: "0px",
                height: "32px",
                paddingLeft: "40px",
              }}
              dropdownStyle={{ fontSize: "13px" }}
              placeholder="Your contact number"
              data-test-id="new-establish-phone"
            />
          </GridItem>
          <GridItem item md={6} sm={12} xs={12}>
            <CustomFormLabel>Select Service</CustomFormLabel>
            <CustomField
              select
              InputProps={{
                startAdornment: Adornment(CommunityIcon),
              }}
              SelectProps={{
                MenuProps: selectMenuProps,
                IconComponent: CustomMenuIcon
              }}
            >
              <MenuFont value={10}>Option 1</MenuFont>
              <MenuFont value={20}>Option 2</MenuFont>
              <MenuFont value={30}>Option 3</MenuFont>
            </CustomField>
          </GridItem>
          <GridItem item md={6} sm={12} xs={12}>
            <CustomFormLabel>Select Sub Service</CustomFormLabel>
            <CustomField
              select
              InputProps={{
                startAdornment: Adornment(CommunityIcon),
              }}
              SelectProps={{
                MenuProps: selectMenuProps,
                IconComponent: CustomMenuIcon
              }}
            >
              <MenuFont value={10}>Option 1</MenuFont>
              <MenuFont value={20}>Option 2</MenuFont>
              <MenuFont value={30}>Option 3</MenuFont>
            </CustomField>
          </GridItem>
        </Grid>
      </>
    )
  }
  renderAvailability = () => {
    const menuRange = generateTimeRange("00:00", "23:30", 30)

    return (
      <>
        <AvailabilityWrapper>
          {this.state.daysList.map((dayItem, dayIndex) => {
            return (
              <DayBoxWrapper key={dayIndex}>
                <DayBox>
                  <DayCheckbox
                    label={dayItem.label}
                    labelPlacement="end"
                    control={this.state.isEditOn ?
                      <Checkbox
                        checkedIcon={<img src={CheckedCheckBox} />}
                        icon={<img src={UnCheckedCheckBox} />}
                      />
                      : <></>
                    }

                  />
                </DayBox>

                <Box>
                  <TimeBox>
                    <AccessTimeIcon
                      style={{
                        width: 24,
                        height: 24,
                        color: checkCondition(this.state.isEditOn, "#6F05EA", "#000000") as string
                      }}
                    />
                    <CustomSelect
                      className={checkCondition(this.state.isEditOn, "edit-select", "default-selects") as string}
                      id="start-range-day"
                      variant="outlined"
                      disabled={!this.state.isEditOn}
                      displayEmpty
                      IconComponent={DownArrowIcon}
                    >
                      <MenuFont value={""} disabled>00:00</MenuFont>
                      {menuRange.map((each, index) => {
                        return (
                          <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                        )
                      })}
                    </CustomSelect>
                    <CustomSelect
                      className={checkCondition(this.state.isEditOn, "edit-select", "default-selects") as string}
                      id="end-range-day"
                      variant="outlined"
                      disabled={!this.state.isEditOn}
                      displayEmpty
                      IconComponent={DownArrowIcon}
                    >
                      <MenuFont value={""} disabled>00:00</MenuFont>
                      {menuRange.map((each, index) => {
                        return (
                          <MenuFont value={each.value} key={index}
                            disabled={disableEndTime(index, "")}
                          >
                            {each.name}
                          </MenuFont>
                        )
                      })}
                    </CustomSelect>
                  </TimeBox>
                </Box>
              </DayBoxWrapper>
            )
          })}
        </AvailabilityWrapper>
      </>
    )
  }
  renderFormButtons = () => {
    return (
      <ButtonWrapper>
        {this.state.isEditOn ?
          <>
            <CustomButton
              style={style.greyBtn}
              onClick={this.handleCancel}
              data-testid="cancel-btn"
            >
              Cancel
            </CustomButton>
            <CustomButton
              style={style.blueBtn}
              data-testid="save-btn"
            >
              Save
            </CustomButton>
          </>
          :
          <CustomButton
            style={style.blueBtn}
            onClick={this.handleEdit}
            data-testid="edit-btn"
          >
            Edit
          </CustomButton>
        }
      </ButtonWrapper>
    )
  }
  renderAddEventModal = () => {
    const { openAddEvent } = this.state
    return (
      <>
        {openAddEvent &&
          <DialogContainer
            open={openAddEvent}
            fullWidth
            aria-labelledby="add-event-id"
            data-testid="add-event-modal"
          >
            <DialogMainWrapper>
              <TitleDialog>Add Event</TitleDialog>
              <InputWrapper>
                <Box>
                  <EventFormLabel>Event Name</EventFormLabel>
                  <CustomField
                    fullWidth
                    id="event-name"
                    value={this.state.eventName}
                    onChange={(event) => this.setEventName(event.target.value)}
                    placeholder="Event Name"
                    data-testid="event-name-input"
                  />
                </Box>
                <DateFieldWrapper>
                  <DateFieldBox>
                    <EventFormLabel>Start date</EventFormLabel>
                    <DatePicker
                      className="custom-day-date-picker"
                      showIcon
                      selectsStart
                      withPortal
                      toggleCalendarOnIconClick
                      placeholderText="Choose a Date"
                      dateFormat="EEE dd/MM/yyyy"
                      minDate={new Date()}
                      selected={this.state.eventStartDate}
                      onChange={(event) => this.setEventDate(event, "eventStartDate")}
                      icon={<img src={DateCalendar} alt="calendar-icon" />}
                      data-testid='event-start-date'
                    />
                  </DateFieldBox>
                  <DateFieldBox>
                    <EventFormLabel>End date</EventFormLabel>
                    <DatePicker
                      className="custom-day-date-picker"
                      showIcon
                      selectsEnd
                      withPortal
                      toggleCalendarOnIconClick
                      placeholderText="Choose a Date"
                      dateFormat="EEE dd/MM/yyyy"
                      minDate={this.state.eventStartDate}
                      selected={this.state.eventEndDate}
                      onChange={(event) => this.setEventDate(event, "eventEndDate")}
                      icon={<img src={DateCalendar} alt="calendar-icon" />}
                      data-testid='event-end-date'
                    />
                  </DateFieldBox>
                </DateFieldWrapper>
              </InputWrapper>

              <DialogButtonWrapper>
                <DialogButton
                  style={style.greyBtn}
                  data-testid="cancel-event-btn"
                  onClick={this.handleClosAddEvent}
                >
                  Cancel
                </DialogButton>
                <DialogButton
                  style={style.blueBtn}
                  data-testid="save-btn"
                >
                  Save
                </DialogButton>
              </DialogButtonWrapper>
            </DialogMainWrapper>
          </DialogContainer>
        }
      </>
    )
  }
  renderCalendar = () => {
    return (
      <>
        <EmployeeWrapper>
          <CalendarBox>
            <CustomerCalendar
              calendarDate={new Date()}
              calendarView={this.state.calendarView}
              changeView={this.changeCalendarView}
              data-testid="employee-calendar"
              events={[]}
              onEventSelect={(event: Shedule | null) => { }}
              fetchAppointments={(view: (typeof Views)[keyof typeof Views], date: Date) => { }}
            />
          </CalendarBox>
        </EmployeeWrapper>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationMenu id={''} navigation={this.props.navigation} />
        <MainWrapper>
          <BackBox>
            <ArrowBtn disableRipple>
              <ArrowIcon /><Title>Employee Management</Title>
            </ArrowBtn>
          </BackBox>
          {this.renderEmployeeProfile()}
          <EmployeeWrapper>
            <CalendarWrapper>
              <Typography style={style.calendarTitle}>Employee Calendar</Typography>
              <AddEventBtn
                onClick={this.handleOpenAddEvent}
                data-testid="add-event-btn"
              >
                Add Event
              </AddEventBtn>
            </CalendarWrapper>
          </EmployeeWrapper>
          {this.renderCalendar()}
          {this.renderAddEventModal()}
        </MainWrapper>
        <AppFooter navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  backgroundColor: "#F6F4F9",
  padding: "32px",
  "@media (max-width: 600px)": {
    padding: "32px 16px",
  },
})
const BackBox = styled(Box)({
  margin: "32px",
  "@media (min-width: 600px) and (max-width: 991px)": {
    margin: "32px 16px",
  },
  "@media (max-width: 600px)": {
    margin: "32px 8px",
  },
})
const ArrowBtn = styled(IconButton)({
  padding: 0,
  "&:hover": {
    background: "none !important",
  },
})
const ArrowIcon = styled(KeyboardArrowLeft)({
  color: "#6F05EA",
  width: 48,
  height: 48
})
const Title = styled(Typography)({
  marginLeft: "12px",
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "30px",
  lineHeight: "20px",
  color: "#0F172A",
  "@media (min-width: 600px) and (max-width: 991px)": {
    fontSize: "22px",
  },
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
})
const EmployeeWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center"
})
const DetailsWrapper = styled(Box)({
  width: "100%",
  maxWidth: "900px",
  marginTop: "32px",
  padding: "61px 36px 61px 36px",
  backgroundColor: "#fff",
  borderRadius: "16px",
  "@media (min-width: 600px) and (max-width: 991px)": {
    padding: "61px 18px 61px 18px",
  },
  "@media (max-width: 600px)": {
    padding: "30px 18px 30px 18px",
  },
})
const ButtonWrapper = styled(Box)({
  padding: "7px 0",
  display: "flex",
  justifyContent: "end",
  columnGap: "26px",
  marginTop: "32px"
})
const CustomButton = styled(Button)({
  padding: "6.5px 0",
  height: "37px",
  width: "136px",
  borderRadius: "31px",
  textAlign: "center",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none",
  fontFamily: 'Montserrat'
})
const DayBoxWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  backgroundColor: "#F8F8F8",
  borderRadius: "4px",
  marginBottom: "10px"
})
const DayBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  height: "60px",
  width: "100px",
  "@media (max-width: 600px)": {
    padding: "0 10px",
  },
})

const TimeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "60px",
  padding: "0 20px",
  "@media (max-width: 600px)": {
    padding: "0 10px",
  }
})
const DayCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "&.checked-label .MuiFormControlLabel-label": {
    color: "#000000",
  },
  "&.default-label .MuiFormControlLabel-label": {
    color: "#A8A29E",
  },
  "& .MuiFormControlLabel-label": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: 'Montserrat'
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
  },
  "& .MuiCheckbox-root": {
    padding: "0px",
    marginRight: "16px"
  },
})
const CustomSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400
  },
  "&.edit-select .MuiSelect-select": {
    color: '#6F05EA'
  },
  "&.edit-select .MuiSelect-icon": {
    color: '#6F05EA',
    marginTop: "-2px"
  },
});
const DownArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
    marginTop: "-2px"
  }
})
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400
})
const FormWrapper = styled(Box)({
  padding: "0 38px",
  "@media (min-width: 600px) and (max-width: 991px)": {
    padding: "0 19px",
  },
  "@media (max-width: 600px)": {
    padding: "0",
  },
})
const AvailabilityWrapper = styled(Box)({
  marginTop: "54px"
})
const CustomFormLabel = styled(FormLabel)({
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "16px",
  color: "#6F05EA",
  fontFamily: 'Montserrat',
  marginBottom: "4px"
})
const GridItem = styled(Grid)({
  display: "flex",
  flexDirection: "column"
})
const CustomMenuIcon = styled(KeyboardArrowDown)({
  color: '#A6A6A6',
});
const CustomField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInputBase-input": {
    height: 20,
    fontFamily: 'Montserrat',
    fontSize: "13px",
    fontWeight: 500,
    color: "#1E293B",

    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontFamily: 'Montserrat',
      fontSize: "13px",
      fontWeight: 500,
    },
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
  }
})
const ImageBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "54px"
})

const CalendarWrapper = styled(Box)({
  width: "100%",
  maxWidth: "972px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "46px 0 26px 0",
})
const AddEventBtn = styled(Button)({
  padding: "8px 0",
  height: "40px",
  width: "100%",
  maxWidth: "166px",
  borderRadius: "100px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none",
  fontFamily: 'Montserrat',
  color: "#6F05EA",
  border: "1px solid #6F05EA",
})

const DialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      maxWidth: 883,
      borderRadius: "16px"
    },
  },
  // GLOBAL CALENDAR
  "& .react-datepicker__input-container": {
    opacity: "1 !important",
    position: "unset",
    "& img": {
      padding: "8px"
    }
  },
  "& .react-datepicker__input-container input": {
    width: "100% ",
    height: "auto !important",
    padding: "8px 10px 10px 30px !important",
    border: "none !important",
    borderBottom: "1px solid #D9D9D9 !important",
    '&:focus': {
      border: "none !important",
      borderBottom: "1px solid #D9D9D9 !important",
      outline: "none !important",
    },
  },
})
const DialogMainWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "61px 36px",
  "@media (max-width: 600px)": {
    padding: "61px 18px",
  },
})
const TitleDialog = styled(Typography)({
  fontWeight: 600,
  fontSize: "27px",
  lineHeight: "24px",
  fontFamily: 'Montserrat',
  color: "#6F05EA"
})
const InputWrapper = styled(Box)({
  margin: "50px 0px",
  width: "100%",
  maxWidth: "360px"
})

const EventFormLabel = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "13px",
  color: "#6F05EA"
})
const DateFieldWrapper = styled(Box)({
  display: "flex",
  columnGap: "60px",
  rowGap: "24px",
  marginTop: "46px",
  flexWrap: "nowrap",
  "@media (max-width: 600px)": {
    flexWrap: "wrap"
  },
})
const DateFieldBox = styled(Box)({
  minWidth: 160,
  width: "100%",
})
const DialogButtonWrapper = styled(Box)({
  width: "100%",
  justifyContent: "center",
  display: "flex",
  columnGap: "26px"
})
const DialogButton = styled(Button)({
  padding: "16px 0",
  height: "56px",
  width: "100%",
  maxWidth: "180px",
  borderRadius: "31px",
  textAlign: "center",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "24px",
  textTransform: "none",
  fontFamily: 'Montserrat',
  "@media (max-width: 400px)": {
    fontSize: "18px",

  },
})
const CalendarBox = styled(Box)({
  width: "100%",
  maxWidth: "972px",
  backgroundColor: "#FFF"
})
const style = {
  blueBtn: {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  } as React.CSSProperties,
  greyBtn: {
    color: "#4B4B4B",
    border: "1px solid #4B4B4B",
  } as React.CSSProperties,
  calendarTitle: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: 'Montserrat',
    color: "#000"
  } as React.CSSProperties,
}
// Customizable Area End
