import React from "react";
// Customizable Area Start
import { Box, Button, Dialog, Typography, styled, IconButton, TextField, FormHelperText } from '@material-ui/core';
import AddCardStripeController, { Props, webConfigJSON } from "./AddCardStripeController.web";
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { CustomSwitch, LoadingButton } from "../../../../components/src/HelperComponents";
import CloseIcon from "@material-ui/icons/Close";
import { DefaultCard, Mastercard, Visa } from '../assets';
import { inputFilter } from "../../../../components/src/helpers";

const card_brand = (brand: string) => {
  let brandLogo;
  switch (brand) {
    case "visa":
      brandLogo = Visa
      break;
    case "mastercard":
      brandLogo = Mastercard
      break;
    default:
      brandLogo = DefaultCard
      break
  }
  return (
    <img src={brandLogo} />
  )
}
const elementStyles = {
  base: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    color: "#333",
    '::placeholder': {
      color: "#B7B7B7",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "14px",
    },
  },
  invalid: {
    color: "red",
  },
}
const cardElementOptions = {
  style: elementStyles,
};
const cardNumberElementOptions = {
  placeholder: "xxxx xxxx xxxx xxxx",
  style: {
    base: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "16px",
      color: "#333",
      '::placeholder': {
        color: "#B7B7B7",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "14px",
      },
    },
    invalid: {
      color: "red",
    },
  },
};
// Customizable Area End

export default class AddCardStripe extends AddCardStripeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  errorMessage = () => {
    const { error } = this.props
    const { errorMessage } = this.state
    return (
      <>
        {errorMessage || error?
          <ErrorBox>
            {errorMessage || error}
            <ErrorCloseButton onClick={this.closeErrorMessage}>
              <ErrorCloseIcon />
            </ErrorCloseButton>
          </ErrorBox>
          : <></>}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isOpen } = this.props
    const { cardErrors, cardHolderName } = this.state
    return (
      <DialogWrapper
        id='add-card'
        open={isOpen}
        fullWidth
        aria-labelledby="add-card-id"
      >
        <ContentWrapper>
          <Title>Card details</Title>
          <SubTitle>{webConfigJSON.DEFAULT_MSG.UPDATE_PAYMENT_CARD}</SubTitle>
          {this.errorMessage()}
          <FormWrapper>
            <Elements stripe={this.stripePromise} options={this.options}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <FormTag
                    onSubmit={(event) => this.handleSubmit(event, stripe, elements)}
                    data-testid="card-form"
                  >
                    <Wrapper>

                      <Box>
                        <CustomLabel>Name</CustomLabel>
                        <CustomTextField
                          fullWidth
                          placeholder={'The name on the card'}
                          onChange={(event) => {
                            this.handleChangeName(inputFilter.applyFilter('lettersSpaces', event.target.value))
                          }}
                          value={cardHolderName}
                          data-testid="card-name-input"
                        />
                        <FormHelperText error>{cardErrors.name}</FormHelperText>
                      </Box>

                      <Box>
                        <CustomLabel>Card Number</CustomLabel>
                        <ElementBox style={{ position: "relative" }}>
                          <CardNumberElement options={cardNumberElementOptions} onChange={this.handleChangeNumber} data-testid="card-number-input" />
                          <BrandBox>{card_brand(this.state.cardBrand)}</BrandBox>
                        </ElementBox>
                        <FormHelperText error>{cardErrors.number}</FormHelperText>
                      </Box>

                      <RowBox>
                        <Box width={"100%"}>
                          <CustomLabel>Valid Till</CustomLabel>
                          <ElementBox>
                            <CardExpiryElement options={cardElementOptions} onChange={this.handleChangeExpiry} data-testid="card-expiry-input" />
                          </ElementBox>
                          <FormHelperText error>{cardErrors.expiry}</FormHelperText>
                        </Box>
                        <Box width={"100%"}>
                          <CustomLabel>Security Code</CustomLabel>
                          <ElementBox>
                            <CardCvcElement options={cardElementOptions} onChange={this.handleChangeCVC} data-testid="card-cvc-input" />
                          </ElementBox>
                          <FormHelperText error>{cardErrors.cvv}</FormHelperText>
                        </Box>
                      </RowBox>

                      <Box>
                        <CustomLabel>Set as Default</CustomLabel>
                        <CustomSwitch
                          checked={this.props.setAsDefault}
                          onChange={(event) => {this.props.handleChangeDefault(event.target.checked)}}
                          name="set-as-default"
                          data-testid="set-default-switch" />

                      </Box>
                      <ButtonsWrapper>
                        <FormButton onClick={this.handleCancel}>Cancel</FormButton>
                        <FormSaveButton
                          isLoading={this.props.submitLoader || this.state.submitBtnLoading}
                          buttonText='Save'
                          type='submit'
                          data-testid="card-save-button"
                        >
                          Save
                        </FormSaveButton>
                      </ButtonsWrapper>
                    </Wrapper>
                  </FormTag>
                )}
              </ElementsConsumer>
            </Elements>
          </FormWrapper>
        </ContentWrapper>
      </DialogWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DialogWrapper = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      maxWidth: 630,
    },
  },
})
const ContentWrapper = styled(Box)({
  padding: "40px 60px",
  "@media (max-width: 960px)": {
    padding: "40px 48px",
  },
  "@media (max-width: 600px)": {
    padding: "32px",
  },
  "@media (max-width: 400px)": {
    padding: '20px',
  },
})
const Title = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: "32px",
  color: '#6F05EA',
  marginBottom: '16px',
  "@media (max-width: 400px)": {
    fontSize: '20px',
    marginBottom: '8px',
  },
})
const SubTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: "32px",
  color: '#000',
  "@media (max-width: 400px)": {
    fontSize: '16px',
  },
})

const FormWrapper = styled(Box)({
  marginTop: "32px"
})
const CustomLabel = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: "22px",
  color: '#000',
  "@media (max-width: 600px)": {
    fontSize: '14px',
  },
})
const ElementBox = styled(Box)({
  padding: "12px 8px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  "@media (max-width: 400px)": {
    padding: "12px 0px",
  },
})

const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "40px"
})
const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "40px",
  "@media (max-width: 400px)": {
    flexDirection: "column",
  },
})
const CustomTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
  "& .MuiInputBase-input": {
    fontFamily: 'Montserrat',
    height: '20px',
    padding: "12px 8px",
    fontSize: "18px",
    fontWeight: 400,
    color: "#000000",
    "@media (max-width: 400px)": {
      padding: "12px 0px",
    },
    "&::placeholder": {
      color: "#B7B7B7",
      opacity: 1,
      fontFamily: 'Montserrat',
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  "& .MuiFormHelperText-root": {
    fontFamily: 'Montserrat',
  }
});
const BrandBox = styled(Box)({
  position: "absolute",
  top: "8px",
  right: "8px",
  "@media (max-width: 400px)": {
    right: "0px"
  },
})
const FormTag = styled(`form`)({
  marginBottom: 0
})
const ButtonsWrapper = styled(Box)({
  display: "flex",
  gap: "26px",
  alignItems: 'center',
  justifyContent: 'center',
  "@media (max-width: 400px)": {
    gap: "12px",
  },
})
const FormButton = styled(Button)({
  border: "2px solid #4B4B4B",
  color: '#4B4B4B',
  width: '100%',
  fontWeight: 600,
  backgroundColor: 'transparent',
  backround: 'transparent',
  textTransform: 'none',
  letterSpacing: 0,
  padding: '16px 32px',
  borderRadius: '40px',
  fontSize: '24px',
  lineHeight: '24px',
  fontFamily: 'Montserrat',
  "@media (max-width: 1600px)": {
    fontSize: "20px",
  },
})
const FormSaveButton = styled(LoadingButton)({
  backgroundColor: 'transparent',
  backround: 'transparent',
  textTransform: 'none',
  letterSpacing: 0,
  padding: '16px 32px',
  borderRadius: '40px',
  fontSize: '24px',
  lineHeight: '24px',
  fontFamily: 'Montserrat',
  border: "2px solid #6F05EA",
  color: '#6F05EA',
  width: '100%',
  fontWeight: 700,
  "@media (max-width: 1600px)": {
    fontSize: "20px",
  },
})
const ErrorBox = styled(Box)({
  margin: "16px 0",
  display: 'flex',
  minHeight: "40px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: '8px 16px',
  borderLeft: '4px solid #DC2626',
  backgroundColor: '#FEE2E2',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 400,
  color: '#DC2626',
  borderRadius: "4px"
})
const ErrorCloseButton = styled(IconButton)({
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
})
const ErrorCloseIcon = styled(CloseIcon)({
  height: 16,
  width: 16,
  color: "#DC2626"
})
// Customizable Area End
