import React, { useCallback, useState } from 'react';
import { Box, Tab, Tabs, Typography, styled } from '@material-ui/core';
import moment from 'moment';
import Appointment from './Appointment';
import { groupAppointmentsByDate } from '../../../../components/src/helpers';
import { EmptyAppointments } from '../assets';
import { Shedule } from '../CustomisableUserProfilesController';


type Props = {
    appointments: Shedule[];
    onEventSelect: (event: Shedule | null) => void;
}

const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    textAlign: 'left',

    // "& .appointment-tab": {
    //     whiteSpace: 'nowrap',
    // },

    "& .apointment-tab": {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#B7B7B7',
        fontWeight: 700,
        textTransform: 'none',
        [theme.breakpoints.up(640)]: {
            flex: 1
          },
    },
    "& .apointment-tab.Mui-selected": {
        color: '#6F05EA',
        fontWeight: 700,
    },
    "& .content-wrapper": {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '36px'
    },
    "& .appointment-wrapper": {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px'
    },
    "& .view-all-wrapper": {
        fontFamily: 'Montserrat',
        fontSize: '16px', 
        color: '#6F05EA', 
        fontWeight: 600, 
        cursor: 'pointer'
    },
    "& .appointment-list": {
        display: 'flex', flexDirection: 'column', gap: '20px'
    },
    "& .header-color": {
        color: '#000', fontSize: '20px', fontWeight: 600
    },
    "& .group-list": {
        display: 'flex', flexDirection: 'column', gap: '13px'
    },
    "& .empty-wrapper": {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', gap: '8px',
        [theme.breakpoints.up(640)]: {
            width: '235px'
          },
    },
    "& .empty-text": {
        color: '#B7B7B7', fontSize: '19px', fontWeight: 600
    }
}))

const ApointmentsList = ({ appointments, onEventSelect }: Props) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [isVisibleArr, setIsVisibleArr] = useState<Record<string, boolean>>({});

    const handleToogleVisible = (date: string) => {
        setIsVisibleArr((prev) => ({
            ...prev,
            [date]: prev[date] ? false : true
        }))
    }
    
    const appointmentsList = useCallback(() => {
        const now = moment();
        const past = appointments.filter(appointment => moment(appointment.end_date, 'YYYY-MM-DD').isBefore(now.startOf('day')));
        const future = appointments.filter(appointment => moment(appointment.end_date, 'YYYY-MM-DD').isSameOrAfter(now.startOf('day')));
        const withRequest = appointments.filter(appointment => !!appointment.requests.request_details.length);

        return { past, future, withRequest }
    }, [appointments])

    const renderApointments = (list: Shedule[]) => {
        const grouped = groupAppointmentsByDate(list);

        return (
                <Box className='appointment-list'>
                    {grouped.map(group => { 
                        const limit = 3;
                        const total = group.appointments.length;
                        const dateFromat = moment(group.date).format("dddd");
                        const isShowMore = total > limit;
                        const visibleCount = isVisibleArr[dateFromat] ? total : limit;
                        return ( 
                            <Box className='group-list'>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography className='header-color'>
                                        {dateFromat}, <span style={{color: '#B7B7B7'}}>{moment(group.date).format("DD")}</span>
                                    </Typography>
                                    {isShowMore && 
                                        <Box onClick={() => handleToogleVisible(dateFromat)} data-test-id="view-all-btn">
                                            <Typography className='view-all-wrapper'>
                                                {isVisibleArr[dateFromat] ? 'Hide all' : 'View all'}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                {group.appointments.slice(0, visibleCount).map(appointment => <Appointment event={appointment} key={appointment.id} onEventSelect={onEventSelect} />)}
                            </Box>
                        )
                    })}
                    {!grouped.length && 
                    <Box className='empty-wrapper'>
                        <Box><img src={EmptyAppointments} /></Box>
                        <Typography className='empty-text'>{'No appointments'}</Typography>
                    </Box>
                }
                </Box>
        ) 
    }

    const renderPastAppointments = (list: Shedule[], isRequest?: boolean) => {
        return (
            <Box className='appointment-list' data-test-id='past-appointments'>
                {list.map(appointment => (<Appointment event={appointment} key={appointment.id} onEventSelect={onEventSelect} />))}
                {!list.length && 
                    <Box className='empty-wrapper'>
                        <Box><img src={EmptyAppointments} /></Box>
                        <Typography className='empty-text'>{isRequest ? 'No requests' : 'No past appointments'}</Typography>
                    </Box>
                }
            </Box>
        )
    }

    return (
        <Wrapper>
            <Tabs
                variant='scrollable'
                scrollButtons='auto'
                value={currentTab}
                data-test-id="apointment-tabs"
                onChange={(_, idx) => setCurrentTab(idx)}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: '#6F05EA',
                    },
                }}
                >
                <Tab label="Appointments" className="apointment-tab" />
                <Tab label="Past appointments" className="apointment-tab" wrapped={false}/>
                <Tab label="Requests" className="apointment-tab" />
            </Tabs>
            <Box className='content-wrapper'>
                <Box className='appointment-wrapper'>
                {currentTab === 0 && renderApointments(appointmentsList().future)}
                {currentTab === 1 && renderPastAppointments(appointmentsList().past)}
                {currentTab === 2 && renderPastAppointments(appointmentsList().withRequest, true)}
                </Box>
            </Box>
        </Wrapper>
    )
}


export default ApointmentsList
