const days: { [key: string]: string } = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };


  export const convertAbbreviationToFullDay = (abbreviation: string): string => {
    return days[abbreviation] || abbreviation; 
  };