type Params = {
    pathURL: string,
    token: string | null,
    filename: string,
    callback?: () => void;
}

export const downloadFile = async ({ pathURL, token, filename, callback = () => undefined }: Params) => {
    if(token) {
        try {
          const response = await fetch(pathURL, {
            method: 'GET',
            headers: {
              'token': token
            },
          })
          
          if(!response.ok) {
            throw new Error('Failed to download file');
          }
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error download csv:", error)
        } finally {
          callback();
        }
    }
}