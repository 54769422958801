Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfentrepriseaccount";
exports.labelBodyText = "cfentrepriseaccount Body";

exports.btnExampleTitle = "CLICK ME";
exports.defaultCountry = "Portugal";
exports.establishmentListApiEndPoint = "account_block/establishments";
exports.formContentType = "multipart/form-data";
exports.apiMethodPut = "PUT";
exports.updatePhoneEndPoint = "account_block/accounts/change_phone_number";
exports.updateEmailEndPoint = "account_block/accounts/change_email_address";
exports.resendEmailEndPoint = "account_block/accounts/resend_email_verification";
exports.generalInfoEndPoint = "account_block/accounts";
exports.changePasswordEndPoint = "account_block/accounts/change_password";
exports.loggedInDeviceEndPoint = "account_block/logged_sessions";
exports.endDeviceSessionEndPoint = "account_block/logged_sessions/end_device_session";
exports.deleteAPiMethod = "DELETE";
exports.addressSuggestionAPIEndPoint = "bx_block_maps3/locations?address="

exports.AddNewEstablishment = "Add New Establishment";
exports.PleaseAddEstablishment = "Please add an establishment";
exports.SelectYourEstablishment = "Select Your Establishment"
// Customizable Area End