import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { createRef } from "react";
import { defaultScroll } from "../../../components/src/HelperUtils";

interface APIModal {
  body:object,
  token?:string,
  contentType:string,
  method:string, 
  endPoint:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subject: string;
  details: string;
  isLoading: boolean;
  isShowModal: boolean;
  validationShema: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: string;
  apiEmailLoginCallId: string;
  fileSize: number;
  suportedFormats: string[];
  fileInputRef: React.RefObject<HTMLInputElement>;
  resetForm: () => void;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseToken)
    ];

    this.contactUsApiCallId = "";
    this.apiEmailLoginCallId = "";
    this.resetForm = () => {};

    let validationShema = Yup.object().shape({
      subject: Yup.string()
        .required(configJSON.validationSubjectEmpty)
        .trim(),
      details: Yup.string()
        .required(configJSON.validationDetailsEmpty)
        .trim(),
      files: Yup.array()
      .test(
      'fileSize',
      configJSON.validationFileSize,
      (files: File[] | undefined) =>
        files
          ? files.every(
              (file) =>
                file.size <= this.fileSize
            )
          : true
    ).test(
      'fileType',
      configJSON.validationFileType,
      (files: File[] | undefined) =>
        files
          ? files.every(
              (file) =>
                this.suportedFormats.includes(file.type)
            )
          : true
    )
    });

    this.fileSize = 5 * 1024 * 1024;
    this.fileInputRef = createRef();
    this.suportedFormats = ['image/png', 'image/jpeg'];

    this.state = {
      token: "",
      subject: "",
      details: "",
      isLoading: false,
      isShowModal: false, 
      validationShema: validationShema

    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.doEmailLogin();
    defaultScroll();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleSession(responseJson);
      if(apiRequestCallId === this.contactUsApiCallId) {
        this.handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: async () => {
            this.setState({ isShowModal: true, isLoading: false });
            this.resetForm();
          },
          onFail: () => {
            this.setState({ isLoading: false });
            errorJson.erros.forEach((error: { token: string}) => {
              if(error.token) {
                this.showAlert(configJSON.errorTitle, error.token);
              }
            })
          }
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleSession = (responseJson: any) => {
    if(responseJson && responseJson.errors) {
      if(Array.isArray(responseJson.errors)) {
        if(responseJson.errors[0].token) {
          this.goToLoginPage();
        }
      }
    }
  }

  subjectInputProps = {
    id: 'subject',
    placeholder: "Inquiry Type",
    fullWidth: true,
    type: "text",
    inputProps: { maxLength: 500 }
  }

  detailsInputProps = {
    autoComplete: "off",
    id: "details",
    placeholder: "Enter Details", 
    fullWidth: true,
    type: "text",
    multiline: true,
    inputProps: { maxLength: 1000 }
  }

  attachmentInputProps = {
    id: "files",
    name: "files",
    type: "file",
    accept: "image/png, image/jpeg",
    multiple: true,
    "data-test-id": "file-input",
    style: { display: 'none' }
  }

  btnSendProps = {
    id: "submit-btn",
    className: "ContactUs-Submit-Btn",
    disabled: false,
  }

  imageInputProps = (file: File) => ({
    className: "ContactUs-Img-Preview",
    alt: `preview ${file.name}`,
    src: URL.createObjectURL(file)
  })

  setFiles = (files: FileList | null, setValue: any, setError: any) => {
    if (files) {
      const filesArray = Array.from(files);
      setValue("files", filesArray);
    }
  }

  handleFileClick = () => {
    if (this.fileInputRef && this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  }

  apiCall = async (data: APIModal) => {
    const { contentType, method, endPoint, body, token } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  doEmailLogin = async() => {
    const token = localStorage.getItem("login_token") || '';
    const userId = localStorage.getItem("login_user_id") || '';
    if(token && userId) {
      this.setState({ token });
    } else {
      this.goToLoginPage();
    }
  }

  navigateToPage = (targetPage: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), targetPage);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  goToLoginPage = () => {
    this.navigateToPage("EmailAccountLoginBlock");
  };

  handleResponseMessage<T>(input: {
    responseJson: {
      errors?: T;
      error: T;
    };
    errorJson?: T;
    onSuccess: () => void;
    onFail: () => void;
  }) {
    const { responseJson, onSuccess, onFail, errorJson } = input;
  
    if (responseJson && !responseJson.error && !responseJson.errors) {
      onSuccess();
    }
  
    if (responseJson?.error || errorJson || responseJson.errors) {
      onFail();
    }
  }

  sendFormData = async (data: { subject: string, details: string, files: File[]}) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('contacts[title]', data.subject);
    formData.append('contacts[description]', data.details);
    Array.from(data.files).forEach((file) => {
      formData.append('contacts[pet_images][]', file);
    });
    const requestedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.contactUsApiCallId = requestedMessage.messageId;

    requestedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsSubmitApiEndpoint,
    );

    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ Token: this.state.token })
    );

    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestedMessage && requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestedMessage.id, requestedMessage);
  }

  // Customizable Area End
}
