export function capitalizeFirstLetterOnly(input: string) {
    if(!input) return "";

    if (input.length === 0) return input; 
    
    let lowercased = input.toLowerCase();
    
    let result = lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
    
    return result;
}