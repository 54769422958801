import React from "react";
// Customizable Area Start
import {
  Box, styled, Button, Typography, IconButton, TextField,
  Select, MenuItem,ImageList,
  CircularProgress,
  FormHelperText
} from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RoomsController, { Props } from "./RoomsController.web"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Cross, ViewClose, avatar, viewImage1, viewImage2, viewImage3, viewImage4, viewImage5, viewImage6 } from "../assets";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import {capitalizeFirstLetter, getImageIndices} from "../../../../components/src/HelperUtils";
import { SelectProps } from '@material-ui/core/Select';
import { Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton, SimpleDialog } from "../../../../components/src/HelperComponents";
import SubServiceGalleryWeb from "./SubServiceGallery.web";
interface IRooms {
  title: string,
  capacity: string | number,
  description: string
}

const menuProps: Partial<SelectProps['MenuProps']> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const roomSchema = Yup.object().shape({
  title: Yup.string()
    .required("*Room Title is required")
    .trim(),
  capacity: Yup.number()
    .typeError("*Capacity must be a number")
    .required("*Capacity is required"),
  allowedPet: Yup.string()
    .required("*Allowed Pet is required")
    .trim(),
  description: Yup.string()
    .required("*Description is required")
    .trim(),
  images: Yup.array()
    .min(1, "*One image is required"),
});
// Customizable Area End

export default class Rooms extends RoomsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderAddRoomModal = () => {
    return (
      <>
        <DialogContainer
          open={this.state.openAddRoom}
          fullWidth
          aria-labelledby="add-room-id"
          data-testid="add-room-modal"
        >
          <FormTitle id="add-room-id">{this.state.isEdit ? "Edit Rooms" : "Add Rooms"}</FormTitle>
          <FormWrapper>
          <Formik
                initialValues={this.state.roomFormInitialValue}
                validationSchema={roomSchema}
                enableReinitialize
                onSubmit={(values) => {
                  this.addUpdateRoomApiCall(values);
                }}
              >
                {({ errors, touched, handleSubmit, setFieldValue, getFieldProps, values }) => {
                  return (
            <form onSubmit={handleSubmit}>
                <RowBox>
                  <LeftBox>
                    <FormLabel>Room Title</FormLabel>
                    <CustomSelect
                      fullWidth
                      id="room-title-id"
                      defaultValue={""}
                      displayEmpty
                      IconComponent={ArrowIcon}
                      MenuProps={menuProps}
                      {...getFieldProps("title")}
                    >
                      <MenuItem value="" disabled >
                        <span style={style.defaultMenu}>Select Room Type</span>
                      </MenuItem>
                      <MenuFont value={'Individual room'}>Individual Room</MenuFont>
                      <MenuFont value={'Collective room'}>Collective Room</MenuFont>
                      <MenuFont value={'Suite room'}>Suite Room</MenuFont>
                    </CustomSelect>
                    {errors.title && touched.title && (
                            <FormHelperText
                              data-test-id="upload-error"
                              className="errorText"
                            >
                              {errors.title}
                            </FormHelperText>
                          )}
                  </LeftBox>

                        <RightBox>
                          <FormLabel>Capacity</FormLabel>
                          <CustomTextField
                            placeholder="Enter Capacity"
                            fullWidth
                            {...getFieldProps("capacity")}
                            error={Boolean(errors.capacity) && touched.capacity}
                            helperText={(
                              touched.capacity && errors.capacity
                            )?.toString()}
                          />
                        </RightBox>
                  </RowBox>
                      <Box style={{ marginTop: "40px", maxWidth: "204px" }}>
                          <FormLabel>Allowed Pet</FormLabel>
                          <CustomSelect
                            {...getFieldProps("allowedPet")}
                            fullWidth
                            id="room-title-id"
                            defaultValue={""}
                            displayEmpty
                            IconComponent={ArrowIcon}
                            MenuProps={menuProps}
                          >
                            <MenuItem value="" disabled >
                              <span style={style.defaultMenu}>Select Pet</span>
                            </MenuItem>
                            {this.state.allowedPetOptionValue.length > 0 && this.state.allowedPetOptionValue.map((option) => (
                              <MenuFont value={option.key}>{option.label}</MenuFont>
                            ))}
                          </CustomSelect>
                          {errors.allowedPet && touched.allowedPet && (
                            <FormHelperText
                              data-test-id="upload-error"
                              className="errorText"
                            >
                              {errors.allowedPet}
                            </FormHelperText>
                          )}
                        </Box>
                  <FormLeftBox id="left-part">
                  <Box className="description-container">
                    <FormLabel>Description</FormLabel>
                    <CustomTextArea
                      {...getFieldProps("description")}
                      multiline
                      minRows={4}
                      data-testid="desc-input-fiels"
                      variant="outlined"
                      placeholder="Enter Description"
                      error={
                        Boolean(errors.description) && touched.description
                      }
                      helperText={(
                        touched.description && errors.description
                      )?.toString()}
                    />
                  </Box>

                </FormLeftBox>

              <ImageWrraper>
                <Box id="room-galary">
                  <FormLabel>Room Gallery</FormLabel>
                  <GalleryWrapper>
                    <UploadButton>
                      <UploadBox>
                        <Box>
                          <UploadIcon />
                          <UploadText>upload file (png,jpg)</UploadText>
                          <input
                            type="file"
                            multiple
                            accept=".png, .jpg"
                            style={style.imageInput}
                            data-test-id="upload-img"
                            onChange={(event) => {
                              const fileList = event.currentTarget.files;
                              if (fileList) {
                                const files = Array.from(fileList);
                                const newFiles = [...values.images, ...files];
                                setFieldValue('images', newFiles);
                              }
                            }}
                          />
                        </Box>
                      </UploadBox>
                    </UploadButton>
                    <ImageListWrapper>
                      {values.images.map((file, index) => {
                        return (
                          <ImageBox key={index} >
                            <img src={URL.createObjectURL(file)} alt="preview" width={83} height={83} />
                            <RemoveBtn onClick={() => {
                                    const updatedFiles = values.images.filter((_, i) => i !== index);
                                    setFieldValue('images', updatedFiles);
                                  }} data-test-id="remove-btn"><img src={Cross} /></RemoveBtn>
                          </ImageBox>
                        )
                      })}
                    </ImageListWrapper>
                  </GalleryWrapper>
                  {
                          errors.images && touched.images && (
                            <FormHelperText
                              className="errorText"
                              data-test-id="upload-error"
                            >
                              {errors.images}
                            </FormHelperText>
                          )
                        }
                </Box>
              </ImageWrraper>
              <FormButtonWrapper>
                <FormButton
                  style={style.greyBtn}
                  data-testid="cancel-btn"
                  onClick={this.closeAddRoomModal}
                >
                  Cancel
                </FormButton>
                <LoadingButton
                  isLoading={this.state.createUpdateLoading}
                  buttonText="Save"
                  style={{...style.formButton, ...style.blueBtn}}
                  data-testid="save-btn"
                  type="submit"
                >
                </LoadingButton>
              </FormButtonWrapper>

            </form>
            );
          }}
        </Formik>
          </FormWrapper>
        </DialogContainer>
      </>
    )
  }

  renderImageGallery = () => {
    return (
          <Box className="modalImageGalleryContainer">
            <SubServiceGalleryWeb serviceImageList={this.state.selectedViewData.attributes.images_urls} />
        </Box>
      );
  };

  renderSimpleDialogForRoom = () => {
    const { openSimpleModalForRoom, messageSimpleModalForRoom } = this.state;
    return (
      <>
        {openSimpleModalForRoom &&
          <SimpleDialog
            open={openSimpleModalForRoom}
            message={messageSimpleModalForRoom}
            showRightBtn
            handleRightClick={this.simpleModalCloseForRoom}
            rightBtnTitle="Okay"
            data-testid="simple-dialog"
          />
        }
      </>
    )
  }

  renderViewDialog = () => {
    return (
      <ViewDialogContainer
        fullWidth
        open={this.state.isViewDialogOpen}
        maxWidth={"md"}
        data-test-id="view-data-modal"
      >
        <Box className="viewModalContainer">
          <ViewDialogTitle>
            <Box className="closeIconContainer">
              <img onClick={this.handleViewDialogClose} src={ViewClose} data-test-id="view-close-btn" style={{ cursor: "pointer" }} />
            </Box>
            <Box style={{ paddingRight: "15px", marginBottom:"25px" }}>
              <Typography className="modalTitleTypo">{this.state.selectedViewData.attributes.title}</Typography>
              <Box className="modalInfoContainer">
                <Typography className="modalInfoTypo">
                  Capacity : <span>{this.state.selectedViewData.attributes.capacity}</span>
                </Typography>
                <Typography className="modalInfoTypo">
                  Allowed Pets: <span>{capitalizeFirstLetter(this.state.selectedViewData.attributes.allows_pet)}</span>
                </Typography>
              </Box>
              <Box className="modalDescriptionContainer">
                <span className="modalDescriptionTypo">
                  {this.state.selectedViewData.attributes.description}
                </span>
              </Box>
            </Box>
          </ViewDialogTitle>
          <DialogContent className="imgGalleryContainer">
            <Box >
              {this.renderImageGallery()}
            </Box>
          </DialogContent>
          <Box className="viewModalBtnContainer">
            <Button
              className="viewModalEditBtn"
              onClick={this.handleViewEditClick}
              data-test-id="edit-btn"
            >
              Edit
            </Button>
            <Button
              onClick={this.handleHiddenClick}
              data-test-id="hidden-btn"
              style={{ display: "none" }}
            >
            </Button>
          </Box>
        </Box>
      </ViewDialogContainer>
    );
  };

  renderCreateRoomModal = () => {
    return (
      <StyledCreateRoom
        fullWidth
        open={this.state.openCreateRoom}
        maxWidth={"md"}
        data-test-id="create-room-modal"
      >
        <Box className="createRoomContainer">
          <CreateRoomImgWrapper>
            <CloseIconButton onClick={() => this.handleCreateRoomDialogClose()} data-test-id="create-close-btn"><img src={ViewClose} alt="close-icon" /></CloseIconButton>
          </CreateRoomImgWrapper>
          <CreateRoomInfoWrapper>
            <Typography className="createRoomTypo">
              To create a Room, we'll need some basic information.
            </Typography>
            <Typography className="pleasFillTypo">
              Please fill in the general details and commodity specifics first!
            </Typography>
          </CreateRoomInfoWrapper>
          </Box>
      </StyledCreateRoom>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        <ListWrapper>
        {this.state.getRoomList.length && !this.state.getRoomListLoading ? (
          this.state.getRoomList.map((room, index) => {
            return (
              <>
                <RoomWrapper key={index}>
                    <TitleWrapper>
                      <LabelFont>Room Title :</LabelFont>
                      <ValueFont noWrap>
                        <span title={room.attributes.title}>{room.attributes.title}</span>
                      </ValueFont>
                    </TitleWrapper>
                    <CapacityWrapper>
                      <LabelFont>Capacity :</LabelFont>
                      <ValueFont style={{ fontWeight: 600 }} noWrap>
                        {room.attributes.capacity}
                      </ValueFont>
                    </CapacityWrapper>
                  <DescWrapper>
                    <LabelFont>Description :</LabelFont>
                    <ValueFontDec noWrap>
                      <span title={room.attributes.description}>{room.attributes.description}</span>
                    </ValueFontDec>
                  </DescWrapper>
                    <IconButton
                    style={{ padding: 0 }}
                    onClick={() => this.handleViewClick(room.id)}
                    data-test-id="view-data-btn"
                  >
                    <KeyboardArrowRightIcon style={{ color: "#6F05EA" }} />
                  </IconButton>
                </RoomWrapper>
              </>
            );
          })) : (<></>) }
           {!this.state.getRoomList.length &&
                  this.state.getRoomListLoading ? (
                    <Box className="progressBarContainer">
                      <CircularProgress />
                    </Box>
                   ) : (
                    <></>
                  )} 
                  {!this.state.getRoomList.length &&
                  !this.state.getRoomListLoading ? (
                    <Typography className="no-data-found">
                      No results found!
                    </Typography>
                  ) : (
                    <></>
                  )}
        </ListWrapper>
        {this.renderAddRoomModal()}
        {this.state.selectedViewData.attributes && this.renderViewDialog()}

        <ButtonWrapper>
          <CustomButton
            style={style.blueBtn}
            data-testid="add-service-btn"
            onClick={this.openAddRoomModal}
          >
            Add Rooms
          </CustomButton>
        </ButtonWrapper>
        {this.renderCreateRoomModal()}
        {this.renderSimpleDialogForRoom()}
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
  width: "100%",
  "& .disableNewRoomBtn": {
    color: "#78716C !important",
    border: "1px solid #78716C !important",
  },
  "& .no-data-found":{
    fontWeight:600,
    fontFamily: 'Montserrat',
    color:"rgb(183, 183, 183)",
    textAlign:"center",
    fontSize:18
  },
})
const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "end",
  marginTop: "32px"
})
const CustomButton = styled(Button)({
  height: "40px",
  padding: "8px 36px",
  borderRadius: "100px",
  textAlign: "center",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none"
})
const ListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  "& .progressBarContainer":{
    display:"flex",
    justifyContent:"center",
    "& .MuiCircularProgress-colorPrimary":{
      color:"#6F05EA"
    }
  }
})
const RoomWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  minHeight: "48px",
  height: "100%",
  alignItems: "center",
  padding: "8px 24px",
  rowGap: "8px",
  justifyContent: "space-between",
  flexWrap: "wrap",
  "@media (max-width: 600px)": {
    padding: "8px",
  },
})
const TitleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  width:"225px",
  flexWrap:"wrap"
})
const CapacityWrapper = styled(Box)({
  minWidth: 100,
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  flexWrap:"wrap"
})
const DescWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  width:"370px",
  flexWrap: "wrap",
  rowGap: "8px",
  "@media (max-width: 600px)": {
    width: "300px"
  },
  "@media (max-width: 400px)": {
    width: "250px"
  }
})

const LabelFont = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#0F172A"
})
const ValueFont = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 500,
  maxWidth: 250,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6F05EA"
})

const DialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      maxWidth: 1240,
    }
  },
})
const FormTitle = styled(DialogTitle)({
  padding: "40px 60px",
  "& h2": {
    fontFamily: 'Montserrat',
    fontSize: "32px",
    fontWeight: 700,
    color: "#6F05EA",
    "@media (max-width: 600px)": {
      fontSize: "24px",
    },
  },
  "@media (max-width: 600px)": {
    padding: "32px",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  },
})
const FormWrapper = styled(DialogContent)({
  padding: "0px 60px",
  "@media (max-width: 600px)": {
    padding: "32px",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  },
  "& .errorText": {
    color: "#f44336",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
  },
  "& .MuiInput-underline.Mui-error::after":{
    border:"none"
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
    borderColor:"#D9D9D9"
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor:"#D9D9D9"
  },
  "& .description-container":{
    marginTop: "40px",
    "& .MuiFormHelperText-contained":{
      fontFamily: 'Montserrat',
      marginLeft:"0px !important"
    }
  }
})
const FormLabel = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "22px",
  color: "#6F05EA"
})
const FormButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  columnGap: "24px",
  margin: "32px 0"
})
const FormButton = styled(Button)({
  height: "56px",
  maxWidth: "246px",
  width: "100%",
  borderRadius: "31px",
  textAlign: "center",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none"
})
const CustomTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInputBase-input": {
    marginLeft: "8px",
    marginTop: "4px",
    fontFamily: 'Montserrat',
    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  "& .MuiFormHelperText-root":{
    fontFamily: 'Montserrat',
  }
})
const CustomSelect = styled(Select)({
  "& .MuiSelect-root": {
    marginLeft: "8px",
    marginTop: "4px",
    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  "&:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    fontFamily: 'Montserrat',
    fontSize: "12px",
  },
})
const CustomTextArea = styled(TextField)({
  marginTop: "6px",
  width: "100%",
  "& .MuiOutlinedInput-multiline": {
    padding: "0"
  },
  "& textarea": {
    resize: "none",
    padding: "8px",
    fontFamily: 'Montserrat',
    fontSize: "12px",
    border: "1px solid #D9D9D9 !important",
    borderRadius: "8px !important",
    "&::placeholder": {
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontSize: "12px",
      fontWeight: 400,
      color: "rgba(166, 166, 166, 1)"
    }
  },

})
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400
})
const ArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
  }
})
const FormMainBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "32px",
  flexWrap: "wrap",
  rowGap: "40px"
})
const FormLeftBox = styled(Box)({
  maxWidth: 360,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "40px"
})
const FormRightBox = styled(Box)({
  maxWidth: 124,
  width: "100%"
})
const ImageWrraper = styled(Box)({
  marginTop: "40px"
})
const GalleryWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "12px",
  rowGap: 20,
  marginTop: 6,
  flexWrap: "wrap"
})
const ImageListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "12px",
  rowGap: 20,
  flexWrap: "wrap",
  width: "100%",
  maxWidth: 580
})
const UploadButton = styled(Button)({
  padding: 0,
  border: "1px solid #D9D9D9",
  borderRadius: "9px",
  width: "84px",
  height: "84px",
  textTransform: "none"

})
const UploadIcon = styled(AddCircleOutline)({
  color: "#6F05EA",
  height: 24,
  width: 24
})
const UploadText = styled(Typography)({
  marginTop: "8px",
  fontFamily: 'Montserrat',
  fontSize: "10px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center"
})
const UploadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
})
const ImageBox = styled(Box)({
  position: "relative",
  padding: 0,
  border: "1px solid #D9D9D9",
  borderRadius: "9px",
  width: "83px",
  height: "83px",
  "& img":{
    borderRadius:"9px"
  }
})
const RemoveBtn = styled(IconButton)({
  padding: 0,
  position: "absolute",
  top: -10,
  right: -10
})
const style = {
  blueBtn: {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  } as React.CSSProperties,
  greyBtn: {
    color: "#4B4B4B",
    border: "1px solid #4B4B4B",
  } as React.CSSProperties,
  defaultMenu: {
    fontStyle: "italic",
    fontFamily: 'Montserrat',
    fontSize: "12px",
    fontWeight: 400,
    color: "rgba(166, 166, 166, 1)"
  } as React.CSSProperties,
  imageInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  formButton:{
    height: "56px",
    maxWidth: "246px",
    width: "100%",
    borderRadius: "31px",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none"
  } as React.CSSProperties,
}
const ViewDialogContainer = styled(Dialog)({
  "& .MuiPaper-root":{
    "&::-webkit-scrollbar": {
      borderRadius: "6px",
      width: "0px",
    },
    "&::-webkit-scrollbar-track":{
      borderRadius: "6px",
      background: "#CBD5E1",
    },
    "&::-webkit-scrollbar-thumb":{
      background: "#6F05EA",
      width: "6px", 
      borderRadius: "4px", 
    },
    "&::-webkit-scrollbar-thumb:hover":{
      background: "#6F05EA",
    }
  },
  "& .viewModalContainer": {
    height:"100%",
    padding: "36px 32px 64px 47px",
    display:"flex",
    flexDirection:"column",
    "@media (max-width: 600px)": {
        padding:"18px 23px 32px 23px"
    },
  },
  "& .closeIconContainer": {
    display: "flex",
    justifyContent: "flex-end",
    "& img":{
      "@media (max-width: 600px)": {
        height:"16px",
        width:"16px"
    },
    }
  },
  "& .modalTitleTypo": {
    fontSize: "32px",
    color: "#6F05EA",
    textAlign: "center",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  "& .modalInfoContainer": {
    display: "flex",
    gap: "12px",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 20,
  },
  "& .modalInfoTypo": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "center",
    fontSize: "14px",
    "& span": {
      textWrap: "wrap",
      color: "#6F05EA",
    },
  },
  "& .modalDescriptionTypo": {
    fontSize: "11px",
    textAlign: "justify",
    fontWeight: 400,
    whiteSpace:"pre-wrap",
    fontFamily: "Montserrat",
  },
  "& .modalDescriptionContainer": {
    marginTop: "28px",
    padding: 8,
    borderRadius: 8,
    backgroundColor: "#FBFBFB",
  },
  "& .imgGalleryContainer": {
    padding: "0px 21px 0 8px", 
    marginRight: "9px",
    overflowY:"hidden"
  },
  "& .card": {
    border: "none",
    boxShadow: "none",
    height: "100%",
  },
  "& .media": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "& .viewModalBtnContainer": {
    justifyContent: "flex-end",
    display: "flex",
    marginTop:"62px",
    marginRight:"32px"
  },
  "& .viewModalEditBtn": {
    padding: "8px 0",
    height: "40px",
    borderRadius: "100px",
    fontWeight: 700,
    textAlign: "center",
    width: "140px",
    lineHeight: "24px",
    fontSize: "18px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
    textTransform: "none",
    border: "1px solid #6F05EA",
  },
  "& .modalImageGalleryContainer":{
    display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      "& .MuiImageList-root":{
        overflowY:"hidden"
      }
  },
  "& .modalImageGalleryImage":{
    height:"100%",
    width:"100%"
  }
});
const StyledImageList = styled(ImageList)({
   maxWidth:"750px",
  "& .MuiImageListItem-root":{
    maxHeight:"250px !important",
  },
  "@media (max-width: 600px)": {
    "& .MuiImageListItem-root":{
      width:"50% !important",
    }
  },
  "@media (max-width: 450px)": {
    "& .MuiImageListItem-root":{
      width:"50% !important",
    }
  },
});
const ValueFontDec = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6F05EA",
  maxWidth: "200px !important",
  "@media (max-width: 400px)": {
    maxWidth: "100px !important",
  }
});
const StyledCreateRoom = styled(Dialog)({
  "& .MuiDialog-paperWidthMd":{
    maxWidth:"751px"
  },
  "& .createRoomContainer":{
    padding:"33px"
  }
});
const CreateRoomImgWrapper = styled(Box)({
  display:"flex",
  justifyContent:"flex-end"
});
const CloseIconButton = styled(IconButton)({
  height:"32px", 
  width:"32px"
});
const CreateRoomInfoWrapper = styled(Box)({
  maxWidth:"547px", 
  textAlign:"center",
  padding:"153px 70px 171px 70px", 
  "& .pleasFillTypo":{
    fontWeight:500,
    fontSize:"24px",
    lineHeight:"29px",
    "@media (max-width: 960px)": {
      fontSize:"20px",
    },
    "@media (max-width: 600px)": {
      fontSize:"16px",
    },
  },
  "@media (max-width: 960px)": {
    padding:"133px 50px 151px 70px"
  },
  "@media (max-width: 600px)": {
    padding:"100px 0px 120px 0px"
  },
  "& .createRoomTypo":{
    fontSize:"28px",
    lineHeight:"34px",
    fontWeight:600,
    "@media (max-width: 960px)": {
      fontSize:"24px",
    },
    "@media (max-width: 600px)": {
      fontSize:"20px",
    },
  },
})
const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 32,
  flexWrap: "wrap"
})
const LeftBox = styled(Box)({
  maxWidth: 360,
  width: "100%",
})
const RightBox = styled(Box)({
  maxWidth: 124,
  width: "100%"
})

const ViewDialogTitle = styled(DialogTitle)({
  padding: "0px 25px 0px 0px"
})
// Customizable Area End
