export const AddressLocation = require("../assets/address_location.svg");
export const TransportationIcon = require('../assets/transportation-icon.svg');
export const MedicationIcon = require('../assets/medication-icon.svg');

export const CommoditiesIcon = require('../assets/comodities.svg');

export const checkedIcon = require('../assets/check_service.svg');
export const unCheckedIcon = require('../assets/uncheck_service.svg');
export const warningIcon = require('../assets/red_warning.svg');

export const capacityCalendar = require('../assets/capacity_calender.svg');

// BOOKING SUMMARY
export const petPawIcon = require('../assets/pet_paw.svg');
export const DateCalendar = require("../assets/calendar_icon.svg");
export const RightBtnArrowIcon = require('../assets/right_btn_arrow.svg');
export const checkRepeat = require("../assets/check_repeat.svg");
export const unCheckRepeat = require("../assets/uncheck_repeat.svg");
//View Map
export const selectedmarkerpin = require("../../advancedsearch/assets/selectedmarkerpin.png");
export const crossButton = require("../../maps/assets/crossButton.png");
export const MarkerSmall = require("../../advancedsearch/assets/marker_small.svg");

//SERVICE GALLERY IMAGES
export const image1 = require("../assets/serviceGalleryImages/service-img-1.png");
export const image2 = require("../assets/serviceGalleryImages/service-img-2.png");
export const image3 = require("../assets/serviceGalleryImages/service-img-3.png");
export const image4 = require("../assets/serviceGalleryImages/service-img-4.png");
export const image5 = require("../assets/serviceGalleryImages/service-img-5.png");
export const image6 = require("../assets/serviceGalleryImages/service-img-6.png");
export const image7 = require("../assets/serviceGalleryImages/service-img-7.png");
export const image8 = require("../assets/serviceGalleryImages/service-img-8.png");
export const image9 = require("../assets/serviceGalleryImages/service-img-9.png");
export const image10 = require("../assets/serviceGalleryImages/service-img-10.png");
export const closeIcon = require("../assets/close-icn.svg");


// CHECKOUT PAGE
export const addCard = require("../assets/add_card_icon.svg");
export const DefaultSelectedCard = require("../assets/default_selected_card.svg");

export const Mastercard = require("../assets/Mastercard.svg");
export const Visa = require("../assets/Visa.svg");
export const Amex = require("../assets/Amex.svg");
export const DefaultCard = require("../assets/card_default.svg");
export const DeleteCard = require("../assets/delete_card.svg");