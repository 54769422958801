import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { capitalizeFirstLetterOnly } from '../helpers';


const VendorWeek = (props: any) => {
    const { services, names } = props;

    return (
        <Box style={{ whiteSpace: 'nowrap', border: 'none', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
            {Object.keys(services).map((service: any) => (
                <Box key={service} 
                    style={{ 
                        flex: 1, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'flex-start', 
                        minHeight: '100px', 
                        height: "100px",
                        width: '100px', 
                        padding: '10px',
                        overflow: 'hidden',
                    }}>
                        <Typography style={{ whiteSpace: 'break-spaces' }}>
                            {capitalizeFirstLetterOnly(names[service])}
                        </Typography>
                </Box>
            ))}
        </Box>
    )
}


export default VendorWeek;