import React, { useState } from "react";
import { Avatar, FormHelperText, MenuItem, Select, Typography, styled } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Flag from 'react-world-flags';

interface CustomSelectField {
    value?: string | number | undefined;
    fullWidth?: boolean;
    mb?: string;
    onChange?: (e:React.ChangeEvent<any>) => void;
    onBlur?:(e:React.ChangeEvent<any>) => void;
    list?: Array<{ label: string; value: string | number | undefined, flag?: string }>;
    asterisk?: boolean;
    placeholder?: string;
    label?: string;
    helperText?: string;
    error?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    startAdornment?: React.ReactNode;
    id:string;
    name:string;
    className?:string;
    dataTestId?: string;
    paperProps?: object;
    scrollOptions?: object;
    editLabel?: { enabled: boolean, styles?: any };
}

const CustomIconComponent = ({ value, open, isEditLabel }: { value: string | number | undefined, open: boolean, isEditLabel: boolean }) => {
  if (!value || isEditLabel) {
    return <ExpandMoreRoundedIcon style={{ color: '#A6A6A6', transition: 'transform 0.3s ease', transform: open ? 'rotate(180deg)' : 'rotate(0deg)', width: '24px' }}/>;
  }
  return <ExpandMoreRoundedIcon style={{ color: '#6F05EA', transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease', width: '24px'  }}/>;
};

export const CustomSelectField = (props: CustomSelectField) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        value,
        onChange,
        fullWidth = false,
        disabled = false,
        list,
        placeholder,
        label,
        style,
        error = false,
        helperText,
        startAdornment,
        onBlur,
        id,
        name,
        className,
        dataTestId,
        paperProps,
        editLabel,
        scrollOptions,
    } = props;

    const defaultScrollOptions = {
      disableScrollLock: false,
      marginThreshold: undefined,
    }

    const scrollOptionsProps = scrollOptions ? scrollOptions : defaultScrollOptions;

    const isFlag = name === 'language';
    const flagCode = () => {
      switch (value) {
        case 'portuguese':
          return 'pt';
        case 'english':
          return 'us';
        case 'spanish': 
          return 'es';
        case 'french':
          return 'fr'
        default:
          return 'pt';
      }
    }

    const renderLabel = (value: unknown) => {
        const filteredNode = list?.filter((element) => element.value === value);
        return filteredNode?.[0]?.label;
    };

    const listValues = list ? list.map(item => item.value): [''];

    const handleLableStyles = () => {
      if(editLabel && Object.keys(editLabel).length) {
        const margin = value ? '12px' : '17.5px';
        if (editLabel.enabled) {
          return editLabel.enabled ? {...WebStyles.customLabelSelected, ...editLabel.styles, marginBottom: margin } : WebStyles.customLabelDefault;
        } else {
          return editLabel.styles ? { ...editLabel.styles, marginBottom: "8px" } : WebStyles.customLabelDefault;
        }
      } else {
        return !!value ? WebStyles.customLabelSelected : WebStyles.customLabelDefault;
      }
    };
    

    const handleTextStyles = () => {
      if(editLabel && Object.keys(editLabel).length) {
        return editLabel.enabled ? WebStyles.selectInput : WebStyles.selectInputDefault;
      } else {
        return WebStyles.selectInput;
      }
    }

    return (
        <>
            {label && (
                <Typography style={ handleLableStyles() } id={`label-${name}`}>
                    {label} 
                </Typography>
            )}
            <CustomSelect
                data-test-id={dataTestId} 
                value={value}
                id={id}
                name={name}
                disabled={disabled}
                fullWidth={fullWidth}
                onChange={onChange}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                onBlur={onBlur}
                style={style}
                startAdornment={
                  isFlag ? <Avatar style={{width: '18px',height: '18px', overflow: 'hidden', marginBottom: '7px'}}>
                    <Flag code={flagCode()} style={{ width: 'auto', height: '100%'}}/>
                    </Avatar> 
                    : null
                }
                IconComponent={() => <CustomIconComponent value={value} open={isOpen} isEditLabel={!editLabel?.enabled} />}
                displayEmpty
                error={error}
                renderValue={(value) =>
                    typeof value === "undefined" || value === "" || value === 0 || value === null || !listValues.includes(value as string)  ? (
                        <Typography id="select-placeholder"
                            style={{ color: "#000", opacity: "0.38", fontSize: "13px", paddingTop: '6px', paddingLeft: '8px' }}
                        >
                            {placeholder}
                        </Typography>
                    ) : (
                        <Typography style={handleTextStyles()}>{renderLabel(value)}</Typography>
                    )
                    }
                MenuProps={{
                    ...scrollOptionsProps,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: {...paperProps}
                }}
            >
            {list && list?.length > 0 ? (
                list?.map((listItem) => {
                    return (
                    <CustomMenuItem key={listItem.label} value={listItem.value}>
                      {listItem.flag && 
                      <Avatar style={WebStyles.flagAvatar}>
                        <Flag code={listItem.flag} style={{ width: 'auto', height: '100%'}}/>
                      </Avatar>
                      }
                        {listItem.label}
                    </CustomMenuItem>
                    );
                })
                ) : (
                <CustomMenuItem value={""} disabled>
                    No Item
                </CustomMenuItem>
                )}
            </CustomSelect>
            {error && <FormHelperText style={{ color: '#f44336'}}> {helperText}</FormHelperText>}
        </>
    )
}


const WebStyles = {
    customLabelSelected: {
      color: '#6F05EA',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      marginBottom: '17.5px'
    },
    customLabelDefault: {
      color: '#777777',
      fontWeight: 400,
      fontSize: "12px",
      marginBottom: "12px",
    },
    flagAvatar: {
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      overflow: 'hidden',
      marginRight: '10px',
    },
    selectInput: {
      fontFamily: 'Montserrat', 
      fontSize: '18px', 
      fontWeight: 600, 
      paddingLeft: '8px'
    },
    selectInputDefault: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#A6A6A6',
      fontFamily: 'Montserrat',
      paddingLeft: '8px' 
    }
  };

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '13px',

    '&:hover': {
      backgroundColor: 'rgba(111, 5, 234, 0.1)', 
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(111, 5, 234, 0.3)', 
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgba(111, 5, 234, 0.1)', 
    },
    '& img': {
      width: '100%',
      height: '100%'
    }
  }));
  
  const CustomSelect = styled(Select)(({ theme }) => ({

    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingTop: 0, 
    },
    '& .Mui-focused .MuiSelect-select': {
      backgroundColor: 'transparent', 
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent', 
    },
    '& .MuiList-padding': {
        padding: 0
    },
  }));