import moment from "moment";

export function countNights(startDateStr: Date, endDateStr: Date): number {
    const startDate = moment(startDateStr).startOf('day');
    const endDate = moment(endDateStr).startOf('day');
  
    if (endDate.isSame(startDate, 'day')) {
      return 0;
    }
  
    const differenceInDays = endDate.diff(startDate, 'days')
  
    return Math.max(differenceInDays, 0);
  }