import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import {
  createTheme,
  styled,
  ThemeProvider,
} from "@material-ui/core/styles";
import { SearchIcon, UserIcon } from "./assets";
import { AppHeaderChat } from "../../../components/src/AppHeaderChat.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import ViewChat from "./ViewChat.web";
//LovePet Chat
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const ChatGridContainer = styled(Grid)(({ theme }) => ({
  "& .user-search-container": {
    width: "100%",
    marginTop: "24px",
  },
  "& .user-info-container": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "fit-content",
  },
  "& .user-name": {
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  "& .total-msg-badge": {
    borderRadius: "24px",
    padding: "2px 8px 2px 8px",
    backgroundColor: "#6F05EA",
    color: "#fff",
    fontSize: "10px",
    height: "12px",
  },
  "& .user-chat-list": {
    overflowY: "auto",
    marginTop: "32px",
  },
  "& .user-chat-list-container": {
    height: "112px",
    display: "flex",
    borderBottom: "1px solid #00000012",
    padding: "0px 12.5%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 9.5%",
    },
  },
  "& .user-chat-list-container-active": {
    height: "112px",
    display: "flex",
    borderBottom: "1px solid #00000012",
    padding: "0px 12.5%",
    backgroundColor: "#F0E5FF",
    [theme.breakpoints.down("md")]: {
      padding: "0px 9.5%",
    },
  },
  "& .user-chat-container": {
    display: "flex",
    gap: 10,
    alignItems: "center",
    width: "100%",
  },
  "& .user-chat-img": {
    width: "45px",
    height: "45px",
    borderRadius:'50%',
    [theme.breakpoints.down("md")]: {
      width: "40px",
      height: "40px",
    },
  },
  "& .user-msg-info-container": {
    display: "flex",
    gap: "20px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
    },
  },
  "& .user-name-msg": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "& .list-user-name": {
    fontSize: "19px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  "& .list-user-msg": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#00000099",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  "& .msg-time-container": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    height: "50px",
  },
  "& .list-msg-time": {
    fontSize: "12px",
    fontWeight: 600,
    color: "#00000099",
  },
  "& .list-msg-badge": {
    borderRadius: "100%",
    padding: "2px 6px",
    backgroundColor: "#6F05EA",
    color: "#fff",
    fontSize: "10px",
  },
  "& .view-chat-container": {
    borderLeft: "1px solid #00000012",
    height:"800px",
    overflowY:"auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .chat-container": {
    borderLeft: "1px solid #00000012",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .display-none": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .display-block": {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   formatTimeAgo = (timestamp: string) => {
    const date = moment(timestamp);
    const now = moment();
  
    const minutesDiff = now.diff(date, 'minutes');
    const hoursDiff = now.diff(date, 'hours');
    const today = now.startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
  
    if (minutesDiff === 0) {
      // If the message is from less than a minute ago, show the current time (e.g., "21:47")
      return date.format('HH:mm');
    } else if (minutesDiff < 60) {
      // If it's less than an hour ago, show minutes (e.g., "12m")
      return `${minutesDiff}m`;
    } else if (date.isSame(today, 'day')) {
      // If it's today, show hours ago (e.g., "3h")
      return `${hoursDiff}h`;
    } else if (date.isSame(yesterday, 'day')) {
      // If it's yesterday, show "Yesterday"
      return "Yesterday";
    } else {
      // For dates older than yesterday, show as "24/10/24"
      return date.format('DD/MM/YY');
    }
};

  // Customizable Area End

  render() {
    // Customizable Area Start
  
    const { filteredChatList,currentUserName,currentUserProfile } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Backdrop
          open={this.state.isLoading}
          style={{
            color: "#fff",
            zIndex: 1300, // Ensures it overlays the entire screen
            backgroundColor: "rgba(0, 0, 0, 0.5)" // Semi-transparent background
          }}
        >
          <CircularProgress color="inherit" /> 
        </Backdrop>
        <AppHeaderChat userAvatar={UserIcon} username="Joana Esteves" />
        <ChatGridContainer container>
          <Grid
            item
            md={4}
            lg={3}
            className={`${
              this.state.isChatListShow ? "display-block" : "display-none"
            } view-chat-container`}
          >
            <Box className="user-search-container">
              <Box style={{ marginLeft: "12.5%" }}>
                <Box className="user-info-container">
                  <img src={currentUserProfile} alt="userImg" className="user-chat-img" />
                  <Typography className="user-name">{currentUserName}</Typography>
                  <Box className="total-msg-badge"></Box>
                </Box>
                <TextField
                  value={this.state.searchQuery}
                  name="search"
                  placeholder="Search"
                  style={{ marginTop: "10px" }}
                  data-test-id="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} alt="search-icon" />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onChange={this.handleSearch}
                />
              </Box>
            </Box>
            <Box className="user-chat-list">
              {filteredChatList && filteredChatList.length >= 1 && filteredChatList.map((message) => (
                <Box
                  className={`${
                    this.state.chatUserId === message.chat_room.id
                      ? "user-chat-list-container-active"
                      : "user-chat-list-container"
                  }`}
                  onClick={() => this.handleChatListUserClick(message)}
                  data-test-id="user-chat-list"
                >
                  <Box className="user-chat-container">
                    <img src={message.other_user_data.image_url} className="user-chat-img" />
                    <Box className="user-msg-info-container">
                      <Box className="user-name-msg">
                        <Typography className="list-user-name">
                          {message.other_user_data.name}
                        </Typography>
                        <Typography className="list-user-msg">
                          {message.last_message}
                        </Typography>
                      </Box>
                      <Box className="msg-time-container">
                        <Typography className="list-msg-time" data-test-id='formatTime'>
                        {this.formatTimeAgo(message.chat_room.last_message_time)}
                        </Typography>
                        {message.chat_room.unread_messages>0 && <Box data-test-id='unreadcount' className="list-msg-badge">{message.chat_room.unread_messages}</Box>}
                        
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            md={8}
            lg={9}
            className={`${
              this.state.isChatListShow ? "display-none" : "display-block"
            } chat-container`}
          >
            <ViewChat
              handleBackListClick={this.handleClickBackChatList}
              userId={this.state.chatUserId}
              navigation=""
              id="chat-view"
              userListData={this.state.UserChatList}
              conversationId={this.state.conversationSID}
            />
          </Grid>
        </ChatGridContainer>
        <AppFooter />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
