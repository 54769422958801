import React from 'react';
import { Avatar, Box, Paper, Typography, styled, useMediaQuery } from '@material-ui/core';
import { HotelsIcon, DogWalkingIcon, PetSittingIcon, TrainingIcon, GroomingIcon, DayCareBlue  } from '../assets';
import { Shedule, configJSON } from '../CustomisableUserProfilesController';
import { capitalizeFirstLetterOnly, getLatestRequest } from '../../../../components/src/helpers';

type Props = {
    event: Shedule;
    onEventSelect: (event: Shedule | null) => void;
}

type IconType = 'Grooming' | 'Dog Walking' | 'Hotels' | 'Training' | 'Day Care' | 'Pet Sitting';


const Wrapper = styled(Paper)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    padding: '21px 28px',
    gap: '12px',

    [theme.breakpoints.up('sm')]: {
        gap: '30px',
    },

    "& .card-avatar": {
        width: '80px',
        height: '80px'
    },

    "& .appointment-content": {
        display: 'flex',
        flexDirection: 'column',
        gap: '13px',
        textAlign: 'left'
    },
    
    "& .app-pet-name": {
        color: '#000',
        fontSize: '20px',
        fontWeight: 600
    },
    "& .app-pet-service": {
        color: '#B7B7B7',
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: 600
    },
    "& .app-pet-time": {
        color: '#B7B7B7',
        fontSize: '16px',
        fontWeight: 400
    },
    "& .service-icon": {
        maxWidth: '30px',
        width: '100%',
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginTop: '4px',
        }
    },
    "& .requests-section": {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    },
    "& .request-item": {
        fontSize: '13px',
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
        }
    },
    "& .appointment-service-type": {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '12px'
    },
    "& .mobile-icon": {
        width: '18px'
    }
}))

const iconMap: Record<IconType, string> = {
    'Grooming': GroomingIcon,
    'Dog Walking': DogWalkingIcon,
    'Hotels': HotelsIcon,
    'Training': TrainingIcon,
    'Day Care': DayCareBlue,
    'Pet Sitting': PetSittingIcon
}

const Appointment = ({ event, onEventSelect }: Props) => {

    const isLargeScreen = useMediaQuery('(min-width:600px)')

    const request = getLatestRequest(event.requests.request_details);

    const renderRequestText = () => {
        const mapRequestStatus = {
            "accepted": "approved",
            "pending": "is on approval",
            "rejected": "rejected"
        }

        const mapColor = {
            "accepted": "#059669",
            "pending": "#D4AD2D",
            "rejected": "#C10000"
        }
        
        if(request) {
            return (
                <Box className='requests-section'>
                    {request && 
                        <Typography className='request-item' style={{ color: mapColor[request.status as 'accepted']}}>
                            {`${capitalizeFirstLetterOnly(request.request_type)} ${mapRequestStatus[request.status as 'accepted']}`}
                        </Typography>
                    }
                </Box>
            )
        }

        return null
    }

    return (
        <Wrapper square={false} elevation={3} variant="outlined" onClick={() => onEventSelect(event)}>
            <Avatar src={event.pet.data.attributes.pet_photo || configJSON.noImageUrl} className='card-avatar'/>
            {isLargeScreen && 
                <Box>
                    <img className='service-icon' alt={event.service.service_type} src={iconMap[event.service.service_type as IconType]}/>
                </Box>
            }
            <Box className='appointment-content'>
                <Typography className='app-pet-name'>{event.pet.data.attributes.name}</Typography>
                <Box className='appointment-service-type'>
                    {!isLargeScreen && <Box className='mobile-icon'><img className='service-icon' alt={event.service.service_type} src={iconMap[event.service.service_type as IconType]}/></Box>}
                    <Typography className='app-pet-service'>{event.service.service_type}</Typography>
                </Box>
                {!!event.shift && <Typography className='app-pet-service'>{event.shift}</Typography>}
                {renderRequestText()}
            </Box>
        </Wrapper>
    )

}

export default Appointment