import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
} from "@material-ui/core";
import React, { ReactNode } from "react";
interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  children: ReactNode;
  className?: string;
  closeIconProp?: string;
}

const CustomCalendarDialog: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  return (
    <>
      <StyledDialogContainer
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </StyledDialogContainer>
    </>
  );
};
export default CustomCalendarDialog;

const StyledDialogContainer = styled(Dialog)({
    "*": {
      userSelect: 'none'
    },
    "& .error-msg": {
      color: '#f44336',
      fontSize: '0.75rem',
      marginTop: '3px',
      lineHeight: '1.66',
      letterSpacing: '0.03333em'
    },
    "& .content-wrapper": {
      padding: '0px 24px',
      paddingBottom: '18px'
    },
    "& .content-wrapper.success": {
      display: 'flex', 
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '35vh',
      padding: '0px 55px',
      marginBottom: '35px',
    },
    "& .add-booking-dialog-btn-container":{
        display:"flex",
        justifyContent:"flex-end",
        gap:"10px",
        marginTop:"70px"
      },
      "& .dialog-cancel-btn":{
        border: "1px solid #4B4B4B",
        borderRadius: "100px",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 700,
        height: "fit-content",
        padding: "8px 49px",
        textTransform: "none",
        color: "#4B4B4B",
        backgroundColor: "transparent",
        boxShadow: "none",
        "&:hover": {
          color: "#4B4B4B",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
      "& .dialog-save-btn.dialog-disable-btn": {
        border: '1px solid #B7B7B7',
        color: '#B7B7B7'
      },
      "& .dialog-save-btn":{
        border: "1px solid #6F05EA",
        borderRadius: "100px",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 700,
        height: "fit-content",
        padding: "8px 49px",
        textTransform: "none",
        color: "#6F05EA",
        backgroundColor: "transparent",
        boxShadow: "none",
        "&:hover": {
          color: "#6F05EA",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
      "& .dialog-title-container":{
        display:"flex", 
        justifyContent:"center",
        padding: '24px 24px',
        gap: '16px',
        marginRight: '24px'
      },
      "& .dialog-title":{
        fontSize: "27px", 
        fontWeight: "600", 
        color: "#6F05EA",
        fontFamily: 'Montserrat'
      },
      "& .dialog-close-icon":{
        height:"18px", 
        width:"18px",
        color: '#090909'
      },
      "& .sent-successfully-email-info-text":{
        fontSize: "27px", 
        fontWeight: "600", 
        color: "#6F05EA",
        fontFamily: 'Montserrat',
      },
      "& .dialog-successfully-title-container":{
        display:"flex",
        justifyContent:"flex-end"
      },
      "& .dialog-close-btn": {
        padding: 0,
        position: 'absolute',
        top: 22,
        right: 22
      },
      "& .dialog-close-btn:hover":{
        backgroundColor:"#fff"
      }
})