Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.userSignUpAPIEndPoint= "account_block/accounts";
exports.petSignUpAPIEndPoint= "account_block/animal_types";
exports.defaultCountry = "Portugal";
exports.emailVerificationAPIEndPoint= "account_block/accounts/email_confirmations/verify_email";
exports.ResendEmailVerificationAPIEndPoint = "account_block/accounts/resend_email_verification"

exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
}

exports.ENDPOINTS = {
  GET_BREED_LIST: "bx_block_admin/animal_breeds/show_animal_breeds",
}

exports.form1Title = "Please tell us about yourself";
exports.form2Title = "Now let's talk about your pet";
exports.welComeText = "Welcome to Petzai";
exports.RegisterYourBusiness="Register your business";
exports.TrustingPetzai="Thank you for trusting Petzai!";
exports.PendingApproval = "Your business registration is now pending approval.";
exports.UnknownStep = "Unknown step";
exports.VendorForm1Title = "Select your type of register";
exports.VendorForm2Title = "Fill in your business info";
exports.VendorForm3Title = "Set your account";
exports.VendorForm4Title = "Set your service";
exports.YouReceiveEmailBusiness = "You will receive an email in the next 1 to 2 business days."
// Customizable Area End
