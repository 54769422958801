import { Box, IconButton, Modal, Typography, createTheme, styled } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Close } from "@material-ui/icons";
import React from "react";


interface IGenericModal {
    id: string;
    isOpen: boolean;
    title: string;
    onClose: () => void;
    children: React.ReactNode;
    headerStyle?: CSSProperties;
    bodyStyle?: CSSProperties;
    withoutCloseBtn?: boolean;
    disableOutside?: boolean;
    contentStyle?: CSSProperties;

}

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

const GenericModal = ({id, isOpen, onClose, title, children, disableOutside, headerStyle = {}, bodyStyle = {}, withoutCloseBtn = false, contentStyle = {}}: IGenericModal) => {
    return (
        <ModalWrapper
          id={id}
          open={isOpen}
          {...(!disableOutside ? { onClose: onClose } : {})}
          style={modalStyles.modal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <ModalContainer style={bodyStyle}>
            <div style={modalStyles.header}>
              <Typography
                id="modal-title"
                style={{
                  fontSize: "32px",
                  fontWeight: 700,
                  ...headerStyle
                }}
              >
                {title}
              </Typography>
              {withoutCloseBtn 
              ? <Box></Box> 
              : <IconButton
                data-test-id="close-icon"
                onClick={onClose}
                aria-label="close"
                style={{ padding: 0 }}
              >
                <Close />
              </IconButton>
              }
            </div>
            <div id="modal-description" style={{...modalStyles.description, ...contentStyle }}>
              { children }
            </div>
          </ModalContainer>
        </ModalWrapper>
    )
}

const ModalWrapper = styled(Modal)({
    "& .MuiTypography-root.MuiTypography-body1": {
        fontFamily: 'Montserrat',
    },
    "& .MuiSvgIcon-root path": {
        color: '#090909'
    }
})

const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: 'none',
  maxWidth: '782px',
  maxHeight: '791px',
  overflow: 'auto',
  borderRadius: 4,
  width:'782px',
  margin:"0px 40px",
  [theme.breakpoints.down("md")]: {
    width:"650px"
  },
  [theme.breakpoints.down("sm")]: {
    width:"800px"
  },
  [theme.breakpoints.down("xs")]: {
    margin: "0px 20px",
  },
}))

const modalStyles: Record<any, CSSProperties> = {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Montserrat",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: theme.spacing(2),
    },
  };

  export default GenericModal;